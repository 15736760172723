import {getBasket} from "../api/GoodsAPI";

export const saveBasket = (basket) => ({
    type: 'SAVE_BASKET',
    basket: basket
});


export const loadBasket = () => {

    return (dispatch) => {
        return getBasket().then(
            response => {
                dispatch(saveBasket(JSON.parse(response)));
            },
            error => {
                //TODO: load basket error show
            }
        );
    };


};