
const selectByAlias = (categories, alias) => {
    return categories.filter((category)=>{
        return category.alias === alias;
    })[0];
};

const selectByAliasChild = (categories, alias) => {
    let child = null;
    categories.forEach((category)=>{
        category.child.forEach(c => {
            if (c.alias === alias) {
                child = c;
            }
        });
    });
    return child;
};

export {selectByAlias, selectByAliasChild};