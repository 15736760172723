export const loadValues = () => (
    {
        type: 'LOAD_ALL',
        values: {
            pushClient: localStorage.getItem('nt'),
            goodsPush: localStorage.getItem('gp'),
            orderPush: localStorage.getItem('op')
        }
    }
);

export const saveItem = (item = {key: '', value: '', object: null}) => {
    localStorage.setItem(item.key, item.value);
    return {
        type: 'SAVE_VALUE',
        values: item.object
    };
};

export const deletePush = () => {

    localStorage.removeItem('nt');
    return {
        type: 'DELETE_PUSH',
        values: {
            pushClient: ''
        }
    };
};

