import React from 'react';
import {Link} from "react-router-dom";

const AboutLine = (props) => (

    <div id="about-line" style={{marginTop: '10px'}}>
        <div className="row">
            <div className="about-line-block d-flex justify-content-center">
                <span id="slogan">Crafty - это уникальные вещи и проверенные ремесленники</span>
                <Link className="about-as-button" to={'/about'}>Больше о нас</Link>
            </div>
        </div>
    </div>

);

export default AboutLine;