const settingsReducerDefaultState = {
    unread: 0,
    conversations: [],
    isOpen: false,
    isDialog: false,
    dialogId: null,
    dialog : {},
    userPage: null,
    isNew: false
};

const messages = (state = settingsReducerDefaultState, action) => {
    switch (action.type) {
        case 'SAVE_UNREAD':
            return {
                ...state,
                ...action.messages
            };
        case 'SAVE_CONVERSATIONS':
            return {
                ...state,
                ...action.messages
            };
        case 'CHANGE_STATUS':
            return {
                ...state,
                ...action.messages
            };
        case 'IS_DIALOG':
            return {
                ...state,
                ...action.messages
            };
        case 'DIALOG_ID':
            return {
                ...state,
                ...action.messages
            };
        case 'SAVE_DIALOG':
            return {
                ...state,
                ...action.messages
            };
        case 'USER_PAGE':
            return {
                ...state,
                ...action.messages
            };
        case 'CREATE_NEW':
            return {
                ...state,
                ...action.messages
            };
        default:
            return state;
    }
};
export default messages;