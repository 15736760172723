import React from 'react';
import {connect} from "react-redux";
import {selectByAlias} from "../selectors/category";
import {goodsCollections} from "../api/GoodsAPI";
import {Link, Redirect} from "react-router-dom";
import {refreshSession} from "../actions/authorization";
import {isOpenLeftMenu, setChildCategory, setMainCategory} from "../actions/settings";
import {withStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import Pagination from "material-ui-flat-pagination";
import {getFilteredCategories} from "../api/DictionaryAPI";
import * as queryString from "query-string";
import MainListProduct from "./MainListProduct";
import LoadingPage from "./LoadingPage";
import * as ReactGA from "react-ga";

const styles = theme => ({

    root: {
        flexGrow: 1,
        width: '100%',
        height: 50,
        display: 'inline-grid'
    },

    select: {
        color: 'red',
        width: '100%'
    },
    content: {
        margin: '0px!important'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: '-4px',
        color: '#5B6273',
        fontFamily: "Open Sans",
        fontSize: '12px',
        lineHeight: '17px'
    },
    textSecondary: {
        opacity: '0.5',
        color: '#283149',
        fontFamily: 'Open Sans',
        fontSize: '14px',
        lineHeight: '19px'
    },
    textPrimary: {
        color: '#4A90E2', fontFamily: 'Open Sans', fontSize: '14px', lineHeight: '19px'
    }

});

class CollectionPage extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            currentCategory: {},
            availableCategories: [],
            filter: {
                page: 0,
                size: 48,
                sort: 'orderToShow,asc'
            },
            product: {
                goodsList: {
                    content: []
                }
            },
            value: {
                min: props.dict.limits.min,
                max: props.dict.limits.max
            },
            loading: true,
            offset: 0,
            limit: 1,
            total: 1,
            childSelect: {},
            redirectCategory: false,
            suggestion: []
        }
    }

    componentDidMount() {
        this.searchProduct();
        if (typeof window !== 'undefined'){
            window.scrollTo(0, 0);
            window.addEventListener('popstate', this.onBackButtonEvent);

        }
        ReactGA.event({
            category: 'Categories',
            action: 'Open category'
        });
    }

    componentWillMount() {
        const parsed = queryString.parse(window.location.search);
        let filter = {...this.state.filter};
        filter.size = parsed.size ? parsed.size : 48;
        filter.page = parsed.page ? parsed.page : 0;
        this.setState({filter: filter, offset: (filter.page * filter.size)});
        window.history.pushState(filter, 'Поиск', '/collection/' + this.props.match.params.id +'?' + queryString.stringify(filter));
        this.props.dispatch(isOpenLeftMenu(false));
    }

    componentWillUnmount() {
        this.props.dispatch(setMainCategory(''));
        this.props.dispatch(setChildCategory(''));
        if (window)
            window.removeEventListener('popstate', this.onBackButtonEvent);


    }

    onBackButtonEvent = ( ev ) => {
        if(ev.state && typeof ev.state.page !== 'undefined' ) {

            let filter = {...this.state.filter};
            filter.page = ev.state.page;
            filter.size = ev.state.size;
            filter.category = ev.state.category;
            filter.costFrom = ev.state.costFrom;
            filter.costTo = ev.state.costTo;
            filter.color = ev.state.color;
            filter.name = ev.state.name;
            filter.sort = ev.state.sort;

            this.setState({filter: filter, offset: (filter.page * filter.size)}, () => {
                this.searchProduct();
            });

        }
    };


    componentDidUpdate(prevProps) {

        if (prevProps.match.params.alias !== this.props.match.params.alias || prevProps.match.params.child !== this.props.match.params.child) {
            this.props.dispatch(isOpenLeftMenu(false));

            const category = selectByAlias(this.props.dict.categories, this.props.match.params.alias);
            getFilteredCategories(this.props.match.params.alias).then(
                response => {
                    let availableCategories = JSON.parse(response);
                    this.setState({availableCategories});
                },
                error => {
                    console.log(error);
                }
            );
            let filter = this.state.filter;
            filter.page = 0;
            this.setState(() => ({
                currentCategory: category,
                filter: {...filter, category: this.props.match.params.alias},
                offset: 0,
                limit: 1,
                total: 1
            }));
            this.props.dispatch(setMainCategory(this.props.match.params.alias));
            this.props.dispatch(setChildCategory(this.props.match.params.child));
            this.searchProduct();

            ReactGA.event({
                category: 'Categories',
                action: 'Change category'
            });
            ReactGA.pageview(this.props.history.location.pathname);
        }

    }

    searchProduct = () => {
        if (typeof window !== 'undefined'){
            window.scrollTo(0, 0);
        }
        this.setState(()=>({loading: true}));
        // let cat = typeof this.props.match.params.child === 'string' ? this.props.match.params.child : this.props.match.params.alias;
        goodsCollections(this.props.match.params.id, this.state.filter).then(
            response => {
                let resp  = JSON.parse(response);
                    this.setState(() => ({
                        product: resp,
                        limit: resp.size,
                        total: resp.totalElements

                }));
                this.setState(()=>({loading: false}));
            },
            error => {
                this.setState(()=>({loading: false}));
                let resp = JSON.parse(error.message);
                if (resp.status === 401 || resp.status === 403) {
                    this.props.dispatch(refreshSession()).then(() => {
                        this.searchProduct();
                    });
                }
            }
        );
    };

    hexToRGB = (hex) => {
        const RGB_HEX = /^#?(?:([\da-f]{3})[\da-f]?|([\da-f]{6})(?:[\da-f]{2})?)$/i;

        const [, short, long] = String(hex).match(RGB_HEX) || [];

        if (long) {
            const value = Number.parseInt(long, 16);
            return [value >> 16, value >> 8 & 0xFF, value & 0xFF];
        } else if (short) {
            return Array.from(short, s => Number.parseInt(s, 16)).map(n => (n << 4) | n);
        }

    };

    onSearchChange = (ev) => {
        let filter = this.state.filter;
        filter.name = ev.target.value;
        this.setState({filter: filter}, () => {
            const params = queryString.parse(window.location.search);
            params.q = filter.name;
            params.page = filter.page;
            params.size = filter.size;
            params.sort = filter.sort;
            window.history.pushState(params, 'Поиск', '?' + queryString.stringify(params));

            this.searchProduct();
        })
    };

    onOrderChange = (ev) => {
        let filter = this.state.filter;
        filter.sort = ev.target.value;
        this.setState({filter: filter}, () => {
            const params = queryString.parse(window.location.search);
            params.q = filter.name;
            params.page = filter.page;
            params.size = filter.size;
            params.sort = filter.sort;
            window.history.pushState(params, 'Поиск', '?' + queryString.stringify(params));

            this.searchProduct();
        });
    };

    changePage = (e, offset, page) => {
        let filter = this.state.filter;
        filter.page = page - 1;
        this.setState(() => ({filter: filter, offset: offset}), () => {
            const params = queryString.parse(window.location.search);
            params.q = filter.name;
            params.page = filter.page;
            params.size = filter.size;
            params.sort = filter.sort;
            window.history.pushState(params, 'Поиск', '?' + queryString.stringify(params));
        });
        if (typeof window !== 'undefined'){
            window.scrollTo(0, 0);
        }
        this.searchProduct();
    };

    render() {

        const { classes, theme } = this.props;

        const selectStyles = {
            input: base => ({
                ...base,
                width: '100%',
                color: theme.palette.text.primary,
                '& input': {
                    font: 'inherit',
                },
            }),
            control: (provided) => {
                return {
                    ...provided,
                    width: '100%',
                    borderRadius: 0,
                    height: 50,
                    ':active': {
                        ...provided[':active'],
                        outlineColor: '#000000',
                        borderColor: '#000000',
                        outlineFocused: '#000000'
                    },
                    ':focus': {
                        ...provided[':focus'],
                        outlineColor: '#000000',
                        borderColor: '#000000'
                    },
                    ':target': {
                        ...provided[':target'],
                        outlineColor: '#000000',
                        borderColor: '#000000'
                    },
                    fontFamily: 'Open Sans',
                    fontSize: 14,
                    fontWeight: 'normal',
                    fontStyle: 'normal',
                    fontStretch: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    color: '#283149'
                };
            },
            indicatorSeparator: () => {

            },
            option: (provided) => {
                return {
                    ...provided,
                    borderRadius: 0,
                    backgroundColor: '#FFFFFF',
                    color: '#000000',
                    cursor: 'default',
                    ':active': {
                        ...provided[':active'],
                        backgroundColor: 'rgba(207, 214, 216, 0.2)',
                    },
                    ':hover': {
                        ...provided[':hover'],
                        backgroundColor: 'rgba(207, 214, 216, 0.2)',
                    },
                    width: '100%'
                };
            },
            container: (provided) => {
                return {
                    ...provided,
                    width: '100%',
                    borderRadius: 0
                };
            },
            valueContainer: (provided) => {
                return {
                    ...provided,
                    borderRadius: 0
                };
            },
            menu: (provided) => {
                return {
                    ...provided,
                    borderRadius: 0
                };
            },
        };



        return (
            <div>
                <div id="path-category">
                    <Link className="path-category" to={'/'}>Главная</Link><span>  /  </span> <Link className="path-category" to={'/collection/' + this.props.match.params.id}>{this.state.product.title}</Link> {}
                </div>
                <div className="catalog-box" style={{marginTop: 0, paddingTop: 0}}>
                    <div>
                        <div className="category-product-box"  style={{marginTop: 0}}>

                            {   this.state.loading ? <LoadingPage opacity={1} /> :
                                this.state.product.goodsList.content.length > 0 ?
                                    <MainListProduct productList={this.state.product.goodsList.content}/>
                                :
                                    <div className='d-flex justify-content-center'>
                                        <span>Ничего не найдено</span>
                                    </div>
                            }


                        </div>
                        <div className={'d-flex justify-content-center'}>
                                <Pagination
                                    limit={this.state.product.goodsList.size}
                                    offset={this.state.offset}
                                    total={this.state.product.goodsList.totalElements}
                                    currentPageColor={'primary'}
                                    otherPageColor={'secondary'}
                                    classes={{textSecondary: classes.textSecondary, textPrimary: classes.textPrimary}}
                                    onClick={(e, offset, page) => this.changePage(e, offset, page)}
                                />
                        </div>
                    </div>
                </div>
            </div>
        );
    }


}

const mapStateToProps = (state) => {
    return {
        dict: state.dictionary.dict,
        user: state.user,
        settings: state.settings,
        basket: state.basket,
        messages: state.messages
    };
};

CollectionPage.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps)(CollectionPage));