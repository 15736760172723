import {Link} from "react-router-dom";
import React from "react";
import {giftIcon} from "../../assets/asstes";


const giftsMenuItem = (props) => {
    return props.user.name ? <div style={{marginLeft: '21px',  marginBottom: '15px'}}>

        <div style={{display: 'inline-grid', verticalAlign: 'text-top', marginLeft: 0}}>
            <Link onClick={props.closeMenu} className='category-menu-label' to={'/cabinet/gift'}><img alt={'shop'} src={giftIcon}
                                                                            className="shop-icon" /><span className='category-menu-label'>Хочу в подарок</span></Link>
        </div>
    </div>
        : '';
}

export default giftsMenuItem;