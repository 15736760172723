import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {getBookmark, removeBookmark} from "../api/GoodsAPI";
import Slide from "@material-ui/core/Slide";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {sendNotification} from "../actions/notifications";
import Pagination from "material-ui-flat-pagination";
import * as queryString from "query-string";

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        color: '#5B6273',
        fontFamily: "Open Sans",
        fontSize: '12px',
        lineHeight: '17px'
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
        color: '#5B6273',
        fontFamily: "Open Sans",
        fontSize: '12px',
        lineHeight: '17px'
    },
    textSecondary: {
        opacity: '0.5',
        color: '#283149',
        fontFamily: 'Open Sans',
        fontSize: '14px',
        lineHeight: '19px'
    },
    textPrimary: {
        color: '#4A90E2', fontFamily: 'Open Sans', fontSize: '14px', lineHeight: '19px'
    }
});

class BookmarkPage extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            products: {},
            deleteOpen: false,
            goodsDeleteId: 0,
            deleteInProgress: false,
            filter: {
                page: 0,
                size: 48,
                sort: 'createdDate,desc'
            },
            offset: 0
        };
    }

    getMyBookmark = (filter) => {
        getBookmark(filter).then(
            response => {
                let products = JSON.parse(response);
                this.setState({products});
            },
            error => {
                console.log(error.message);
            }
        );
    };

    componentWillMount() {
        this.getMyBookmark(this.filter);
    }

    Transition = (props) => {
        return <Slide direction="up" {...props} />;
    };

    dialogOnOpen = (goodsId) => {
        this.setState(() => ({deleteOpen: true, goodsDeleteId: goodsId}))
    };
    dialogOnClose = () => {
        this.setState(() => ({goodsDeleteId: 0, deleteOpen: false}));
    };

    onDeleteGoods = () => {
        this.setState(() => ({deleteInProgress: true}));
        removeBookmark(this.state.goodsDeleteId).then(
            response => {
                this.setState(() => ({deleteInProgress: true, deleteOpen: false}));
                this.getMyBookmark(this.filter);
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Удалено из закладок',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));
            },
            error => {
                this.setState(() => ({deleteInProgress: true, deleteOpen: false}));
                console.log(error.message)
            }
        );
    };


    changePage = (e, offset, page) => {
        let filter = this.state.filter;
        filter.page = page - 1;
        this.setState(() => ({filter: filter, offset: offset}), () => {
            const params = queryString.parse(window.location.search);
            params.q = filter.name;
            params.page = filter.page;
            params.size = filter.size;
            params.sort = filter.sort;
            window.history.pushState(params, 'Поиск', '?' + queryString.stringify(params));
        });
        if (typeof window !== 'undefined'){
            window.scrollTo(0, 0);
        }
        this.getMyBookmark(filter);
    };



    render() {
        const { classes, theme } = this.props;
        return (
            <div>
                <div id="path-category">
                    <span>Главная  / Закладки  </span>
                </div>
                <div style={{marginLeft: '0px', marginTop: '33px'}}>
                    <div className='row' style={{marginTop: '20px', marginLeft: '0px', minHeight: '400px', width: '100%'}}>
                        {
                            (!!this.state.products.content && this.state.products.content.length > 0)
                                ? this.state.products.content.map((product) => {
                                    return (
                                        <div className="col-6 goods-card" key={product.id} >
                                            <Link className="link-block" to={"/product/" + product.id}>
                                                <div className="main-card-goods-image" style={
                                                    {
                                                        backgroundImage: 'url("'
                                                            + (product.photos.length > 0
                                                                ? product.photos.filter((ph) => {
                                                                    return ph.orderPlace === 1
                                                                })[0].photoMin
                                                                : '')
                                                            + '")',
                                                        backgroundSize: 'cover',
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundPositionX: 'center',
                                                        backgroundPositionY: 'center',
                                                        position: 'relative'
                                                    }}>
                                                    {
                                                        product.isAvailable
                                                            ?
                                                            <div style={
                                                                {
                                                                    width: '68px',
                                                                    height: '25px',
                                                                    backgroundColor: '#79CA1F',
                                                                    display: 'block',
                                                                    position: 'absolute',
                                                                    bottom: '0',
                                                                    borderRadius: '0 5px 0 0'
                                                                }
                                                            }><span style={
                                                                {
                                                                    color: '#FFFFFF',
                                                                    fontFamily: 'Open Sans',
                                                                    fontSize: '10px',
                                                                    lineHeight: '20px',
                                                                    marginLeft: '5px'
                                                                }
                                                            }>В наличии</span></div>
                                                            :
                                                            ''
                                                    }

                                                </div>
                                                <div className="main-card-goods-desc">
                                                    <div className="main-card-goods-name">
                                                        <Link className="main-card-goods-name-text"
                                                              to={"/product/" + product.id}>{product.name}</Link>
                                                    </div>
                                                    <div className="main-card-goods-store" style={{position: 'relative'}}>
                                                        <Link to={'/store/' + product.store.id}
                                                              className="main-card-goods-store-link"
                                                              style={{position: 'absolute', top: '0px'}}
                                                        >{product.store.name}</Link>
                                                    </div>
                                                    <div className="main-card-goods-cost">
                                                        {
                                                            product.oldCost ?
                                                                <span
                                                                    className="main-card-goods-cost crossed-red-cost" >{product.oldCost ? product.oldCost.cost : 0} {product.oldCost ? product.oldCost.currency : 'BYN '}  </span>
                                                                : ''
                                                        }
                                                        <span
                                                            className="main-card-goods-cost">{product.cost ? product.cost.cost : 0} {product.cost ? product.cost.currency : 'BYN'}</span>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    );
                                })
                                :
                                <div style={{width: '100%'}} className='d-flex justify-content-center'>
                                    <span>Пусто</span>
                                </div>
                        }

                    </div>
                    <div className={'d-flex justify-content-center'}>
                        <Pagination
                            limit={this.state.products.size}
                            offset={this.state.offset}
                            total={this.state.products.totalElements}
                            currentPageColor={'primary'}
                            otherPageColor={'secondary'}
                            classes={{textSecondary: classes.textSecondary, textPrimary: classes.textPrimary}}
                            onClick={(e, offset, page) => this.changePage(e, offset, page)}
                        />
                    </div>
                </div>

                <div>
                    <Dialog
                        open={this.state.deleteOpen}
                        TransitionComponent={this.Transition}
                        keepMounted
                        onClose={this.dialogOnClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title">
                            {"Вы точно хотите удалить этот товар из закладок?"}
                        </DialogTitle>
                        <DialogContent className='d-flex justify-content-center'>
                            <CircularProgress
                                style={{display: this.state.deleteInProgress ? 'block' : 'none'}}
                                color="secondary"/>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.dialogOnClose} color="secondary">
                                Отмена
                            </Button>
                            <Button disabled={this.state.deleteInProgress} onClick={this.onDeleteGoods} color="primary">
                                Удалить
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>

            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        dict: state.dictionary.dict,
        user: state.user,
        settings: state.settings,
        basket: state.basket,
        messages: state.messages
    };
};

BookmarkPage.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};
export default withStyles(styles, {withTheme: true})(connect(mapStateToProps)(BookmarkPage));