import React from 'react';
import {Link} from "react-router-dom";

const MainPopularStore = (props) => (

    <div id="popular-stores" style={{marginTop: '18px'}}>
        <div className="row">
            <div className="col-12">
                <span className="header-list-store"><Link className="header-list-store" to={'/stores'}>Популярные магазины</Link></span>
            </div>
        </div>
        <div className="row popular-store-block">

            {
                props.stores.filter((store) => {
                    return store.alias;
                }).map((store) => (
                    <div key={store.id} className="col-6">
                        <Link to={'/store/' + store.id} className='link-block'>
                            <div className="main-card-store-image"
                                 style={{
                                     backgroundImage: 'url("' + store.photoMin + '")',
                                     backgroundSize: 'cover',
                                     backgroundRepeat: 'no-repeat',
                                     backgroundPositionX: 'center',
                                     backgroundPositionY: 'center'
                                 }}
                            > </div>
                            <div className="main-card-store-desc">
                                <div className="main-card-goods-name">
                                    <Link className="main-card-store-name" to={'/store/' + store.id}>{store.name}</Link>
                                </div>

                                <div className="main-card-store-pin">
                                    <div className="main-card-store-pin-icon"></div>
                                    <span className="main-card-store-location">{store.city}</span>
                                </div>
                            </div>
                        </Link>
                    </div>
                ))
            }


        </div>

    </div>
);

export default MainPopularStore;