import {Link} from "react-router-dom";
import React from "react";


const ProductListComponent = (props) => {
    return (
        <div className="row " style={{marginTop: '21px', marginLeft: 0, marginRight: 0}}>
            {
                props.productList ? props.productList.map((product) => (
                    <div className="col-6 goods-card" key={product.id} >
                        <Link className="link-block" to={"/product/" + product.id}>
                            <div className="main-card-goods-image col-12" style={
                                {
                                    backgroundImage: 'url("'
                                        + (product.photos.length > 0
                                            ? product.photos.filter((ph) => {
                                                return ph.orderPlace === 1
                                            })[0].photoMin
                                            : '')
                                        + '")',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPositionX: 'center',
                                    backgroundPositionY: 'center',
                                    position: 'relative',
                                    paddingTop: '100%'
                                }}>
                                {
                                    product.isAvailable
                                        ?
                                        <div style={
                                            {
                                                width: '68px',
                                                height: '25px',
                                                backgroundColor: '#79CA1F',
                                                display: 'block',
                                                position: 'absolute',
                                                bottom: '0',
                                                left: 0,
                                                borderRadius: '0 5px 0 0'
                                            }
                                        }><span style={
                                            {
                                                color: '#FFFFFF',
                                                fontFamily: 'Open Sans',
                                                fontSize: '10px',
                                                lineHeight: '20px',
                                                marginLeft: '5px'
                                            }
                                        }>В наличии</span></div>
                                        :
                                        ''
                                }

                            </div>
                            <div className="main-card-goods-desc">
                                <div className="main-card-goods-name">
                                    <Link className="main-card-goods-name-text"
                                          to={"/product/" + product.id}>{product.name}</Link>
                                </div>
                                <div className="main-card-goods-store" style={{position: 'relative'}}>
                                    <Link to={'/store/' + product.store.id}
                                          className="main-card-goods-store-link"
                                          style={{position: 'absolute', top: '0px'}}
                                    >{product.store.name}</Link>
                                </div>
                                <div className="main-card-goods-cost">
                                    {
                                        product.oldCost ?
                                            <span
                                                className="main-card-goods-cost crossed-red-cost" >{product.oldCost ? product.oldCost.cost : 0} {product.oldCost ? product.oldCost.currency : 'BYN '}  </span>
                                            : ''
                                    }
                                    <span
                                        className="main-card-goods-cost">{product.cost ? product.cost.cost : 0} {product.cost ? product.cost.currency : 'BYN'}</span>
                                </div>
                            </div>
                        </Link>
                    </div>

                )) : ''
            }


        </div>

    );

};


export default ProductListComponent;