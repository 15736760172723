import React from 'react';
import {searchIcon} from "../assets/asstes";

const CategorySearchBlock = (props) => (
  <div>
      <div id="search-block"  style={{width: '100%', marginLeft: '5px'}}>
          <div className="row">
              <div style={{paddingLeft: props.paddingLeft ? props.paddingLeft : 10}} className="col-12 d-flex justify-content-center">
                  <div className="search-input-mid input-group ">
                      <div className="input-group-prepend">
                          <span className="input-group-text" style={{background: '#FFF', border: 0}}
                                id="inputGroup-sizing-lg"><img src={searchIcon} alt={'Поиск'} /></span>
                      </div>
                      <input value={props.searchString} onChange={props.onChange} id="search-main" type="text" className="form-control" placeholder="Поиск" />
                  </div>
              </div>
          </div>
      </div>
  </div>
);

export default CategorySearchBlock;
