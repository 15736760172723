import React from 'react';
import {repairPassword} from '../api/AuthorizationApi';
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {sendNotification} from "../actions/notifications";
import {closeIcon, logoIcon} from "../assets/asstes";

class RepairPasswordPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            key: props.match.params.key,
            repPassword: '',
            password: '',
            redirect: false,
            errors: {
                name: '',
                email: '',
                password: ''
            },
            errorStyle: {
                borderBottom: '2px',
                borderBottomColor: '#E60936',
                borderBottomStyle: 'solid'
            },
        }
    }

    onPasswordChange = (e) => {
        const password = e.target.value;
        this.setState(() => ({password}));
    };


    onRepPasswordChange = (e) => {
        const repPassword = e.target.value;
        this.setState(() => ({repPassword}));
    };

    onSubmit = (e) => {
        e.preventDefault();

        let repair = {
            key: this.state.key.trim(),
            password: this.state.password.trim(),
            repPassword: this.state.repPassword.trim()
        };


        if (repair.password === '' || repair.password.length < 8) {
            let errors = this.state.errors;
            errors.password = 'Пароль слишком короткий';
            this.setState(()=>({errors}));
            return;
        }else {
            let errors = this.state.errors;
            errors.password = '';
            this.setState(()=>({errors}));
        }


        if (repair.password !== repair.repPassword) {
            let errors = this.state.errors;
            errors.password = 'Пароли не совпадают';
            this.setState(()=>({errors}));
            return;
        }else {
            let errors = this.state.errors;
            errors.password = '';
            this.setState(()=>({errors}));
        }


        repairPassword(repair).then(
            response => {
                this.setState(() => ({redirect: true}));
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Пароль изменен',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));
            },
            error => {
                console.log(error.message);
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Ошибка при изменении пароля',
                        key: new Date().getTime(),
                        variant: 'error'
                    }
                }));
            }
        );
    };

    onClose = () => {
        this.setState({redirect: true});
    };

    render() {
        return (
            <div>
                {this.state.redirect ? <Redirect to={'/login'}/> : ''}
                <div className="row" style={{marginTop: '40px'}}>
                    <div className="col-11">
                        <div style={{marginLeft: '95px'}} className="d-flex justify-content-center">
                            <img src={logoIcon}/>
                        </div>
                    </div>
                    <div className="col-1" onClick={this.onClose}>
                        <div className="d-flex justify-content-end">
                            <img src={closeIcon}/>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop: '92px'}}>
                    <div className="col-12">
                        <div className="d-flex justify-content-center">
                <span style={{
                    height: '40px',
                    color: '#283149',
                    fontFamily: 'Tinos,sans-serif',
                    fontSize: '30px',
                    lineHeight: '40px'
                }}>Новый пароль</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-center">
                <span style={{
                    opacity: '0.5',
                    color: '#283149',
                    fontFamily: 'Open Sans,sans-serif',
                    fontSize: '16px',
                    lineHeight: '22px'
                }}>Задайте новый пароль</span>
                        </div>
                    </div>
                </div>
                <form onSubmit={this.onSubmit}>
                    <div className="row" style={{marginTop: this.state.errors.email.length > 0 ? '8px' : '28px'}}>
                        <div className="col-12">
                            <div className="d-flex justify-content-center">
                                <input type="password" className="email-field form-control" id="password-registration"
                                       aria-describedby="emailHelp" placeholder="Пароль"
                                       value={this.state.password}
                                       onChange={this.onPasswordChange}
                                       style={this.state.errors.password.length > 0 ? this.state.errorStyle : {}}
                                />
                            </div>
                            <div className="d-flex justify-content-center">
                                <span className='error-message' ref="passwordError" style={{display: this.state.errors.password.length > 0 ? 'block' : 'none'}}>{this.state.errors.password}</span>
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{marginTop: this.state.errors.email.length > 0 ? '8px' : '28px'}}>
                        <div className="col-12">
                            <div className="d-flex justify-content-center">
                                <input type="password" className="email-field form-control" id="password-repeat"
                                       aria-describedby="passwordHelp" placeholder="Повторите пароль"
                                       value={this.state.repPassword}
                                       onChange={this.onRepPasswordChange}
                                       style={this.state.errors.password.length > 0 ? this.state.errorStyle : {}}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{marginTop: this.state.errors.password.length > 0 ? '10px' : '30px'}}>
                        <div className="col-12">
                            <div className="d-flex justify-content-center">
                                <button type="submit" className="btn-login btn btn-danger"><span
                                    className="btn-login-label">Применить</span></button>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
        );
    };

}

export default connect()(RepairPasswordPage);