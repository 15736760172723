import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";


const LoadingPage = (props) => {

    let opacity = props.opacity ? props.opacity : 1;

    return (
        <div style={{
            zIndex: '1200',
            position: "absolute",
            width: '100%',
            height: '100%',
            backgroundColor: `rgba(255, 255, 255, ${opacity})`,
            // margin: 0,
            // padding: 0,
            // top: 0,
            // left: 0,
            // right: 0,
            // bottom: 0,
            transition: 'all .75s ease'
        }}>
            <CircularProgress
                style={{position: "absolute", top: '35%', left: '42%'}}
                color="secondary"/>
        </div>
    );
};


export default LoadingPage;