const dictReducerDefaultState = {};

const dictionary = (state = dictReducerDefaultState, action) => {
    switch (action.type) {
        case 'LOAD_DICT':
            return {
                ...state,
                dict: action.dict
    };
        default:
            return state;
    }
};
export default dictionary;