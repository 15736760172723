import {countUnreadMessages, getAllConversations, getConversation} from "../api/MessageAPI";
import {refreshSession} from "./authorization";

export const setUnreadMessages = (messages) => ({
    type: 'SAVE_UNREAD',
    messages: {
        unread: messages.unread,
    }
});

export const setOpenStatus = (status) => ({
    type: 'CHANGE_STATUS',
    messages: {
        isOpen: status
    }
});

export const setConversations = (messages) => ({
    type: 'SAVE_CONVERSATIONS',
    messages: {
        conversations: messages
    }
});

export const setDialog = (messages) => ({
    type: 'SAVE_DIALOG',
    messages: {
        dialog: messages
    }
});

export const setIsDialog = (isDialog) => ({
    type: 'IS_DIALOG',
    messages: {
        isDialog: isDialog
    }
});

export const setDialogId = (dialogId) => ({
    type: 'DIALOG_ID',
    messages: {
        dialogId: dialogId
    }
});

export const setUserPage = (user) => ({
    type: 'USER_PAGE',
    messages: {
        userPage: user
    }
});

export const createNew = (isNew) => ({
    type: 'CREATE_NEW',
    messages: {
        isNew: isNew
    }
});

export const promSetUserPage = (user) => {
    return (dispatch) => {
        dispatch(setUserPage(user));
        return Promise.resolve();
    }
};


export const getDialog = (filter = {id: 0, size: 40, page: 0}) => {
    return (dispatch) => {
        return getConversation(filter).then(
            response => {
                let dialog = JSON.parse(response);
                dialog.messages.content = dialog.messages.content.reverse();
                dispatch(setDialog(dialog));
            },
            error => {
                return error;
            }
        );
    }
};

export const getConversations = () => {
    return (dispatch) => {
        return getAllConversations().then(
            response => {
                dispatch(setConversations(JSON.parse(response)));
            },
            error => {
                return error;
            }
        );
    };
};

export const getUnreadAction = () => {
    return (dispatch) => {
        return countUnreadMessages().then(
            response => {
                dispatch(setUnreadMessages(JSON.parse(response)));
            },
            error => {
                return error;
                // if (error.code === 401 || error.code === 403) {
                //     if (localStorage.getItem('session')) {
                //         this.props.dispatch(refreshSession());
                //     } else {
                //         clearInterval(this.state.messageInterval);
                //     }
                // }
                //TODO: load user error show
            }
        );
    };
};
