import React from 'react';
import {login, registration} from '../api/AuthorizationApi';
import {connect} from "react-redux";
import {loadUser} from "../actions/user";
import {Link, Redirect} from "react-router-dom";
import * as ReactGA from "react-ga";
import {Helmet} from "react-helmet";

class RegistrationPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fullName: '',
            email: '',
            password: '',
            redirect: false,
            errors: {
                name: '',
                email: '',
                password: ''
            },
            errorStyle: {
                borderBottom: '2px',
                borderBottomColor: '#E60936',
                borderBottomStyle: 'solid'
            },
        }
    }

    onEmailChange = (e) => {
        const email = e.target.value;
        this.setState(() => ({email}));
    };

    onPasswordChange = (e) => {
        const password = e.target.value;
        this.setState(() => ({password}));
    };

    onNameChange = (e) => {
        const fullName = e.target.value;
        this.setState(() => ({fullName}));
    };

    validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };


    componentDidMount() {
        if (window){
            window.scrollTo(0, 0);
        }
        ReactGA.event({
            category: 'Registration',
            action: 'Open registration page'
        });
        ReactGA.pageview(this.props.history.location.pathname);
    }

    onSubmit = (e) => {
        e.preventDefault();

        let user = {
            fullName: this.state.fullName.trim(),
            email: this.state.email.trim(),
            password: this.state.password.trim()
        };

        if (user.fullName === '' || user.fullName.length < 3) {
            let errors = this.state.errors;
            errors.name = 'Имя слишком короткое';
            this.setState(()=>({errors}));
            return;
        }else {
            let errors = this.state.errors;
            errors.name = '';
            this.setState(()=>({errors}));
        }
        if (user.email === '' || !this.validateEmail(user.email)) {
            let errors = this.state.errors;
            errors.email = 'Введите правильный email';
            this.setState(()=>({errors}));
            return;
        }else {
            let errors = this.state.errors;
            errors.email = '';
            this.setState(()=>({errors}));
        }
        if (user.password === '' || user.password.length < 8) {
            let errors = this.state.errors;
            errors.password = 'Пароль слишком короткий';
            this.setState(()=>({errors}));
            return;
        }else {
            let errors = this.state.errors;
            errors.password = '';
            this.setState(()=>({errors}));
        }


        registration(user).then(
            response => {
                ReactGA.event({
                    category: 'Registration',
                    action: 'RegistrationSuccessful',
                    value: 10
                });
                localStorage.setItem("session", response);
                this.setState(() => ({redirect: true}));
                this.props.dispatch(loadUser());
            },
            error => {
                const err = JSON.parse(error.message);
                if (err.code === 40) {
                    let errors = this.state.errors;
                    errors.email = 'Данный email уже зарегистрирован';
                    this.setState(()=>({errors}));
                }
                console.log(error.message);
            }
        );
    };

    onClose = () => {
        this.setState({redirect: true});
    };

    render() {
        return (
            <div>
                {this.state.redirect ? <Redirect to={'/'}/> : ''}
                <Helmet>
                    <title>Регистрация</title>
                </Helmet>
                <div className="row" style={{marginTop: '40px'}}>
                    <div className="col-12">
                        <div className="d-flex justify-content-center">
                <span style={{
                    height: '40px',
                    color: '#283149',
                    fontFamily: 'Tinos,sans-serif',
                    fontSize: '30px',
                    lineHeight: '40px'
                }}>Создать аккаунт</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-center">
                            <span style={{
                                opacity: '0.5',
                                color: '#283149',
                                fontFamily: 'Open Sans,sans-serif',
                                fontSize: '16px',
                                lineHeight: '22px'
                            }}>Уже есть аккаунт на Crafty?</span> <Link style={{marginLeft: '0.86%'}} to={'/login'}>Войти</Link>
                        </div>
                    </div>
                </div>
                <form onSubmit={this.onSubmit}>
                    <div className="row" style={{marginTop: '8.8%'}}>
                        <div className="col-12">
                            <div className="d-flex justify-content-center">
                                <input type="name" className="email-field form-control" id="name-registration"
                                       aria-describedby="nameHelp" placeholder="Имя"
                                       onChange={this.onNameChange}
                                       // autoFocus
                                       value={this.state.fullName}
                                       style={this.state.errors.name.length > 0 ? this.state.errorStyle : {}}
                                />
                            </div>
                            <div className="d-flex justify-content-center">
                                <span className='error-message' ref="nameError" style={{display: this.state.errors.name.length > 0 ? 'block' : 'none'}}>{this.state.errors.name}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{marginTop: this.state.errors.name.length > 0 ? '8px' : '28px'}}>
                        <div className="col-12">
                            <div className="d-flex justify-content-center">
                                <input className="email-field form-control" id="email-registration"
                                       aria-describedby="emailHelp" placeholder="Email"
                                       onChange={this.onEmailChange}
                                       value={this.state.email}
                                       style={this.state.errors.email.length > 0 ? this.state.errorStyle : {}}
                                />
                            </div>
                            <div className="d-flex justify-content-center">
                                <span className='error-message' ref="emailError" style={{display: this.state.errors.email.length > 0 ? 'block' : 'none'}}>{this.state.errors.email}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{marginTop: this.state.errors.email.length > 0 ? '8px' : '28px'}}>
                        <div className="col-12">
                            <div className="d-flex justify-content-center">
                                <input type="password" className="email-field form-control" id="password-registration"
                                       aria-describedby="emailHelp" placeholder="Пароль"
                                       value={this.state.password}
                                       onChange={this.onPasswordChange}
                                       style={this.state.errors.password.length > 0 ? this.state.errorStyle : {}}
                                />
                            </div>
                            <div className="d-flex justify-content-center">
                                <span className='error-message' ref="passwordError" style={{display: this.state.errors.password.length > 0 ? 'block' : 'none'}}>{this.state.errors.password}</span>
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{marginTop: this.state.errors.password.length > 0 ? '10px' : '30px'}}>
                        <div className="col-12">
                            <div className="d-flex justify-content-center">
                                <button type="submit" className="btn-login btn btn-danger"><span
                                    className="btn-login-label">Создать</span></button>
                            </div>
                            <div style={{marginTop: '10px'}} className="d-flex justify-content-center">
                                <div style={{width: '100%', textAlign: 'center', fontWeight: 600, fontFamily: 'Open Sans', fontSize: 20}}>

                                    <span className='info-message'>Нажимая "Создать" вы принимаете </span><Link className='link-message' to={'/agreement'}>пользовательское соглашение.</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
        );
    };

}

export default connect()(RegistrationPage);