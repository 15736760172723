const authReducerDefaultState = {

};

const authorization = (state = authReducerDefaultState, action) => {
    switch (action.type) {
        case 'SAVE_SESSION':
            return {
                ...state,
                ...action.session,
                ...{isAuthorized: true}
            };
        case 'LOGOUT':
            let uState = {...state};
            uState = {};
            uState.isAuthorized = false;
            return {
                ...uState
            };
        case 'REFRESH_TOKEN_MANAGER':
            return {
                ...state,
                ...action.tokenManager,
            };
        default:
            return state;
    }
};

export default authorization;