import React from 'react';
import {login} from '../api/AuthorizationApi';
import {connect} from "react-redux";
import {loadUser} from "../actions/user";
import {Link, Redirect} from "react-router-dom";
import queryString from 'query-string';
import * as ReactGA from "react-ga";
import ym from "react-yandex-metrika";

class AboutUsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isRedirect: false,
            redirectURL: '/'
        };
        this.rootDiv = React.createRef();
    }


    onClose = () => {
        this.setState({isRedirect: true});
    };

    componentDidMount()  {
        // this.rootDiv.current.scrollTo(0, 0);
        window.scrollTo(0, 0);
        let params = queryString.parse(this.props.location.search);
        if (params.redirectURL){
            this.setState({redirectURL: params.redirectURL});
        }
        ReactGA.event({
            category: 'About',
            action: 'Read About Us'
        });
        ReactGA.pageview('/about');
        ym('about', '/about');
    }

    render() {
        return (
            <div ref={this.rootDiv}>
                {this.state.isRedirect ? <Redirect to={this.state.redirectURL}/> : ''}
                <div className="row" style={{marginTop: '40px'}}>
                    <div className="col-12">
                        <div className="d-flex justify-content-center">
                <span style={{
                    height: '40px',
                    color: '#283149',
                    fontFamily: 'Tinos,sans-serif',
                    fontSize: '30px',
                    lineHeight: '40px'
                }}>О нас</span>
                        </div>
                    </div>
                </div>
                <div style={{marginTop: '30px'}} className="row">
                    <div className="col-12 d-flex justify-content-center">
                        <div style={{maxWidth: '600px'}} className='about'>
                            Crafty - современный маркетплейс для людей, которые неравнодушны к товарам ручной работы. <br /><br />
                            Мы — небольшая, но очень амбициозная команда. <br /> Наша цель — собрать людей, которые по-настоящему ценят товары ручной работы, и лучших ремесленников в одном месте. <br />
                            Для ремесленников мы стараемся сделать наиболее удобное и максимально простое место, где они могут продавать свои изделия. <br />
                            Мы постоянно отслеживаем и улучшаем работу сервиса, поэтому если у Вас есть предложения, или замечания — не стесняйтесь отправлять их нам на почтовый адрес <a href='mailto:info@crafty.by'>info@crafty.by</a>. Мы обязательно прочитаем каждое Ваше письмо!
                        </div>
                    </div>
                </div>


            </div>
        );
    };

}

export default connect()(AboutUsPage);