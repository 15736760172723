import React from 'react';
import {Link} from "react-router-dom";


const MainListProduct = (props) => (

    <div id="popular" style={{marginTop: '10px'}}>
        <div className="row" style={{margin: 0}}>
            <div className="col-12">
                <Link to={props.url} className="header-list-goods">{props.name}</Link>
            </div>
        </div>
        <div className="row " style={{marginTop: '21px', marginLeft: 0, marginRight: 0}}>
            {
                props.productList ? props.productList.map((product) => (
                    <div className="col-6 goods-card" key={product.id} >
                        <Link className="link-block" to={"/product/" + product.id}>
                            <div className="main-card-goods-image" style={
                                {
                                    backgroundImage: 'url("'
                                        + (product.photos.length > 0
                                            ? product.photos.filter((ph) => {
                                                return ph.orderPlace === 1
                                            })[0].photoMin
                                            : '')
                                        + '")',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPositionX: 'center',
                                    backgroundPositionY: 'center',
                                    position: 'relative',
                                }}>
                                {
                                    product.isAvailable
                                        ?
                                        <div className='availability-block'>В наличии</div>
                                        :
                                        ''
                                }

                            </div>
                            <div className="main-card-goods-desc">
                                <div className="main-card-goods-name">
                                    <Link className="main-card-goods-name-text"
                                          to={"/product/" + product.id}><h3 className='main-card-goods-name-text-h3'>{product.name}</h3></Link>
                                </div>
                                <div className="main-card-goods-store" style={{position: 'relative'}}>
                                    <Link to={'/store/' + product.store.id}
                                          className="main-card-goods-store-link"
                                          style={{position: 'absolute'}}
                                            ><h3 className='main-card-goods-store-link-h3'>{product.store.name}</h3></Link>
                                </div>
                                <div className="main-card-goods-cost">
                                    {
                                        product.oldCost ?
                                            <span
                                                className="main-card-goods-cost crossed-red-cost" >{product.oldCost ? product.oldCost.cost : 0} {product.oldCost ? product.oldCost.currency : 'BYN '}  </span>
                                            : ''
                                    }
                                    <span
                                        className="main-card-goods-cost">{product.cost ? product.cost.cost : 0} {product.cost ? product.cost.currency : 'BYN'}</span>
                                </div>
                            </div>
                        </Link>
                    </div>

                )) : ''
            }


        </div>

    </div>
);

export default MainListProduct;