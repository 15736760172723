import React from 'react';
import {withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import moment from "moment";
import 'moment/locale/ru'
import {
    createNew,
    getDialog,
    promSetUserPage,
    setDialogId,
    setIsDialog,
    setOpenStatus,
    setUserPage
} from "../actions/messages";
import {getStore} from "../api/StoreAPI";
import Slide from "@material-ui/core/Slide";
import LoadingPage from "./LoadingPage";
import Carousel, {Modal, ModalGateway} from "react-images";
import {Link, Redirect} from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {changeStatusByUser, getUserOrderV2} from "../api/OrderAPI";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import {getConversationExist} from "../api/MessageAPI";
import {OrderPhotoComponent, OrderPhotoLinkComponent} from "./OrderPhotoComponent";
import {deleteGift, getGifts, parseGiftUrl, saveGift} from "../api/GiftAPI";
import {sendNotification} from "../actions/notifications";
import {CopyToClipboard} from "react-copy-to-clipboard";
import * as queryString from "query-string";
import {Helmet} from "react-helmet";
import * as VanillaSharing from 'vanilla-sharing';
import {logSocial} from "../api/LogAPI";
import {reportOrder} from "../api/ReportAPI";
import {
    arrowDownIcon,
    arrowUpIcon,
    avatarIcon, closeIcon, defaultSquareIcon,
    fbShareIcon, filledSquareIcon,
    okShareIcon,
    pinterestShareIcon, telegramShareIcon, viberShareIcon,
    vkShareIcon, whatsappShareIcon
} from "../assets/asstes";
import firebase from 'firebase/app';
import messaging from 'firebase/firebase-messaging';
import {adminGoodsSubscription, adminOrderSubscription, deleteClientId, saveClientId} from "../api/PushWebAPI";
import {deletePush, loadValues, saveItem} from "../actions/localStorageValuesActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";


const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    tabsRoot: {
        borderBottom: '1px solid #e8e8e8',
        width: '100%'
    },
    tabsIndicator: {
        backgroundColor: '#E60936',
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(4),
        fontSize: '20px',
        lineHeight: '30px',
        fontFamily: [
            'Tanos'
        ].join(','),
        '&:hover': {
            color: '#40a9ff',
            opacity: 1,
        },
        '&$tabSelected': {
            color: '#283149',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: '#283149',
            outline: 0
        },
    },
    tabSelected: {},
    typography: {
        padding: theme.spacing(3),
    },
    paperWidthXl: {
        width: '100%'
    },
    titleRoot: {
        paddingLeft: '10px',
        paddingRight: '10px',
        paddingTop: '24px',
        paddingBottom: '0px',
    },
    contentRoot: {
        paddingLeft: '10px',
        paddingRight: '10px',
        paddingBottom: '24px',
        paddingTop: 0
    },
});


const TabContainer = (props) => {
    return (
        <Typography component="div" style={{padding: 0}}>
            {props.children}
        </Typography>
    );
};


class UserCabinetPage extends React.Component{


    constructor(props, context) {
        super(props, context);
        moment.locale('ru');
        this.state = {
            userId: this.props.match.params.userId,
            store: {
                name: '',
                owner: {
                    name: '',
                    photoMid: ''
                },
                createdDate: moment(),
                city: ''
            },
            filter: {
                page: 0,
                size: 80,
                sort: 'createdDate,desc'
            },
            products: {},
            user: {
                id: this.props.user.id,
                name: this.props.user.name,
                country: this.props.user.country,
                city: this.props.user.city,
                phone: this.props.user.phone,
                email: this.props.user.email,
                description: this.props.user.description,
                address: this.props.user.address,
                photoMax: this.props.user.photoMax,
                photoMid: this.props.user.photoMid,
                photoMin: this.props.user.photoMin,
                createdDate: this.props.user.createdDate,
                phoneIsConfirmed: this.props.user.phoneIsConfirmed,
                confirmEmail: this.props.user.confirmEmail
            },
            gifts: {
                content: []
            },
            messageIsOpen: false,
            sendingInProgress: false,
            message: '',
            files: [],
            uploadingInProgress: false,
            progress: 0,
            value: 0,
            messageReport: '',
            reportOpen: false,
            sendingInProgressReport: false,
            redirectLogin: false,
            photoIsOpen: false,
            orders: {},
            giftIsOpen: false,
            giftIsOpenView: false,
            copied: false,
            gift: {
                url: '',
                title: '',
                description: '',
                image: ''
            },
            urlLoaded: false,
            loadingPage: false,
            titleError: false,
            descriptionError: false,
            imageError: false,
            urlError: false,
            giftToDelete: 0,
            deleteIsOpen: false,
            isEditing: false,
            deliveredInProgress: false,
            deliveredDialog: false,
            reportId: 0,
            orderId: 0,
            pushClient: null,
            goodsPush: false,
            orderPush: false,
            isLoading: false

        };
        this.fileInput = new React.createRef();
    }



    Transition = (props) => {
        return <Slide direction="up" {...props} />;
    };

    componentWillMount() {
        this.checkAuthorization();
        this.loadUserOrders();
    }

    toggleViewer = () => {
        this.setState(() => ({photoIsOpen: !this.state.photoIsOpen}));
    };

    componentWillUnmount() {
        if (!this.props.messages.isOpen)
            this.props.dispatch(setUserPage(null));
    }


    onProgress = (event) => {

        if (event.lengthComputable) {
            let complete = (event.loaded / event.total * 100 | 0);
            this.setState({progress: complete});
        }
    };

    checkAuthorization = () => {
        if (!this.props.user.name) {
            this.setState({logout: true});
        }
    };

    loadUserOrders = () => {
        getUserOrderV2(this.state.filter).then(
            response => {
                let orders = JSON.parse(response);
                this.setState({orders});
            },
            error => {
                console.log(error.message);
            }
        );

        if (this.props.user.storeId){
            getStore(this.props.user.storeId).then(
                response => {
                    let store = JSON.parse(response);
                    this.setState(() => ({
                        store: store
                    }));
                },
                error => {
                    // console.log(error);
                }
            );
        }

    };
    componentDidMount() {
        if (window) {
            window.scrollTo(0, 0);
        }
        this.props.dispatch(loadValues());
    }

    sendOnClose = () => {
        this.setState({messageIsOpen: false, deliveredDialog: false, deliveredId: 0, reportId: 0})
    };

    toggleDrawer = (open) => {
        if (!!this.props.messages.userPage) {
            getConversationExist(this.props.messages.userPage.id).then(
                response => {
                    let exist = JSON.parse(response);
                    if (exist.exist) {
                        this.props.dispatch(getDialog({id: exist.conversationId, size: 20})).then(() => {
                            this.props.dispatch(setDialogId(exist.conversationId));
                            this.props.dispatch(setIsDialog(true));
                            this.props.dispatch(setOpenStatus(open));
                            this.setState({
                                messengerOpen: open,
                            });
                        });

                    } else {
                        this.props.dispatch(createNew(true));
                        this.props.dispatch(setIsDialog(true));
                        this.props.dispatch(setOpenStatus(open));

                    }
                },
                error => {
                    console.log(error);
                }
            );
        } else {
            this.props.dispatch(setOpenStatus(open));

        }


    };

    toggleDrawerClick = () => {
        this.toggleDrawer(!this.props.messages.isOpen);

    };


    sendMessage = () => {
        let order = this.state.orders.content.filter(o => o.orderId === this.state.orderId)[0];



        this.props.dispatch(promSetUserPage(order.store.owner)).then(() => {
                this.toggleDrawerClick();
            }
        );
        this.setState({orderId: 0});

    };

    getOrderStatus = (status) => {
        switch (status) {
            case 2:
                return 'Подтвержден';
            case 1:
                return 'Ожидает ответа';
            case 3:
                return 'Отменен';
            case 4:
                return 'Отправлен';
            case 5:
                return 'Доставлен';
            default:
                return '';
        }
    };

    handleChange = (event, value) => {
        let params = queryString.parse(window.location.search);
        params.tab = new String(value);
        window.history.pushState(params, 'Кабинет', '?' + queryString.stringify(params));
        this.setState({value});
    };


    CustomView = (innerProps) => {
        return (
            <div className="product-image-lg"
                 onClick={() => {
                     this.toggleViewer(innerProps.currentIndex);
                 }}
                 style={{
                     backgroundImage: 'url("'
                         + innerProps.data + '")',
                     backgroundSize: 'contain',
                     backgroundRepeat: 'no-repeat',
                     backgroundPosition: 'center'
                 }}>
            </div>
        )
    };

    FooterCount = (props) => {
        const activeView = props.currentIndex + 1;
        const totalViews = props.views.length;
        return (
            <div>
                <span>{activeView} из {totalViews}</span>
            </div>
        );
    };


    handleCloseMenu = () => {
        // this.setState({orderStatusMenu: 0, orderId: 0}, () => {
        //     document.removeEventListener('click', this.handleCloseMenu, { capture: true });
        // });
    };

    handleClickMenu = (event, orderId, orderStatus) => {
        this.setState({orderId: orderId, orderStatusMenu: orderStatus}, () => {
            document.addEventListener('click', this.handleCloseMenu, { capture: true });
        });

    };


    urlOnChange = (ev) => {
        let url = ev.target.value;
        this.setState({gift: {...this.state.gift, url: url}});
    };

    titleOnChange = (ev) => {
        let title = ev.target.value;
        if (title && title.length > 299) {
            title = title.substring(0, 298);
        }
        this.setState({gift: {...this.state.gift, title: title}});
    };

    descriptionOnChange = (ev) => {
        let description = ev.target.value;
        if (description && description.length > 1199) {
            description = description.substring(0, 1198);
        }

        this.setState({gift: {...this.state.gift, description: description}});
    };


    giftIsOpen = () => {
        this.setState({giftIsOpen: true});
    };

    giftIsOpenView = (id) => {
        let gift = {};
        let filteredGift = this.state.gifts.content.filter(value => value.id === id);
        if (filteredGift){
            gift = filteredGift[0];
        }
        this.setState({giftIsOpenView: true, gift: gift});
    };

    giftOnCloseView = () => {
        this.setState({
            giftIsOpen: false,
            giftIsOpenView: false,
            loadingPage: false,
            titleError: false,
            descriptionError: false,
            imageError: false,
            urlError: false,
            urlLoaded: false,
            isEditing: false,
            gift: {
                url: '',
                title: '',
                description: '',
                image: ''
            }
        });
    };

    onSubscribe = () => {
        this.setState({isLoading: true});

        if (this.props.localStorageValues.pushClient){
            deleteClientId({clientId: this.props.localStorageValues.pushClient}).then(
                response => {
                    this.props.dispatch(deletePush());
                    this.setState({isLoading: false});
                }, error => {
                    this.props.dispatch(deletePush());
                    this.setState({isLoading: false});
                }
            ).catch(
                error => {
                    this.props.dispatch(deletePush());
                    this.setState({isLoading: false});
                }
            )
        }else{

            const messaging = firebase.messaging();
            // запрашиваем разрешение на получение уведомлений
            Notification.requestPermission()
                .then(() => {
                    // получаем ID устройства
                    messaging.getToken()
                        .then((currentToken) => {
                            if (currentToken) {
                                this.setState({isLoading: true});
                                saveClientId({clientId: currentToken}).then(
                                    response => {
                                        this.setState({isLoading: false});
                                        this.props.dispatch(saveItem({key: 'nt', value: currentToken, object: {pushClient: currentToken}}));

                                    }, error => {
                                        this.setState({isLoading: false});
                                        console.log(error);
                                    }
                                );
                            } else {
                                this.setState({isLoading: false});
                                console.warn('Не удалось получить токен.');
                            }
                        })
                        .catch((err) => {
                            this.setState({isLoading: false});
                            console.warn('При получении токена произошла ошибка.', err);
                            // setTokenSentToServer(false);
                        });
                })
                .catch((err) => {
                    this.setState({isLoading: false});
                    console.warn('Не удалось получить разрешение на показ уведомлений.', err);
                });
        }


    };

    onAdminGoodsSubscription = () => {
        adminGoodsSubscription().then(
            response => {
                this.props.dispatch(saveItem({key: 'gp', value: 'true', object: {goodsPush: true}}));
            }, error => {
                console.log(error);
            }
        );
    };

    onAdminOrderSubscription = () => {
        adminOrderSubscription().then(
            response => {
                this.props.dispatch(saveItem({key: 'op', value: 'true', object: {orderPush: true}}));
            }, error => {
                console.log(error);
            }
        );
    };

    giftOnClose = () => {

        if (!this.state.isEditing && this.state.gift.title) {
            this.setState({loadingPage: true});
            deleteGift(this.state.gift.id).then(
                response => {
                    this.setState({gifts: JSON.parse(response), giftToDelete: 0,
                        deleteIsOpen: false,
                        giftIsOpen: false,
                        giftIsOpenView: false,
                        loadingPage: false,
                        titleError: false,
                        descriptionError: false,
                        imageError: false,
                        urlError: false,
                        urlLoaded: false,
                        isEditing: false,
                        gift: {
                            url: '',
                            title: '',
                            description: '',
                            image: ''
                        }});
                }, error => {
                    this.setState({giftToDelete: 0, deleteIsOpen: false, loadingPage: false, isEditing: false});

                    this.props.dispatch(sendNotification({
                        open: true,
                        messageInfo: {
                            message: 'Ошибка при удалении данных, попробуйте позже или обновите страницу',
                            key: new Date().getTime(),
                            variant: 'error'
                        }
                    }));
                }
            );
        }



        this.setState({
            giftIsOpen: false,
            giftIsOpenView: false,
            loadingPage: false,
            titleError: false,
            descriptionError: false,
            imageError: false,
            urlError: false,
            urlLoaded: false,
            isEditing: false,
            gift: {
                url: '',
                title: '',
                description: '',
                image: ''
            }
        });
    };


    sendForParse = () => {
        this.setState({loadingPage: true});
        parseGiftUrl({url: this.state.gift.url}).then(
            response => {
                this.setState({
                    gift: JSON.parse(response),
                    urlLoaded: true,
                    titleError: false,
                    descriptionError: false,
                    imageError: false,
                    urlError: false,
                    loadingPage: false});
            },
            error => {
                this.setState({urlError: true, loadingPage: false});
                // console.log(error);
            }
        );
    };

    saveGiftAfterEdit = () => {
        let gift = {...this.state.gift};

        if (!gift.title || !gift.title.trim().length === 0){
            this.setState({titleError: true});
            return;
        }


        gift.title = gift.title.trim();

        if (!!gift.description) {
            gift.description = gift.description.trim();
        }

        this.setState({loadingPage: true});
        saveGift(gift).then(
            response => {
                this.setState({
                    gifts: JSON.parse(response),
                    urlLoaded: false,
                    loadingPage: false,
                    giftIsOpen: false,
                    titleError: false,
                    descriptionError: false,
                    imageError: false,
                    urlError: false,
                    isEditing: false,
                    gift: {
                        url: '',
                        title: '',
                        description: '',
                        image: ''
                    }});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Запись добавлена',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));
            },
            error => {
                this.setState({loadingPage: false,});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Ошибка при сохранении данных. Попробуйте позже или обновите страницу',
                        key: new Date().getTime(),
                        variant: 'error'
                    }
                }));
            }
        );
    };


    onChangeReport = (ev) => {
        let messageReport = ev.target.value;
        this.setState({messageReport});
    };

    sendOnCloseReport = () => {
        this.setState({reportOpen: false, orderId: 0, reportId: 0});
    };

    sendReport = () => {
        this.setState({sendingInProgressReport: true});
        reportOrder(this.state.reportId, this.state.messageReport).then(
            response => {
                this.setState({messageReport: '', reportOpen: false, sendingInProgressReport: false, reportId: 0});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Жалоба отправлена',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));
            },
            error => {
                this.setState({messageReport: '', reportOpen: false, sendingInProgressReport: false, reportId: 0});
            }
        );
    };


    onEditGift = (id) => {
        let gift = this.state.gifts.content.filter(value => value.id === id);


        this.setState({gift: gift[0], giftIsOpen: true, urlLoaded: true, isEditing: true});

    };

    onDeleteGift = (id) => {
        this.setState({giftToDelete: id, deleteIsOpen: true});
    };


    onDeleteGiftClose = () => {
        this.setState({giftToDelete: 0, deleteIsOpen: false});
    };

    onDeleteGiftPressed = () => {
        this.setState({loadingPage: true});
        deleteGift(this.state.giftToDelete).then(
            response => {
                this.setState({gifts: JSON.parse(response), giftToDelete: 0, deleteIsOpen: false});
                this.setState({loadingPage: false});

            }, error => {
                this.setState({giftToDelete: 0, deleteIsOpen: false, loadingPage: false});

                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Ошибка при удалении данных, попробуйте позже или обновите страницу',
                        key: new Date().getTime(),
                        variant: 'error'
                    }
                }));
            }
        );
    };

    logSocialShared = (type) => {
        const userId = this.props.user.name ? this.props.user.id : null;
        logSocial({page: 'https://crafty.by/user/' + this.state.user.id, userId: userId, type: type});
    };

    sharePinterest= () => {
        VanillaSharing.pinterest({
            url: ('https://crafty.by/user/' + this.state.user.id),
            description: (this.state.user.name + '. ' + this.state.user.description),
            media: this.state.user.photoMax,
        });
        this.logSocialShared('PINTEREST');
    };

    shareFacebook = () => {
        VanillaSharing.fbButton({
            url: ('https://crafty.by/user/' + this.state.user.id)
        });
        this.logSocialShared('FACEBOOK');
    };


    shareVk = () => {
        VanillaSharing.vk({
            url: ('https://crafty.by/user/' + this.state.user.id),
            title: this.state.user.name,
            description: this.state.user.description,
            image: this.state.user.photoMax,
            isVkParse: true,
        });
        this.logSocialShared('VK');
    };

    shareOk = () => {
        VanillaSharing.ok({
            url: ('https://crafty.by/user/' + this.state.user.id),
            title: this.state.user.name,
            image: this.state.user.photoMax,
        });
        this.logSocialShared('OK');
    };

    shareViber = () => {
        VanillaSharing.viber({
            url: ('https://crafty.by/user/' + this.state.user.id),
            title: this.state.user.name,
        });
        this.logSocialShared('VIBER');
    };


    shareTelegram = () => {
        VanillaSharing.telegram({
            url: ('https://crafty.by/user/' + this.state.user.id),
            title: this.state.user.name,
        });
        this.logSocialShared('TELEGRAM');
    };

    shareWhatsApp = () => {
        VanillaSharing.whatsapp({
            url: ('https://crafty.by/user/' + this.state.user.id),
            title: this.state.user.name,
        });
        this.logSocialShared('WHATSAPP');
    };

    sendOnOpen = (orderId) => {
        this.setState({deliveredDialog: true, deliveredId: this.state.orderId});
    };

    reportOnOpen = (orderId) => {
        this.setState({reportOpen: true, orderId: orderId, reportId: this.state.orderId});
    };

    sendStatusToChange = () => {
        this.setState({deliveredInProgress: true});
        changeStatusByUser({orderId: this.state.deliveredId, status: 5, response: ''}).then(
            response => {
                this.loadUserOrders();
                this.setState({deliveredId: 0, deliveredDialog: false, deliveredInProgress: false});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Статус заказа изменен',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));
            },
            error => {
                // console.log(error.message);
                this.setState({deliveredId: 0, deliveredDialog: false, deliveredInProgress: false});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Ошибка при изменении статуса заказа',
                        key: new Date().getTime(),
                        variant: 'error'
                    }
                }));
            }
        );
    };



    render() {
        const {classes} = this.props;
        const {value} = this.state;
        return (
            <div>

                {
                    !this.props.user.name ? <Redirect to={"/"} /> : ''
                }
                {
                    this.state.user.name ?
                    <div style={{marginBottom: '40px'}}>
                        <Helmet>
                            <title>{this.state.user.name}</title>
                        </Helmet>
                        <div  className="product-image-lg">
                            <Carousel styles={{width: '24rem'}} views={[(this.state.user.photoMid ? this.state.user.photoMid : avatarIcon),]} components={{ View: this.CustomView, FooterCount: this.FooterCount }}/>
                        </div>
                        <div className='product-page-name'>
                            <span>{this.state.user.name}</span>
                        </div>
                        <div className='product-cost-block' style={{position: "relative"}}>
                            <div style={{paddingTop: '0px'}}>
                                <span
                                      className="main-card-store-location">{moment(this.state.store.createdDate).fromNow(true)} на Crafty</span>
                            </div>
                            <div style={{position: "absolute", right: 0, top: 0}}>
                                <Link to={'/cabinet/edit'}>Редактировать</Link>
                            </div>
                        </div>
                        {
                            this.state.store.name ?

                                <Link to={'/store/'+this.state.store.id} className='store-product-link'>
                                    <div className='product-store-block'>
                                        <div className='product-store-image'
                                             style={{
                                                 backgroundImage: 'url("' + this.state.store.photoMin + '")',
                                                 backgroundSize: 'cover',
                                                 backgroundRepeat: 'no-repeat',
                                                 backgroundPosition: 'center',
                                                 display: 'inline-block'
                                             }}>

                                        </div>
                                        <div className='product-store-block-text' style={{
                                            display: 'inline-block',
                                            position: 'absolute',
                                            top: 0,

                                        }}>
                                            <div className='product-store-label'>
                                                <span>Магазин</span>
                                            </div>
                                            <div className='product-store-name'>
                                                <span>{this.state.store.name}</span>
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                                :
                                ''
                        }
                        {
                            (!!this.state.user.country || !!this.state.user.city || !!this.state.user.address) ?
                                <div style={{marginTop: '10px'}} className="main-card-store-pin">
                                    <div className="main-card-store-pin-icon"></div>
                                    <span
                                        className="main-card-store-location">{(!!this.state.user.country && this.state.user.country.length > 0) ? (this.state.user.country + ', ') : ''}{this.state.user.city} </span>
                                    <span className="main-card-store-location">{this.state.user.address}</span>
                                </div>
                                : ''
                        }

                        <div style={{marginTop: '10px'}} className="main-card-store-pin">
                            <span
                                className="main-card-store-location">Email: {this.state.user.email}</span> <span className="main-card-store-location" style={this.state.user.confirmEmail ? {color: 'green'} : {color: 'red'}}>({this.state.user.confirmEmail ? 'Подтвержден' : "Не подтвержден"})</span>
                        </div>
                        <div style={{marginTop: '10px'}} className="main-card-store-pin">
                            <span
                                className="main-card-store-location">Телефон: {this.state.user.phone}</span> <span className="main-card-store-location" style={this.state.user.phoneIsConfirmed ? {color: 'green'} : {color: 'red'}}>({this.state.user.phoneIsConfirmed ? 'Подтвержден' : "Не подтвержден"})</span>
                        </div>
                        <div className="product-page-description" style={{wordBreak: 'break-word'}}>
                                <span style={{whiteSpace: 'pre-wrap'}}>
                                    {this.state.user.description}
                                </span>
                        </div>



                        <div>
                            <div style={{marginTop: 20}}>
                                <span className='main-card-store-location'>Подключение Push уведомлений на этом устройстве</span>
                            </div>

                            {
                                (!this.props.userAgent.isIOS && (this.props.userAgent.isChrome || this.props.userAgent.isFirefox || this.props.userAgent.isOpera)) ?
                                <div>
                                    <div style={{position: 'relative'}}>
                                        <FormControlLabel
                                            style={{display: this.state.isLoading ? 'none' : 'block'}}
                                            control={
                                                <Checkbox
                                                    icon={<div style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        backgroundImage: 'url(' + defaultSquareIcon + ')',
                                                        backgroundSize: 'cover',
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundPositionX: 'center',
                                                        backgroundPositionY: 'center',
                                                    }}>

                                                    </div>}
                                                    checkedIcon={<div style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        backgroundImage: 'url(' + filledSquareIcon + ')',
                                                        backgroundSize: 'cover',
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundPositionX: 'center',
                                                        backgroundPositionY: 'center',
                                                    }}>

                                                    </div>}
                                                    value={true}
                                                    checked={this.props.localStorageValues.pushClient}
                                                    onChange={this.onSubscribe}
                                                />
                                            }
                                            label={<span className='product-page-description'>Включить уведомления</span>}
                                        />
                                        {
                                            this.state.isLoading ?
                                                <div  style={{position: 'relative', top: 0}}>
                                                    <CircularProgress />
                                                </div>
                                                : ''
                                        }
                                    </div>
                                </div>
                                    :''
                            }

                            {
                                (this.props.user.name && this.props.user.roles.filter(role => (role.name === 'ROLE_MANAGER'
                                    || role.name === 'ROLE_ADMIN')).length > 0) ?
                                    <div>

                                        <div>
                                            {
                                                this.props.localStorageValues.goodsPush ?
                                                    <span>Товары</span>
                                                    :
                                                    <button className="card-goods-action-btn" style={{paddingLeft: 20, color: '#007bff'}} onClick={this.onAdminGoodsSubscription}>Товары</button>

                                            }
                                        </div>
                                        <div>
                                            {
                                                this.props.localStorageValues.orderPush ?
                                                    <span>Order</span>
                                                    :
                                                    <button className="card-goods-action-btn" style={{paddingLeft: 20, color: '#007bff'}} onClick={this.onAdminOrderSubscription}>Заказы</button>

                                            }
                                        </div>


                                    </div>
                                    : ''
                            }
                        </div>

                        <div>
                            <Tabs
                                id='orders'
                                style={{marginTop: '30px'}}
                                value={value}
                                onChange={this.handleChange}
                                classes={{root: classes.tabsRoot, indicator: classes.tabsIndicator}}
                            >
                                <Tab
                                    disableRipple
                                    classes={{root: classes.tabRoot, selected: classes.tabSelected}}
                                    label="Мои покупки"
                                />
                                {/*<Tab*/}
                                {/*    disableRipple*/}
                                {/*    classes={{root: classes.tabRoot, selected: classes.tabSelected}}*/}
                                {/*    label="Хочу в подарок"*/}
                                {/*/>*/}
                            </Tabs>

                            {value === 0 && <TabContainer style={{padding: '0px'}}>
                                <div style={{marginTop: '0px', width: '100%', marginLeft: '0px', padding: 0}}>
                                    <div>
                                        {
                                            (!!this.state.orders && !!this.state.orders.content) ? this.state.orders.content.map((order, idx) => {
                                                    return (
                                                        <div style={{marginTop: idx === 0 ? '32px' : '48px'}}
                                                             key={order.orderId}>
                                                            <div className='order-header-div' style={{position: "relative"}}>
                                                                <div style={{marginLeft: '12px', marginTop: '14px', paddingTop: '5px'}}>
                                                                    {
                                                                        order.store ?
                                                                            <Link to={'/store/' + order.store.id}
                                                                                  className="order-header-store">{order.store.name}</Link>
                                                                            :
                                                                            <span className='order-header-store'>{order.storeName}</span>
                                                                    }

                                                                </div>
                                                                <div
                                                                     style={{marginLeft: '12px', marginTop: '8px'}}>
                                                                    <span
                                                                        className='order-header-number'>Заказ № {order.orderId} от {moment(order.createdDate).format('DD MMMM, YYYY').toString()}</span>
                                                                </div>
                                                                <div  style={{
                                                                    position: "absolute",
                                                                    top: 0,
                                                                    right: 0,
                                                                    marginRight: '16px',
                                                                    marginTop: '5px'
                                                                }}>
                                                                <span
                                                                    className='user-cabinet-order-status'>{this.getOrderStatus(order.status)}</span>
                                                                </div>
                                                            </div>
                                                            <div className='row' style={{marginTop: '15px', marginLeft: '0px'}}>
                                                                {
                                                                    order.item ?
                                                                        <OrderPhotoLinkComponent order={order} />
                                                                        : <OrderPhotoComponent order={order} />
                                                                }
                                                                <div className='col'
                                                                     style={{textAlign: 'right', paddingRight: '20px'}}>
                                                                <span
                                                                    className='order-amount-cost'>{order.amount} X {order.cost} BYN</span>
                                                                </div>
                                                                <div className="col"
                                                                     style={{textAlign: 'right', paddingRight: '20px'}}>
                                                                    <div>
                                                                    <span
                                                                        className='order-cost-sum'>{order.cost * order.amount} BYN</span>
                                                                    </div>
                                                                    <div className='order-action-menu'
                                                                         style={{marginTop: '12px'}}>
                                                                        <button
                                                                            className='order-action-menu'
                                                                            style={{
                                                                                backgroundColor: '#FFFFFF',
                                                                                border: 0,
                                                                            }}
                                                                            onClick={(e) => {
                                                                                this.handleClickMenu(e, order.orderId, order.status);
                                                                            }}
                                                                        >
                                                                            Выберите

                                                                            <img id={'img-' + order.orderId}
                                                                                 style={{marginLeft: '6px'}}
                                                                                 alt={''}
                                                                                 src={order.orderId === this.state.orderId ? arrowUpIcon : arrowDownIcon}
                                                                                 className="arrow-icon"/>
                                                                        </button>
                                                                        <div id={'menu' + order.orderId}
                                                                             className={this.state.orderId === order.orderId ? "dropdown-menu-order dropdown-menu show" : 'dropdown-menu-order dropdown-menu'}
                                                                             style={{top: '4rem', left: '-45px!important'}}>

                                                                            {
                                                                                this.state.orderStatusMenu === 4 ?
                                                                                    <div>
                                                                                        <button
                                                                                            className="dropdown-item dropdown-item-add"
                                                                                            onClick={(e) => {
                                                                                                this.sendOnOpen(order.orderId);
                                                                                                this.handleCloseMenu();
                                                                                            }}>
                                                                                            Подтведить получение
                                                                                        </button>

                                                                                    </div>
                                                                                    : ''
                                                                            }
                                                                            <button
                                                                                className="dropdown-item dropdown-item-add"
                                                                                onClick={() => {
                                                                                    this.sendMessage();
                                                                                    this.handleCloseMenu();
                                                                                }}>
                                                                                Написать продавцу
                                                                            </button>
                                                                            <button
                                                                                className="dropdown-item dropdown-item-add"
                                                                                onClick={() => {
                                                                                    this.reportOnOpen(this.state.orderId);
                                                                                    this.handleCloseMenu();
                                                                                }}>
                                                                                Пожаловаться
                                                                            </button>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div >
                                                                <div style={{marginTop: '5px', marginBottom: '8px'}}>
                                                                    {
                                                                        order.item ?
                                                                            <Link className="order-goods-name"
                                                                                  to={'/product/' + order.item.id}>{order.item.name}</Link>
                                                                            :
                                                                            <div>
                                                                                <span className='order-goods-name'>{order.goodsName}</span>
                                                                            </div>
                                                                    }

                                                                </div>
                                                                {order.address ?
                                                                    <div style={{marginBottom: '6px'}}>
                                                                        <span className='order-info-label'>Адрес: </span>
                                                                        <span
                                                                            className='order-info-text'>{order.address}</span>
                                                                    </div>
                                                                    : ''
                                                                }
                                                                {order.phone ?
                                                                    <div style={{marginBottom: '6px'}}>
                                                                        <span className='order-info-label'>Телефон: </span>
                                                                        <span
                                                                            className='order-info-text'>{order.phone}</span>
                                                                    </div>
                                                                    : ''
                                                                }
                                                                {order.delivery ?
                                                                    <div style={{marginBottom: '6px'}}>
                                                                        <span
                                                                            className='order-info-label'>Тип доставки: </span>
                                                                        <span
                                                                            className='order-info-text'>{order.delivery}</span>
                                                                    </div>
                                                                    : ''
                                                                }
                                                                {order.comment ?
                                                                    <div style={{marginBottom: '6px'}}>
                                                                        <span
                                                                            className='order-info-label'>Комментарий: </span>
                                                                        <span
                                                                            className='order-info-text'>{order.comment}</span>
                                                                    </div>
                                                                    : ''
                                                                }
                                                                {order.response ?
                                                                    <div style={{marginBottom: '6px'}}>
                                                                        <span
                                                                            className='order-info-label'>Ответ магазина: </span>
                                                                        <span
                                                                            className='order-info-text'>{order.response}</span>
                                                                    </div>
                                                                    : ''
                                                                }
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                                :
                                                <div>
                                                    <span>Вы еще ничего не заказали</span>
                                                </div>
                                        }
                                    </div>
                                </div>

                            </TabContainer>
                            }
                        </div>

                    </div>
                        :
                        <LoadingPage opacity={1}/>
                }
                <ModalGateway>
                    {
                        this.state.photoIsOpen ?
                            <Modal onClose={this.toggleViewer}>
                                <Carousel views={[{source: (this.state.user.photoMax ? this.state.user.photoMax : avatarIcon)}]} components={{ FooterCount: this.FooterCount }} />
                            </Modal>
                            : ''
                    }
                </ModalGateway>
                <div>
                    <Dialog
                        open={this.state.messageIsOpen}
                        TransitionComponent={this.Transition}
                        keepMounted
                        onClose={this.sendOnClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title" className='d-flex justify-content-center'>
                            Сообщение
                        </DialogTitle>
                        <DialogContent className='d-flex justify-content-center'>
                            <div>

                            </div>
                            <div >
                                {
                                    this.state.files.length > 0 ?
                                        this.state.files.map(file => {
                                            return (
                                                <div key={file.fileId}>
                                                    <div style={{display: 'inline-flex', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '284px', wordBreak: 'break-all'}}>
                                                        <a rel="noopener noreferrer" href={file.link} target='_blank'><span style={{
                                                            maxWidth: '250px',
                                                            overflow: 'hide'
                                                        }}>{file.name}</span></a>
                                                    </div><a style={{marginLeft: '10px', color: 'black'}} href="javascript:void(0)" onClick={() => {
                                                    this.removeMessageFile(file.fileId);
                                                }}>X</a>
                                                </div>
                                            );

                                        })
                                        : ''
                                }
                                {
                                    this.state.uploadingInProgress ?
                                        <div >
                                            <LinearProgress variant="determinate" value={this.state.progress}/>
                                        </div>
                                        : ''
                                }
                                <textarea
                                    className='textarea-element'
                                    value={this.state.message}
                                    onChange={this.onChangeMessage}
                                    placeholder={'Введите сообщение'}
                                    style={{
                                        padding: '20px',
                                        marginTop: '10px'
                                    }} />

                            </div>
                            <CircularProgress
                                style={{display: this.state.sendingInProgress ? 'block' : 'none'}}
                                color="secondary"/>
                        </DialogContent>
                        <DialogActions>
                            <input ref={this.fileInput} style={{display: 'none'}} type="file" onChange={this.fileOnChange}/>
                            <Button onClick={this.sendOnClose} color="secondary">
                                Отмена
                            </Button>
                            <button className='save-btn-40' style={{float: 'right', width: '40px', marginRight: '5px'}} onClick={() => {
                                this.fileInput.current.click();
                            }}>+</button>
                            <button className='save-btn-40' disabled={this.state.sendingInProgress} onClick={this.sendMessage}>
                                Отправить
                            </button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        fullWidth={true}
                        maxWidth={'xl'}
                        classes={{paperWidthXl: classes.paperWidthXl}}
                        open={this.state.giftIsOpen}
                        TransitionComponent={this.Transition}
                        keepMounted
                        disableBackdropClick
                        onClose={this.giftOnClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle
                            id="alert-dialog-slide-title"
                            classes={{root: classes.titleRoot}}
                        >
                            <div className='row' style={{marginLeft: 0, marginRight: 0}}>
                                <div className='col-10'>
                                    <span className='alert-dialog-slide-title'>Хочу в подарок</span>
                                </div>
                                <div style={{paddingTop: 10}} className='col-2'>
                                    <img onClick={this.giftOnClose} src={closeIcon}
                                         className="close"/>
                                </div>
                            </div>
                        </DialogTitle>
                        <DialogContent classes={{root: classes.contentRoot}}>
                            {
                                this.state.loadingPage ? <LoadingPage opacity={0.5} /> : ''
                            }
                            <div style={{display: 'block',}}>

                                <div>
                                    <div style={{display: 'block'}}>
                                        <div className="store-header-title" style={{marginTop: '20px'}}>
                                            <span>Вставьте ссылку на то, что хотите получить в подарок. Работает почти со всеми сайтами.</span>
                                        </div>
                                        <div>
                                            <input type='text'
                                                   disabled={(this.state.loadingPage || this.state.urlLoaded) && !this.state.urlError}
                                                   className='input-text-element-470'
                                                   value={this.state.gift.url}
                                                   onChange={this.urlOnChange}
                                                   placeholder='Ссылка'
                                                   style={{
                                                       paddingLeft: '20px',
                                                       marginTop: '10px',
                                                       width: '100%'
                                                   }}/>
                                                   <br/>
                                            <div className="store-header-title" style={{marginTop: '10px'}}>
                                                <span>Это может быть что угодно: товары, услуги, записи на мастер-классы и марафоны, сертификаты.</span>
                                            </div>
                                            <button
                                                style={{
                                                    marginTop: 10,
                                                }}
                                                disabled={(this.state.loadingPage || this.state.urlLoaded) && !this.state.urlError}
                                                onClick={this.sendForParse}
                                                className='btn-info-100pr'
                                            >Загрузить</button>

                                            <div className="d-flex justify-content-start">
                                                <span className='error-message' ref="nameError"
                                                      style={{display: (this.state.urlError ? 'block' : 'none')}}>Неправильный адрес или страница недоступна</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style={{
                                    height: '1px',
                                    width: '100%',
                                    borderRadius: '1px',
                                    backgroundColor: '#CFD6D8',
                                    marginTop: '20px',
                                    display: 'block'
                                }}>

                                </div>
                                {
                                    this.state.urlLoaded ?
                                        <div>
                                            <div style={{display: 'block', marginTop: 20, width: '100%'}} className='d-flex justify-content-center'>
                                                <div style={{
                                                    height: 200,
                                                    width: 200,
                                                    backgroundImage: 'url("' + this.state.gift.image + '")',
                                                    backgroundSize: 'contain',
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundPositionX: 'center',
                                                    backgroundPositionY: 'center',
                                                    position: 'relative'
                                                }}>

                                                </div>
                                            </div>

                                            <div style={{display: 'block'}}>
                                                <div className="store-header-title" style={{marginTop: '20px'}}>
                                                    <span>Название</span>
                                                </div>
                                                <div>
                                                    <input type='text'
                                                           className='input-text-element-100pr'
                                                           value={this.state.gift.title}
                                                           onChange={this.titleOnChange}
                                                           placeholder='Название (обязательно)'
                                                           style={{
                                                               paddingLeft: '20px',
                                                               marginTop: '10px'
                                                           }}/>
                                                    <div className="d-flex justify-content-start">
                                                        <span className='error-message' ref="nameError"
                                                              style={{display: (this.state.titleError ? 'block' : 'none')}}>Название не может быть пустым</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{display: 'block'}}>
                                                <div className="store-header-title" style={{marginTop: '20px'}}>
                                                    <span>Описание</span>
                                                </div>
                                                <div>
                                                    <textarea
                                                        className='textarea-element-100pr'
                                                        value={this.state.gift.description}
                                                        onChange={this.descriptionOnChange}
                                                        placeholder='Добавьте описание или оставьте комментарий (не обязательно)'
                                                        style={{
                                                            padding: '20px',
                                                            marginTop: '10px'
                                                        }}/>
                                                    <div className="d-flex justify-content-center">
                                                    <span className='error-message' ref="nameError"
                                                          style={{display: this.state.descriptionError ? 'block' : 'none'}}>Описание пустое</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : ''
                                }

                            </div>

                            <div className='d-flex justify-content-between'>
                                <button style={{
                                    marginTop: '24px',
                                    width: '47%',
                                }}
                                        disabled={this.state.sendingInProgress}
                                        onClick={this.giftOnClose}
                                    className='btn-info-100pr'
                                >
                                    Отмена
                                </button>
                                <button style={{marginTop: '24px', width: '47%', marginBottom: 24, fontSize: 12, height: 40}} disabled={this.state.sendingInProgress || !this.state.urlLoaded}
                                        onClick={this.saveGiftAfterEdit} className='btn-basket-order-dialog'>
                                    Готово
                                </button>
                            </div>


                            <CircularProgress
                                style={{display: this.state.sendingInProgress ? 'block' : 'none'}}
                                color="secondary"/>
                        </DialogContent>

                    </Dialog>
                </div>





                <div>
                    <Dialog
                        fullWidth={true}
                        maxWidth={'xl'}
                        classes={{paperWidthXl: classes.paperWidthXl}}
                        open={this.state.giftIsOpenView}
                        TransitionComponent={this.Transition}
                        keepMounted
                        onClose={this.giftOnCloseView}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle
                            id="alert-dialog-slide-title"
                            classes={{root: classes.titleRoot}}
                        >
                            <div className='row' style={{marginLeft: 0, marginRight: 0}}>
                                <div className='col-10'>
                                    <span className='alert-dialog-slide-title'>{this.state.gift.siteName}</span>
                                </div>
                                <div className='col-2' style={{paddingTop: 10}}>
                                    <img onClick={this.giftOnCloseView} src={closeIcon}
                                         className="close"/>
                                </div>
                            </div>
                        </DialogTitle>
                        <DialogContent classes={{root: classes.contentRoot}}>
                            {
                                this.state.loadingPage ? <LoadingPage opacity={0.5} /> : ''
                            }
                            <div style={{display: 'block',}}>

                                <div>
                                    <div style={{display: 'block'}}>
                                        <div className="store-header-title" style={{marginTop: '20px'}}>
                                            <span>Ссылка на подарок</span>
                                        </div>
                                        <div style={{width: '100%', wordBreak: 'break-word'}}>
                                            <a target='_blank'
                                               rel="noopener noreferrer"
                                               style={{
                                                   marginTop: '10px',
                                                   width: '78%'
                                               }} href={this.state.gift.url}>{this.state.gift.url}</a>
                                        </div>
                                    </div>
                                </div>

                                <div style={{
                                    height: '1px',
                                    width: '100%',
                                    borderRadius: '1px',
                                    backgroundColor: '#CFD6D8',
                                    marginTop: '20px',
                                    display: 'block'
                                }}>

                                </div>
                                <div>
                                    <div style={{display: 'block', marginTop: 20, width: '100%'}} className='d-flex justify-content-center'>
                                        <div style={{
                                            height: 400,
                                            width: 400,
                                            backgroundImage: 'url("' + this.state.gift.image + '")',
                                            backgroundSize: 'contain',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPositionX: 'center',
                                            backgroundPositionY: 'center',
                                            position: 'relative'
                                        }}>

                                        </div>
                                    </div>

                                    <div style={{display: 'block'}}>
                                        <div className="store-header-title" style={{marginTop: '20px'}}>
                                            <span>Название</span>
                                        </div>
                                        <div>
                                                    <span
                                                        style={{
                                                            marginTop: '10px',
                                                            width: '100%'
                                                        }}>{this.state.gift.title}</span>
                                        </div>
                                    </div>
                                    {
                                        this.state.gift.description ?
                                            <div style={{display: 'block', marginBottom: 20}}>
                                                <div className="store-header-title" style={{marginTop: '20px'}}>
                                                    <span>Описание</span>
                                                </div>
                                                <div>
                                                    <span
                                                        className='gift-description'
                                                        style={{
                                                            marginTop: '10px'
                                                        }}>{this.state.gift.description}</span>
                                                </div>
                                            </div>
                                            :''
                                    }

                                </div>

                            </div>
                            <CircularProgress
                                style={{display: this.state.sendingInProgress ? 'block' : 'none'}}
                                color="secondary"/>
                        </DialogContent>

                    </Dialog>
                </div>





                <div>
                    <Dialog
                        open={this.state.deleteIsOpen}
                        TransitionComponent={this.Transition}
                        keepMounted
                        onClose={this.onDeleteGiftClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title">
                            {"Вы точно хотите удалить запись?"}
                        </DialogTitle>
                        <DialogContent className='d-flex justify-content-center'>
                            {
                                this.state.loadingPage ? <LoadingPage opacity={0.5} /> : ''
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.onDeleteGiftClose} color="primary">
                                Отмена
                            </Button>
                            <Button disabled={this.state.deleteInProgress} onClick={this.onDeleteGiftPressed} color="secondary">
                                Удалить
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.deliveredDialog}
                        TransitionComponent={this.Transition}
                        keepMounted
                        onClose={this.sendOnClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title" className='d-flex justify-content-center'>
                            Вы получили товар?
                        </DialogTitle>
                        <DialogContent className='d-flex justify-content-center'>
                            <CircularProgress
                                style={{display: this.state.deliveredInProgress ? 'block' : 'none'}}
                                color="secondary"/>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.sendOnClose} color="secondary">
                                Отмена
                            </Button>
                            <Button disabled={this.state.deliveredInProgress} onClick={this.sendStatusToChange}
                                    color="primary">
                                Получил
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.reportOpen}
                        TransitionComponent={this.Transition}
                        keepMounted
                        onClose={this.sendOnCloseReport}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title" className='d-flex justify-content-center'>
                            Пожаловаться на заказ
                        </DialogTitle>
                        <DialogContent className='d-flex justify-content-center'>
                            <div>

                            </div>
                            <div>
                                <div className='d-flex justify-content-center'>
                                    <CircularProgress
                                        style={{display: this.state.sendingInProgressReport ? 'block' : 'none'}}
                                        color="secondary"/>
                                </div>
                                <textarea
                                    className='textarea-element'
                                    value={this.state.messageReport}
                                    onChange={this.onChangeReport}
                                    placeholder={'Введите сообщение'}
                                    style={{
                                        padding: '20px',
                                        marginTop: '10px',
                                        height: '150px'
                                    }}/>

                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.sendOnCloseReport} color="secondary">
                                Отмена
                            </Button>
                            <button className='save-btn-40' disabled={this.state.sendingInProgressReport}
                                    onClick={this.sendReport}>
                                Отправить
                            </button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        );
    }
}



const mapStateToProps = (state) => {
    return {
        dict: state.dictionary.dict,
        user: state.user,
        settings: state.settings,
        basket: state.basket,
        messages: state.messages,
        notification: state.notification,
        localStorageValues: state.localStorageValues,
        userAgent: state.userAgentUtil
    };
};

export default withStyles(styles)(connect(mapStateToProps)(UserCabinetPage));