import React from "react";
import connect from "react-redux/es/connect/connect";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {Link, Redirect} from "react-router-dom";
import {refreshSession} from "../../actions/authorization";
import {getAllPhotos} from "../../api/AdminPhotoAPI";
import Pagination from "material-ui-flat-pagination";
import * as queryString from "query-string";
import {withStyles} from "@material-ui/core";
import PropTypes from "prop-types";

const styles = theme => ({

    root: {
        flexGrow: 1,
        width: '100%',
        height: 50,
        display: 'inline-grid'
    },

    select: {
        color: 'red',
        width: '100%'
    },
    content: {
        margin: 0
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(0),
        color: '#5B6273',
        fontFamily: "Open Sans",
        fontSize: '12px',
        lineHeight: '17px'
    },
    textSecondary: {
        opacity: '0.5',
        color: '#283149',
        fontFamily: 'Open Sans',
        fontSize: '14px',
        lineHeight: '19px'
    },
    textPrimary: {
        color: '#4A90E2', fontFamily: 'Open Sans', fontSize: '14px', lineHeight: '19px'
    }

});



class AdminPhotoListComponent extends React.Component{

    constructor(props, context) {
        super(props, context);
        this.state = {
            filter: {
                page: 0,
                size: 100,
                sort: 'createdDate,desc'

            },
            photos: {
                content: []
            },
            loading: true,
            offset: 0,
            limit: 1,
            total: 1,
        }
    }

    componentDidMount() {
        if (window){
            window.scrollTo(0, 0);
        }
        this.searchProduct();
    }

    searchProduct = () => {
        this.setState(()=>({loading: true}));
        getAllPhotos({...this.state.filter}).then(
            response => {
                this.setState(() => ({
                    photos: JSON.parse(response),
                    loading: false
                }));
            },
            error => {
                this.setState(()=>({loading: false}));
                let resp = JSON.parse(error.message);
                if (resp.status === 401 || resp.status === 403) {
                    this.props.dispatch(refreshSession()).then(() => {
                        this.searchProduct();
                    });
                }
            }
        );
    };

    changePage = (e, offset, page) => {
        let filter = this.state.filter;
        filter.page = page - 1;
        this.setState(() => ({filter: filter, offset: offset}), () => {
            // const params = queryString.parse(window.location.search);
            // params.page = filter.page;
            // params.size = filter.size;
            // window.history.pushState(params, 'Поиск', '?' + queryString.stringify(params));
            if (typeof window !== 'undefined'){
                window.scrollTo(0, 0);
            }
            this.searchProduct();
        });

    };



    render() {

        const { classes, theme } = this.props;


        return (
            <div>

                {this.props.user.roles.filter((role) => (role.name === 'ROLE_ADMIN' || role.name === 'ROLE_MANAGER')).length < 1 ? <Redirect to={'/'}/> : ''}

                <div className="category-product-box row" style={{width: '100%'}}>


                    {   this.state.loading ? <CircularProgress color="secondary" style={{
                            marginLeft: '380px'
                        }} /> :
                        this.state.photos.content.map((photo) => {
                            return (
                                <div key={photo.id} className="col-6" style={{width: 150}}>
                                    <a href={photo.photoOriginal} target='_blank'>
                                        <div className="main-card-store-image"
                                             style={{
                                                 backgroundImage: 'url("' + photo.photomin + '")',
                                                 backgroundSize: 'contain',
                                                 backgroundRepeat: 'no-repeat',
                                                 backgroundPositionX: 'center',
                                                 backgroundPositionY: 'center'
                                             }}
                                        > </div>
                                    </a>

                                </div>
                            );
                        })
                    }


                </div>

                <div className={'d-flex justify-content-center'}>
                    <Pagination
                        limit={this.state.photos.size}
                        offset={this.state.offset}
                        total={this.state.photos.totalElements}
                        currentPageColor={'primary'}
                        otherPageColor={'secondary'}
                        classes={{textSecondary: classes.textSecondary, textPrimary: classes.textPrimary}}
                        onClick={(e, offset, page) => this.changePage(e, offset, page)}
                    />
                </div>


            </div>
        );
    }


}

const mapStateToProps = (state) => {
    return {
        dict: state.dictionary.dict,
        user: state.user,
        settings: state.settings,
        basket: state.basket,
        messages: state.messages
    };
};

AdminPhotoListComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

// export default connect(mapStateToProps)(AdminPhotoListComponent);
export default withStyles(styles, {withTheme: true})(connect(mapStateToProps)(AdminPhotoListComponent));