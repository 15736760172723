import React from "react";
import connect from "react-redux/es/connect/connect";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {Link, Redirect} from "react-router-dom";
import {refreshSession} from "../../actions/authorization";
import {approveStore, getNotApprovedStore} from "../../api/AdminStoreAPI";


class StoreListForApprove extends React.Component{

    constructor(props, context) {
        super(props, context);
        this.state = {
            filter: {
                page: 0,
                size: 100,
                category: props.match.params.alias,
                costFrom: props.dict.limits.min,
                costTo: props.dict.limits.max,
                color: ''

            },
            product: {
                content: []
            },
            loading: true
        }
    }

    componentDidMount() {
        if (window){
            window.scrollTo(0, 0);
        }
        this.searchProduct();
    }

    searchProduct = () => {
        this.setState(()=>({loading: true}));
        getNotApprovedStore({...this.state.filter}).then(
            response => {
                this.setState(() => ({
                    product: JSON.parse(response)
                }));
                this.setState(()=>({loading: false}));
            },
            error => {
                this.setState(()=>({loading: false}));
                let resp = JSON.parse(error.message);
                if (resp.status === 401 || resp.status === 403) {
                    this.props.dispatch(refreshSession()).then(() => {
                        this.searchProduct();
                    });
                }
            }
        );
    };

    approveStore = (goodsId) => {
        approveStore(goodsId).then(
            response => {
                this.searchProduct();
            },
            error => {
                this.setState(()=>({loading: false}));
                let resp = JSON.parse(error.message);
                if (resp.status === 401 || resp.status === 403) {
                    this.props.dispatch(refreshSession()).then(() => {
                        this.searchProduct();
                    });
                } else {
                    console.log(resp);
                }
            }
        );
    };

    render() {
        return (
            <div>

                {this.props.user.roles.filter((role) => (role.name === 'ROLE_ADMIN' || role.name === 'ROLE_MANAGER')).length < 1 ? <Redirect to={'/'}/> : ''}

                <div className="category-product-box" style={{marginLeft: '100px'}}>


                    {   this.state.loading ? <CircularProgress color="secondary" style={{
                            marginLeft: '380px'
                        }} /> :
                        this.state.product.content.filter(store => store.name).map((store) => {
                            return (
                                <div key={store.id} className="main-card-store-block">
                                    <div className="main-card-store-image"
                                         style={{
                                             backgroundImage: 'url("' + store.photoMin + '")',
                                             backgroundSize: 'contain',
                                             backgroundRepeat: 'no-repeat',
                                             backgroundPositionX: 'center',
                                             backgroundPositionY: 'center'
                                         }}
                                    > </div>
                                    <div className="main-card-goods-desc">
                                        <div className="main-card-goods-name">
                                            <Link className="main-card-store-name" to={'/admin/store/preview/'+store.id}>{store.name}</Link>
                                        </div>

                                        <div className="main-card-store-pin">
                                            <div className="main-card-store-pin-icon"> </div>
                                            <span className="main-card-store-location">{store.city}</span>
                                        </div>
                                        <div style={{
                                            marginTop: '15px',
                                        }}>
                                            <button className='btn btn-secondary create-btn-min'
                                                    onClick={ (e) => {
                                                        this.approveStore(store.id);
                                                    } } >Approve</button>
                                        </div>
                                    </div>

                                </div>
                            );
                        })
                    }


                </div>
            </div>
        );
    }


}

const mapStateToProps = (state) => {
    return {
        dict: state.dictionary.dict,
        user: state.user,
        settings: state.settings,
        basket: state.basket,
        messages: state.messages
    };
};

export default connect(mapStateToProps)(StoreListForApprove);