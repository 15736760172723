import React from 'react';
import {login} from '../api/AuthorizationApi';
import {connect} from "react-redux";
import {loadUser} from "../actions/user";
import {Link, Redirect} from "react-router-dom";
import queryString from 'query-string';
import * as ReactGA from "react-ga";
import {saveSession} from "../actions/authorization";
import {loadBasket} from "../actions/basket";
import {Helmet} from "react-helmet";

class LoginPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            isRedirect: false,
            redirectURL: '/'
        }
    }

    onEmailChange = (e) => {
        const email = e.target.value;
        this.setState(() => ({email}));
    };

    onPasswordChange = (e) => {
        const password = e.target.value;
        this.setState(() => ({password}));
    };

    onSubmit = (e) => {
        e.preventDefault();
        login({login: this.state.email, password: this.state.password}).then(
            response => {
                localStorage.setItem("session", response);
                this.setState({isRedirect: true});
                this.props.dispatch(loadUser());
                this.props.dispatch(saveSession(JSON.parse(response)));
                this.props.dispatch(loadBasket());
            },
            error => {
                const err = JSON.parse(error.message);
                if (err.code === 20) {
                    this.refs.emailError.style = {display: 'flex'};
                    this.refs.emailError.className = "d-flex justify-content-center error-message";
                }
                console.log(error.message);
            }
        );
    };

    onClose = () => {
        this.setState({redirectURL: '/', isRedirect: true});
    };

    componentDidMount()  {
        if (window){
            window.scrollTo(0, 0);
        }
        ReactGA.event({
            category: 'Login',
            action: 'Open login page'
        });
        ReactGA.pageview(this.props.history.location.pathname);
        let params = queryString.parse(this.props.location.search);
        if (params.redirectURL){
            this.setState({redirectURL: params.redirectURL});
        }
    }

    render() {
        return (
            <div>
                {this.state.isRedirect ? <Redirect to={this.state.redirectURL}/> : ''}
                <Helmet>
                    <title>Авторизация</title>
                </Helmet>
                <div className="row" style={{marginTop: '92px', marginLeft: 0, marginRight: 0}}>
                    <div className="col-12">
                        <div className="d-flex justify-content-center">
                            <span style={{
                                height: '40px',
                                width: '81px',
                                color: '#283149',
                                fontFamily: 'Tinos,sans-serif',
                                fontSize: '30px',
                                lineHeight: '40px'
                            }}>Войти</span>
                        </div>
                    </div>
                </div>
                <div className="row" style={{margin: 0}}>
                    <div className="col-12">
                        <div className="d-flex justify-content-center">
                            <span style={{
                                opacity: '0.5',
                                color: '#283149',
                                fontFamily: 'Open Sans,sans-serif',
                                fontSize: '16px',
                                lineHeight: '22px'
                            }}>Нет аккаунта на Crafty?</span> <Link style={{marginLeft: '0.86%'}} to={'/registration'}>Создать</Link>
                        </div>
                    </div>
                </div>
                <form onSubmit={this.onSubmit}>
                    <div style={{marginTop: '8.8%'}}>
                        <div className="col-12">
                            <div className="d-flex justify-content-center">
                                <input type="email" className="email-field form-control" id="email-login"
                                       aria-describedby="emailHelp" placeholder="Email"
                                       onChange={this.onEmailChange}
                                       // autoFocus
                                       value={this.state.email}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{marginTop: '2.38%'}}>
                        <div className="col-12">
                            <div className="d-flex justify-content-center">
                                <input type="password" className="email-field form-control" id="password-login"
                                       aria-describedby="emailHelp" placeholder="Пароль"
                                       value={this.state.password}
                                       onChange={this.onPasswordChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{marginTop: '30px', marginLeft: 0, marginRight: 0}}>
                        <div className="col-12">
                            <span ref="emailError" style={{display: 'none'}}>Неверный логин или пароль</span>
                            <div className="d-flex justify-content-center">
                                <button type="submit" className="btn-login btn btn-danger"><span
                                    className="btn-login-label">Войти</span></button>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="row" style={{marginTop: '15px', marginRight: 0, marginLeft: 0}}>
                    <div className="col-12">
                        <div className="d-flex justify-content-center">
                            <Link className="forgot-password" to={'/login/forgot'}>Забыли пароль?</Link>
                        </div>
                    </div>
                </div>

            </div>
        );
    };

}

export default connect()(LoginPage);