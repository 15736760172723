import React, {Component} from "react";
import {withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import {refreshSession} from "../actions/authorization";
import {
    addBookmark,
    addToBasket, commentGoods,
    deleteBasket,
    getComment,
    getFilteredGoods,
    getGoodsById, getMyGoodsById,
    removeBookmark
} from "../api/GoodsAPI";
import Slide from "@material-ui/core/Slide";
import {sendNotification} from "../actions/notifications";
import {saveBasket} from "../actions/basket";
import {reportGoods} from "../api/ReportAPI";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Carousel, {Modal, ModalGateway} from "react-images";
import {Link, Redirect} from "react-router-dom";
import LoadingPage from "./LoadingPage";
import * as moment from "moment";
import ProductListComponent from "./ProductListComponent";
import {Helmet} from "react-helmet";
import * as ReactGA from "react-ga";
import ym from "react-yandex-metrika";
import {selectByAliasChild} from "../selectors/category";
import * as VanillaSharing from 'vanilla-sharing';
import {logSocial} from "../api/LogAPI";
import {originalPhoto} from "../api/AdminGoodsAPI";
import {
    avatarIcon,
    fbShareIcon, heartBookmarkIcon, heartFilledBookmarkIcon,
    okShareIcon,
    pinterestShareIcon, plusIcon,
    telegramShareIcon, twitterShareIcon, viberShareIcon,
    vkShareIcon,
    whatsappShareIcon
} from "../assets/asstes";

const styles = theme => ({
    root: {

    },
    category: {
        color: "#E60936",

    }
});


const customStyles = {
    view: () => ({
        // none of react-images styles are passed to <View />
        height: '24rem',
        // width: 600,
    })
};




class ProductPage extends Component{

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: {},
            product: {
                approved: false
            },
            photos: [],
            otherProduct: [],
            redirect: false,
            redirectLogin: false,
            message: '',
            reportOpen: false,
            sendingInProgress: false,
            photoIsOpen: false,
            photoArray: [],
            photoArrayMid: [],
            commentFilter: {
                page: 0,
                size: 4,
                goodsId: this.props.match.params.id
            },
            comments: null,
            messageIsOpen: false,
            comment: '',
            commentInProgress: false,
            redirectPhone: false,
            currentIndex: 0,
            loadingAction: false
        }
        ;
    }

    CustomView = (innerProps) => {

        return (
            <div className="product-image-lg"
                 onClick={() => {
                     this.toggleViewer(innerProps.currentIndex);
                 }}
                 style={{
                     backgroundImage: 'url("'
                         + innerProps.data.source + '")',
                     backgroundSize: 'contain',
                     backgroundRepeat: 'no-repeat',
                     backgroundPosition: 'center'
                 }}>

            </div>
        );
    };

    FooterCount = (props) => {

        // console.log(props);
        const activeView = props.currentIndex + 1;
        const totalViews = props.views.length;
        return (
            <div>
                <span>{activeView} из {totalViews}</span>
            </div>
        );
    };

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id){
            this.setState({
                currentIndex: 0,
                photoIsOpen: false,
                commentFilter: {
                    page: 0,
                    size: 4,
                    goodsId: this.props.match.params.id
                } }, () => {
                this.updateProduct();
            });
        }
    }

    componentDidCatch(error, info) {
        this.setState({currentIndex: 0});

    }

    componentDidMount() {
        window.scrollTo(0, 0);
        ReactGA.event({
            category: 'Product',
            action: 'Open product',
        });
        ym('product', this.props.history.location.pathname);
        ReactGA.pageview(this.props.history.location.pathname);
        this.updateProduct();

    }

    Transition = (props) => {
        return <Slide direction="up" {...props} />;
    };



    toggleViewer = (currentIndex = 0) => {
        this.setState(() => ({photoIsOpen: !this.state.photoIsOpen, currentIndex: currentIndex}));
    };

    updateProduct = () => {
        window.scrollTo(0, 0);
        let product;

        if (window.location.href.includes('/manage/preview')) {

            getMyGoodsById(this.props.match.params.id).then(
                response => {
                    product = JSON.parse(response);
                    this.setState(() => {
                        return {currentIndex: 0, product: product};
                    }, () => {
                        getComment(this.state.commentFilter).then(
                            response => {
                                this.setState({comments: response.data});
                            }
                        ).catch(
                            error => {
                                // console.log(error);
                            }
                        );
                    });
                    this.loadOther(product.store.id);
                    let photos = product.photos;
                    photos.sort((a, b) => {
                        if (a.orderPlace < b.orderPlace) {
                            return -1;
                        }
                        if (a.orderPlace > b.orderPlace) {
                            return 1;
                        }
                        return 0;
                    });
                    let photoArray = [];
                    let photoArrayMid = [];
                    photos.forEach(photo => {
                        photoArray.push({source: photo.photoMax});
                        photoArrayMid.push({source: photo.photoMid});
                    });
                    this.setState(() => ({photos: photos, photoArray: photoArray, photoArrayMid: photoArrayMid}));
                },
                error => {
                    // this.setState({redirect: true});
                    let resp = JSON.parse(error.message);
                    if (error.code === 401 || error.code === 403) {
                        this.props.dispatch(refreshSession()).then(() => {
                            this.updateProduct();
                        });
                    }
                }
            );


        }else {
            getGoodsById(this.props.match.params.id).then(
                response => {
                    product = JSON.parse(response);
                    this.setState(() => {
                        return {currentIndex: 0, product: product};
                    }, () => {
                        getComment(this.state.commentFilter).then(
                            response => {
                                this.setState({comments: response.data});
                            }
                        ).catch(
                            error => {
                                // console.log(error);
                            }
                        );
                    });
                    this.loadOther(product.store.id);
                    let photos = product.photos;
                    photos.sort((a, b) => {
                        if (a.orderPlace < b.orderPlace) {
                            return -1;
                        }
                        if (a.orderPlace > b.orderPlace) {
                            return 1;
                        }
                        return 0;
                    });
                    let photoArray = [];
                    let photoArrayMid = [];
                    photos.forEach(photo => {
                        photoArray.push({source: photo.photoMax});
                        photoArrayMid.push({source: photo.photoMid});
                    });
                    this.setState(() => ({photos: photos, photoArray: photoArray, photoArrayMid: photoArrayMid}));
                },
                error => {
                    // this.setState({redirect: true});
                    let resp = JSON.parse(error.message);
                    if (error.code === 401 || error.code === 403) {
                        this.props.dispatch(refreshSession()).then(() => {
                            this.updateProduct();
                        });
                    }
                }
            );

        }

    };

    getCommentLabel = (number) => {
        if (number > 4 && number < 20) {
            return 'отзывов';
        }
        let numStr = number.toString();
        let lastNumber  = 0;
        if (numStr.length > 1) {
            lastNumber = Number.parseInt(numStr.substr(numStr.length - 1));
        }else{
            lastNumber = Number.parseInt(numStr);
        }
        if (lastNumber === 0 || lastNumber > 4) {
            return 'отзывов';
        }
        if (lastNumber === 1) {
            return 'отзыв';
        }
        if (lastNumber > 1 && lastNumber < 5) {
            return 'отзыва';
        }
        return 'отзывов';
    };

    getViewsLabel = (number) => {
        if (number > 4 && number < 20) {
            return 'просмотров';
        }
        let numStr = number.toString();
        let lastNumber  = 0;
        if (numStr.length > 1) {
            lastNumber = Number.parseInt(numStr.substr(numStr.length - 1));
        }else{
            lastNumber = Number.parseInt(numStr);
        }
        if (lastNumber === 0 || lastNumber > 4) {
            return 'просмотров';
        }
        if (lastNumber === 1) {
            return 'просмотр';
        }
        if (lastNumber > 1 && lastNumber < 5) {
            return 'просмотра';
        }
        return 'просмотров';
    };
    commentOnChange = (ev) => {
        this.setState({comment: ev.target.value});
    };

    loadOther = (id) => {
        let otherProduct;
        const filter = {
            page: 0,
            size: 6,
            storeId: id
        };
        getFilteredGoods(filter).then(
            response => {
                otherProduct = JSON.parse(response);
                this.setState(() => ({otherProduct: otherProduct.content}));
            },
            error => {
                // console.log(error);
            }
        );
    };

    hexToRGB = (hex) => {
        const RGB_HEX = /^#?(?:([\da-f]{3})[\da-f]?|([\da-f]{6})(?:[\da-f]{2})?)$/i;
        const [, short, long] = String(hex).match(RGB_HEX) || [];

        if (long) {
            const value = Number.parseInt(long, 16);
            return [value >> 16, value >> 8 & 0xFF, value & 0xFF];
        } else if (short) {
            return Array.from(short, s => Number.parseInt(s, 16)).map(n => (n << 4) | n);
        }

    };

    bookmark = () => {
        if (!this.props.user.name){
            this.setState({redirectLogin: true});
            return;
        }
        this.setState({loadingAction: true});
        addBookmark(this.state.product.id).then(
            response => {
                let product = {...this.state.product};
                product.booked = true;
                this.setState({product: product, loadingAction: false});

                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Добавлено в закладки',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));
            },
            error => {
                // console.log(error.message);
                this.setState({loadingAction: false});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Ошибка при добавлении',
                        key: new Date().getTime(),
                        variant: 'error'
                    }
                }));
            }
        );

    };

    removeBookmark = () => {

        this.setState({loadingAction: true});
        removeBookmark(this.state.product.id).then(
            response => {
                let product = {...this.state.product};
                product.booked = false;
                this.setState({product: product, loadingAction: false});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Удалено из закладок',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));
            },
            error => {
                // console.log(error.message);
                this.setState({loadingAction: false});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Ошибка при удалении',
                        key: new Date().getTime(),
                        variant: 'error'
                    }
                }));
            }
        );

    };

    addOrderToBasket = () => {
        if (!this.props.user.name){
            this.setState({redirectLogin: true});
            return;
        }
        this.setState({loadingAction: true});
        let checkInBasket = this.props.basket.items.filter(value => {
            return value.item.id === this.state.product.id;
        }).length > 0;
        if (checkInBasket){
            deleteBasket(this.state.product.id).then(
                response => {
                    this.setState({loadingAction: false});
                    this.props.dispatch(saveBasket(JSON.parse(response)));
                    this.props.dispatch(sendNotification({
                        open: true,
                        messageInfo: {
                            message: 'Товар удален из корзины',
                            key: new Date().getTime(),
                            variant: 'success'
                        }
                    }));
                },
                error => {
                    // console.log(error.message);
                    this.setState({loadingAction: false});
                    this.props.dispatch(sendNotification({
                        open: true,
                        messageInfo: {
                            message: 'Ошибка при удалении',
                            key: new Date().getTime(),
                            variant: 'error'
                        }
                    }));
                }
            );
        } else {
            addToBasket(this.state.product.id).then(
                response => {
                    this.setState({loadingAction: false});
                    this.props.dispatch(saveBasket(JSON.parse(response)));
                    this.props.dispatch(sendNotification({
                        open: true,
                        messageInfo: {
                            message: 'Товар добавлен в корзину',
                            key: new Date().getTime(),
                            variant: 'success'
                        }
                    }));
                },
                error => {
                    // console.log(error.message);
                    this.setState({loadingAction: false});
                    this.props.dispatch(sendNotification({
                        open: true,
                        messageInfo: {
                            message: 'Ошибка при добавлении',
                            key: new Date().getTime(),
                            variant: 'error'
                        }
                    }));
                }
            );
        }

    };

    sendComment = () => {

        let comment = this.state.comment.trim();
        if (comment.length === 0){
            this.sendOnClose();
            return;
        }

        commentGoods({goodsId: this.props.match.params.id, comment: this.state.comment}).then(
            response => {
                // console.log('response');
                // console.log(response);
                getComment(this.state.commentFilter).then(
                    response => {
                        this.setState({comments: response.data});
                        this.sendOnClose();

                    }
                ).catch(
                    error => {
                        // console.log(error.message);
                    }
                );

            }
        ).catch(
            error => {
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Ошибка при публикации отзыва. Обновите страницу.',
                        key: new Date().getTime(),
                        variant: 'error'
                    }
                }));
                this.sendOnClose();
            }
        );
    };

    sendOnClose = () => {
        this.setState({reportOpen: false, messageIsOpen: false, commentInProgress: false, comment: ''});
    };

    reportOnOpen = () => {
        this.setState({reportOpen: true});
    };

    sendReport = () => {
        this.setState({sendingInProgress: true});
        reportGoods(this.props.match.params.id, this.state.message).then(
            response => {
                this.setState({message: '', reportOpen: false, sendingInProgress: false});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Жалоба отправлена',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));
            },
            error => {
                this.setState({message: '', reportOpen: false, sendingInProgress: false});
            }
        );
    };

    onChangeReport = (ev) => {

        let message = ev.target.value;
        this.setState({message});
    };

    loadAllComments = () => {
        this.setState({commentFilter: {
                page: 0,
                size: 300,
                goodsId: this.props.match.params.id
            }}, () => {
            getComment(this.state.commentFilter).then(
                response => {
                    this.setState({comments: response.data});
                    this.sendOnClose();

                }
            ).catch(
                error => {
                    // console.log(error.message);
                }
            );
        });
    };

    logSocialShared = (type) => {
        const userId = this.props.user.name ? this.props.user.id : null;
        logSocial({page: 'https://crafty.by/product/' + this.state.product.id, userId: userId, type: type});
    };


    sharePinterest= () => {
        VanillaSharing.pinterest({
            url: ('https://crafty.by/product/' + this.state.product.id),
            description: (this.state.product.name + '. ' + this.state.product.description),
            media: (this.state.product.photos.length > 0
                ? this.state.product.photos.filter((ph) => {
                    return ph.orderPlace === 1
                })[0].photoMax
                : ''),
        });
        this.logSocialShared('PINTEREST');
    };

    shareFacebook = () => {
        VanillaSharing.fbButton({
            url: ('https://crafty.by/product/' + this.state.product.id)
        });
        this.logSocialShared('FACEBOOK');
    };


    shareVk = () => {
        VanillaSharing.vk({
            url: ('https://crafty.by/product/' + this.state.product.id),
            title: this.state.product.name,
            description: this.state.product.description,
            image: (this.state.product.photos.length > 0
                ? this.state.product.photos.filter((ph) => {
                    return ph.orderPlace === 1
                })[0].photoMax
                : ''),
            isVkParse: true,
        });
        this.logSocialShared('VK');
    };

    shareOk = () => {
        VanillaSharing.ok({
            url: ('https://crafty.by/product/' + this.state.product.id),
            title: this.state.product.name,
            image: (this.state.product.photos.length > 0
                ? this.state.product.photos.filter((ph) => {
                    return ph.orderPlace === 1
                })[0].photoMax
                : ''),
        });
        this.logSocialShared('OK');
    };

    shareViber = () => {
        VanillaSharing.viber({
            url: ('https://crafty.by/product/' + this.state.product.id),
            title: this.state.product.name,
        });
        this.logSocialShared('VIBER');
    };


    shareTelegram = () => {
        VanillaSharing.telegram({
            url: ('https://crafty.by/product/' + this.state.product.id),
            title: this.state.product.name,
        });
        this.logSocialShared('TELEGRAM');
    };

    shareWhatsApp = () => {
        VanillaSharing.whatsapp({
            url: ('https://crafty.by/product/' + this.state.product.id),
            title: this.state.product.name,
        });
        this.logSocialShared('WHATSAPP');
    };


    getOriginalPhoto = () => {
        originalPhoto(this.state.product.id).then(
            response => {
                let originals = JSON.parse(response);

                let photoArray = [];
                originals.forEach(photo => {
                    photoArray.push({source: photo.photoOriginal});
                });
                this.setState(() => ({photoArray: photoArray}));

            },
            error => {

            }
        );
    };


    shareTwitter = () => {
        VanillaSharing.tw({
            url: ('https://crafty.by/product/' + this.state.product.id),
            title: this.state.product.name,
            hashtags: ['craftyby', 'handmade', 'ручнаяработа']
        });
        this.logSocialShared('TWITTER');
    };



    render() {
        const { classes } = this.props;
        let {currentIndex} = this.state;
        const category = this.state.product.category ? selectByAliasChild(this.props.dict.categories, this.state.product.category.alias) : null;
        return (
            <div>
                {this.state.redirect ? <Redirect to={{pathname: '/', state: {status: 404}}}/> : ''}
                {this.state.redirectLogin ? <Redirect to={'/login?redirectURL=/product/'+this.state.product.id}/> : ''}
                {this.state.redirectPhone ? <Redirect to={'/cabinet/edit'}/> : ''}
                {
                    this.state.product.name && this.state.photoArrayMid.length > 0 ?
                        <div>
                            {
                                this.state.loadingAction ? <LoadingPage opacity={0.5}/> : ''
                            }
                            <Helmet>
                                <title>{this.state.product.name ? (this.state.product.name.replace(/[^0-9a-zA-ZА-Яа-яЁё .,?!:;_]/gi, ' ')  + ' ручной работы - купить на Crafty.by | ' + this.state.product.store.city + ' | ' + this.state.product.id ): ''}</title>
                                <meta name="description" content={this.state.product.description ? (this.state.product.description.substring(0, 160).replace(/[^0-9a-zA-ZА-Яа-яЁё .,?!:;_]/gi, ' ')) : ''} />
                                <meta name="og:description" content={this.state.product.description ? (this.state.product.description.substring(0, 160).replace(/[^0-9a-zA-ZА-Яа-яЁё .,?!:;_]/gi, ' ')) : ''} />
                                <meta name="og:title" content={(this.state.product.name ? (this.state.product.name.replace(/[^0-9a-zA-ZА-Яа-яЁё .,?!:;_]/gi, ' ')  + ' ручной работы - купить на Crafty.by | ' + this.state.product.store.city + ' | ' + this.state.product.id ) : '')} />
                                <meta property="og:image" content={(this.state.product.photos.length > 0
                                    ? this.state.product.photos.filter((ph) => {
                                        return ph.orderPlace === 1
                                    })[0].photoMid
                                    : '')} />
                                <link rel="canonical" href={'https://crafty.by/product/'+this.state.product.id} />
                                <meta name='keywords' content={this.state.product.name.replace(/[^0-9a-zA-ZА-Яа-яЁё .,?!:;_]/gi, ' ') + ', купить, ручной работы, заказать, цена, в интернет магазине, ' + this.state.product.store.city} />
                                <meta property="og:url" content={'https://crafty.by/product/'+this.state.product.id}/>
                                <meta property="og:type" content="product" />
                                <meta property="og:availability" content={this.state.product.isAvailable ? 'instock' : 'preorder'} />
                                <meta property="product:price:amount" content={this.state.product.cost.cost} />
                                <meta property="product:price:currency" content="BYN" />
                                <meta property="og:brand" content={this.state.product.store.name}/>

                            </Helmet>
                            <div  className="product-image-lg">
                                <Carousel currentIndex={currentIndex}
                                          styles={customStyles}
                                          views={this.state.photoArrayMid}
                                          components={{ View: this.CustomView, FooterCount: this.FooterCount }}/>
                            </div>

                            {
                                category ?
                                    <div className='path-category' style={{marginTop: 5}}>
                                        <Link className='path-category' to={'/catalog/' + category.parent.alias}>{category ? category.parent.name : ''}</Link>
                                        <span className='path-category'> / </span>
                                        <Link className='path-category' to={'/catalog/' + category.parent.alias + '/' + category.alias}>{category ? category.name : ''}</Link>
                                    </div>
                                    : ''
                            }

                            {
                                this.state.product.approved ?
                                    <div className='share-social d-flex justify-content-start'>
                                        <button className='button-social' style={{backgroundColor: '#FFFFFF', border: "none", outline: "none", paddingLeft: 0}} onClick={this.sharePinterest}>
                                            <img style={{width: 30, height: 30}} src={pinterestShareIcon} alt="Поделиться в Pinterest"/>
                                        </button>
                                        <button className='button-social'  style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareFacebook}>
                                            <img style={{width: 30, height: 30}} src={fbShareIcon} alt="Поделиться в Facebook"/>
                                        </button>
                                        <button className='button-social'  style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareVk}>
                                            <img style={{width: 30, height: 30}} src={vkShareIcon} alt="Поделиться во Вконтакте"/>
                                        </button>
                                        <button className='button-social'  style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareOk}>
                                            <img style={{width: 30, height: 30}} src={okShareIcon} alt="Поделиться в Одноклассниках"/>
                                        </button>
                                        <button  className='button-social' style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareTelegram}>
                                            <img style={{width: 30, height: 30}} src={telegramShareIcon} alt="Поделиться в Telegram"/>
                                        </button>
                                        <button className='button-social'  style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareWhatsApp}>
                                            <img style={{width: 30, height: 30}} src={whatsappShareIcon} alt="Поделиться в WhatsApp"/>
                                        </button>
                                        <button className='button-social'  style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareTwitter}>
                                            <img style={{width: 30, height: 30}} src={twitterShareIcon} alt="Поделиться в Twitter"/>
                                        </button>
                                        <button className='button-social'  style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareViber}>
                                            <img style={{width: 30, height: 30}} src={viberShareIcon} alt="Поделиться в Viber"/>
                                        </button>

                                    </div>
                                    : ''
                            }
                            {
                                (this.props.user.roles && this.props.user.roles.filter((role) => (role.name === 'ROLE_ADMIN' || role.name === 'ROLE_MANAGER')).length > 0)
                                    ?
                                    <button onClick={this.getOriginalPhoto} style={{color: 'blue', fontSize: 12, marginLeft: 0, paddingLeft: 0}}>Оригинал</button>
                                    : ''
                            }



                            <div className='product-page-name'>
                                <h1 className='product-page-name-h1'>{this.state.product.name}</h1>
                            </div>

                            <div className='product-cost-block' style={{position: "relative"}}>
                                <div className='product-cost'>
                                        {
                                            this.state.product.oldCost ?
                                                <span className='crossed-red-cost' style={{fontSize: 12}}>{this.state.product.oldCost.cost} BYN  </span>
                                                : ''
                                        }
                                        <span>{this.state.product.cost.cost} BYN</span>
                                </div>
                                <div style={{position: "absolute", right: 0, top: 0}}>
                                    <button onClick={this.addOrderToBasket} className='basket-button'>
                                        {
                                            this.props.user.name
                                                ? (this.props.basket.items.filter(value => {
                                                    return value.item.id === this.state.product.id;
                                                }).length > 0) ? 'Добавлено в корзину' : 'Добавить в корзину'
                                                : 'Добавить в корзину'
                                        }
                                    </button>
                                    <button onClick={this.state.product.booked ? this.removeBookmark : this.bookmark} className='product-bookmark-button'>
                                        {
                                            this.state.product.booked
                                                ?
                                                <img className='heart' src={heartFilledBookmarkIcon} alt={'Удалить из закладок'} />
                                                :
                                                <img className='heart' src={heartBookmarkIcon} alt={'Добавить в закладки'}/>
                                        }
                                    </button>
                                </div>
                            </div>
                            <Link to={'/store/'+this.state.product.store.id} className='store-product-link'>
                                <div className='product-store-block'>
                                    <div className='product-store-image'
                                         style={{
                                             backgroundImage: 'url("' + this.state.product.store.photoMin + '")',
                                             backgroundSize: 'cover',
                                             backgroundRepeat: 'no-repeat',
                                             backgroundPosition: 'center',
                                             display: 'inline-block'
                                         }}>

                                    </div>
                                    <div className='product-store-block-text' style={{
                                        display: 'inline-block',
                                        position: 'absolute',
                                        top: 0,

                                    }}>
                                        <div className='product-store-label'>
                                            <span>Магазин</span>
                                        </div>
                                        <div className='product-store-name'>
                                            <span>{this.state.product.store.name}</span>
                                        </div>
                                    </div>
                                </div>
                            </Link>

                            <div className='product-label'>
                                Описание
                            </div>
                            <div className="product-page-description" style={{wordBreak: 'break-word'}}>
                                <p style={{whiteSpace: 'pre-wrap'}}>
                                    {this.state.product.description}
                                </p>
                            </div>

                            {
                                this.state.product.materials ?
                                    <div>
                                        <div className='product-label'>
                                            Материалы
                                        </div>
                                        <div className='product-material-line'>
                                            <span>{this.state.product.materials}</span>
                                        </div>
                                    </div>
                                    : ''
                            }

                            <div className='product-label'>
                                Наличие
                            </div>
                            <div className="product-page-description" style={{wordBreak: 'break-word'}}>
                                <span style={{whiteSpace: 'pre-wrap'}}>
                                    {this.state.product.isAvailable ? 'В наличии' : 'Под заказ'}
                                </span>
                            </div>

                            <div className='product-label'>
                                Доставка
                            </div>

                            <div className='product-delivery' style={{overflowX: "auto", whiteSpace: 'nowrap'}}>

                                {
                                    this.state.product.store.deliveries.map((deliv, idx) => {
                                        return (
                                            <div  key={idx} style={{marginTop: 5}}>
                                                <span className='product-delivery-type-line'>{deliv.deliveryType.name} :</span><span className='product-delivery-line'> {deliv.city}.
                                                    Срок: {deliv.period}.
                                                    Стоимость: {deliv.cost ? (deliv.cost + ' ' + deliv.currency) : 'Бесплатно'} </span>
                                            </div>
                                        );
                                    })
                                }
                            </div>

                            <div className='product-label'>
                                Отзывы
                            </div>

                            <div>
                                <div className='store-header-title'>
                                    <span>{this.state.comments ? (this.state.comments.totalElements + ' ' + this.getCommentLabel(this.state.comments.totalElements))
                                        : (0 + ' ' + this.getCommentLabel(0))} {}</span> <span>|</span> <span >{this.state.product.views} {this.getViewsLabel(this.state.product.views)}</span>
                                </div>
                            </div>



                            <div>
                                {
                                    this.state.comments ?
                                        this.state.comments.content.map(comment => {
                                            return (
                                                <div key={comment.id}>
                                                    <div className='row' style={{marginLeft: 0, marginRight: 0}}>
                                                        <div className='col' style={{width: 40, maxWidth: 40, marginTop: 17, paddingLeft: 0, paddingRight: 0}}>
                                                            <div style={{
                                                                height: '30px',
                                                                width: '30px',
                                                                borderRadius: '50%',
                                                                backgroundImage: 'url("' + (comment.user.photoMin ? comment.user.photoMin : avatarIcon) + '")',
                                                                backgroundSize: (comment.user.photoMin ? 'cover' : 'contain'),
                                                                backgroundRepeat: 'no-repeat',
                                                                backgroundPositionX: 'center',
                                                                backgroundPositionY: 'center'
                                                            }}> </div>
                                                        </div>
                                                        <div className="col" style={{width: 200, maxWidth: 200, marginTop: 14, paddingLeft: 0}}>

                                                            <div className='comment-user-link'>
                                                                <Link to={'/user/'+comment.user.id}>{comment.user.name}</Link>
                                                            </div>
                                                            <div className='store-header-title' style={{marginBottom: 18}}>
                                                                <span>{moment.utc(comment.createdDate, 'YYYY-MM-DD').local(false).format('DD.MM.YYYY').toString()}</span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div>
                                                        <div className='product-comment-text-block'>

                                                            <div style={{whiteSpace: "break-spaces", paddingLeft: 40}}>
                                                                <span className='comment-text'>{comment.comment}</span>
                                                            </div>
                                                            <div style={{
                                                                height: '1px',
                                                                width: '100%',
                                                                borderRadius: '1px',
                                                                backgroundColor: '#CFD6D8',
                                                                marginTop: '26px',
                                                                display: 'block',
                                                            }}>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                        })
                                        : <span>Пока нет отзывов</span>
                                }

                                {
                                    (this.state.comments && this.state.comments.totalElements > 4 && this.state.comments.content.length < 5) ?
                                        <div className='d-flex justify-content-center' style={{marginTop: 20}}>
                                            <button onClick={this.loadAllComments} className='button-link'>Показать все</button>
                                        </div>
                                        : ''
                                }

                            </div>

                            <div style={{marginTop: '20px'}} className='d-flex justify-content-between'>
                                <span style={{marginRight: '20px', marginTop: '11px'}} className='text-12-grey'>Вы покупали этот товар?</span>
                                <button onClick={() => {
                                    if (!this.props.user.name){
                                        this.setState({redirectLogin: true});
                                        return;
                                    }
                                    if (!this.props.user.phoneIsConfirmed){
                                        this.setState({redirectPhone: true});
                                        return;
                                    }

                                    this.setState({messageIsOpen: true});
                                }} className='button-150' style={{paddingLeft: '0'}}>
                                    <img style={{marginBottom: '3px', marginRight: '5px'}}
                                         src={plusIcon}
                                         alt={'Добавить отзыв'}
                                         className="plus"/> Добавить отзыв</button>
                            </div>

                            <div className='product-label' style={{marginTop: 25}}>
                                Другие товары этого продавца <Link
                                to={'/store/' + this.state.product.store.id}>{this.state.product.store.name}</Link>
                            </div>

                            <div>
                                <ProductListComponent productList={this.state.otherProduct}/>
                            </div>
                        </div>

                        :
                        <LoadingPage opacity={1}/>
                }

                <div>
                    <Dialog
                        open={this.state.messageIsOpen}
                        // TransitionComponent={this.Transition}
                        keepMounted
                        onClose={this.sendOnClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title" className='d-flex justify-content-center'>
                            Добавить отзыв
                        </DialogTitle>
                        <DialogContent className='d-flex justify-content-center'>
                            <div>

                            </div>
                            <div >
                                {
                                    this.state.uploadingInProgress ?
                                        <div >
                                            <LinearProgress variant="determinate" value={this.state.progress}/>
                                        </div>
                                        : ''
                                }
                                <textarea
                                    // autoFocus={true}
                                    className='textarea-element'
                                    value={this.state.comment}
                                    onChange={this.commentOnChange}
                                    placeholder={'Введите комментарий'}
                                    style={{
                                        padding: '20px',
                                        marginTop: '10px'
                                    }} />

                            </div>
                            <CircularProgress
                                style={{display: this.state.commentInProgress ? 'block' : 'none'}}
                                color="secondary"/>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.sendOnClose} color="secondary">
                                Отмена
                            </Button>
                            <button className='save-btn-40' disabled={this.state.commentInProgress} onClick={this.sendComment}>
                                Отправить
                            </button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.reportOpen}
                        TransitionComponent={this.Transition}
                        keepMounted
                        onClose={this.sendOnClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title" className='d-flex justify-content-center'>
                            Пожаловаться на товар
                        </DialogTitle>
                        <DialogContent className='d-flex justify-content-center'>
                            <div>

                            </div>
                            <div >
                                <div  className='d-flex justify-content-center'>
                                    <CircularProgress
                                        style={{display: this.state.sendingInProgress ? 'block' : 'none'}}
                                        color="secondary"/>
                                </div>
                                <textarea
                                    className='textarea-element'
                                    value={this.state.message}
                                    onChange={this.onChangeReport}
                                    placeholder={'Введите сообщение'}
                                    style={{
                                        padding: '20px',
                                        marginTop: '10px',
                                        height: '150px'
                                    }} />

                            </div>
                        </DialogContent>
                        <DialogActions>
                            <input ref={this.fileInput} style={{display: 'none'}} type="file" onChange={this.fileOnChange}/>
                            <Button onClick={this.sendOnClose} color="secondary">
                                Отмена
                            </Button>
                            <button className='save-btn-40' disabled={this.state.sendingInProgress} onClick={this.sendReport}>
                                Отправить
                            </button>
                        </DialogActions>
                    </Dialog>
                </div>
                <ModalGateway>
                    {
                        this.state.photoIsOpen ?
                            <Modal

                                allowFullscreen={true}
                                onClose={() => {
                                    this.toggleViewer(currentIndex);
                                }}>
                                <Carousel

                                    interactionIsIdle={false}
                                    isFullscreen={true}
                                    views={this.state.photoArray}
                                    currentIndex={currentIndex}
                                    components={{ FooterCount: this.FooterCount }}/>
                            </Modal>
                            : ''
                    }
                </ModalGateway>
            </div>

        );
    }

}


const mapStateToProps = (state) => {
    return {
        dict: state.dictionary.dict,
        user: state.user,
        settings: state.settings,
        basket: state.basket,
        messages: state.messages
    };
};

export default withStyles(styles)(connect(mapStateToProps)(ProductPage));
