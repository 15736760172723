import url from '../settings/Settings';
import cookie from "react-cookies";

const login = (authorization) => {
    return new Promise(function(resolve, reject) {

        const xhr = new XMLHttpRequest();
        xhr.open('POST', url + '/v1.0/auth/token', true);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
        xhr.setRequestHeader('web', cookie.load('_web'));


        xhr.onload = function() {
            if (this.status === 200) {
                resolve(this.response);
            } else {
                let error = new Error(this.responseText);
                error.code = this.status;
                reject(error);
            }
        };

        xhr.onerror = function() {
            reject(new Error("Network Error"));
        };

        xhr.send(JSON.stringify(authorization));
    });
};

const registration = (registration) => {
    return new Promise(function(resolve, reject) {

        const xhr = new XMLHttpRequest();
        xhr.open('POST', url + '/v1.0/auth/registration', true);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
        xhr.setRequestHeader('web', cookie.load('_web'));


        xhr.onload = function() {
            if (this.status === 200) {
                resolve(this.response);
            } else {
                let error = new Error(this.responseText);
                error.code = this.status;
                reject(error);
            }
        };

        xhr.onerror = function() {
            reject(new Error("Network Error"));
        };

        xhr.send(JSON.stringify(registration));
    });
};



const refreshToken = (isError = false) => {
    return new Promise(function(resolve, reject) {

        const xhr = new XMLHttpRequest();
        xhr.open('POST', url + '/v1.0/auth/refresh', true);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
        xhr.setRequestHeader('web', cookie.load('_web'));

        let session = JSON.parse(localStorage.getItem("session"));
        let refresh;
        let skip = false;
        if (session){
            let remTime = (new Date(JSON.parse(localStorage.getItem('session')).expDate)- new Date()) / 1000 / 60;
            if(remTime > 5 && !isError){
                resolve(localStorage.getItem("session"));
                reject(localStorage.getItem("session"));
                return;
            }else{
                refresh = {
                    refreshToken: session.refreshToken
                };
            }

        }else{
            skip = true;
        }

        if(!skip){
            xhr.onload = function() {
                if (this.status === 200) {
                    resolve(this.response);
                } else {
                    let error = new Error(this.responseText);
                    error.code = this.status;
                    reject(error);
                }
            };

            xhr.onerror = function() {
                reject(new Error("Network Error"));
            };

            xhr.send(JSON.stringify(refresh));
        }

    });
};

const logout = () => {
    return new Promise(function(resolve, reject) {

        const xhr = new XMLHttpRequest();
        xhr.open('POST', url + '/v1.0/auth/logout', true);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
        xhr.setRequestHeader('web', cookie.load('_web'));

        let session = JSON.parse(localStorage.getItem("session"));
        if (session)
            xhr.setRequestHeader('Authorization', 'token ' + session.token);

        xhr.onload = function() {
            if (this.status === 200) {
                resolve(this.response);
            } else {
                let error = new Error(this.responseText);
                error.code = this.status;
                reject(error);
            }
        };

        xhr.onerror = function() {
            reject(new Error("Network Error"));
        };

        xhr.send();
    });
};

const resetPassword = (resetPassword) => {
    return new Promise(function(resolve, reject) {

        const xhr = new XMLHttpRequest();
        xhr.open('POST', url + '/v1.0/auth/password/reset', true);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
        xhr.setRequestHeader('web', cookie.load('_web'));


        let session = JSON.parse(localStorage.getItem("session"));
        if (session)
            xhr.setRequestHeader('Authorization', 'token ' + session.token);

        xhr.onload = function() {
            if (this.status === 200) {
                resolve(this.response);
            } else {
                let error = new Error(this.responseText);
                error.code = this.status;
                reject(error);
            }
        };

        xhr.onerror = function() {
            reject(new Error("Network Error"));
        };

        xhr.send(JSON.stringify(resetPassword));
    });
};

const forgotPassword = (email) => {
    return new Promise(function(resolve, reject) {

        const xhr = new XMLHttpRequest();
        xhr.open('POST', url + '/v1.0/auth/password/forgot', true);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
        xhr.setRequestHeader('web', cookie.load('_web'));


        xhr.onload = function() {
            if (this.status === 200) {
                resolve(this.response);
            } else {
                let error = new Error(this.responseText);
                error.code = this.status;
                reject(error);
            }
        };

        xhr.onerror = function() {
            reject(new Error("Network Error"));
        };

        xhr.send(JSON.stringify({email: email}));
    });
};


const repairPassword = (repairPassword) => {
    return new Promise(function(resolve, reject) {

        const xhr = new XMLHttpRequest();
        xhr.open('POST', url + '/v1.0/auth/password/repair', true);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
        xhr.setRequestHeader('web', cookie.load('_web'));


        xhr.onload = function() {
            if (this.status === 200) {
                resolve(this.response);
            } else {
                let error = new Error(this.responseText);
                error.code = this.status;
                reject(error);
            }
        };

        xhr.onerror = function() {
            reject(new Error("Network Error"));
        };

        xhr.send(JSON.stringify(repairPassword));
    });
};


const checkAuth = () => {
    return new Promise(function(resolve, reject) {

        const xhr = new XMLHttpRequest();
        xhr.open('GET', url + '/v1.0/auth/check', true);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
        xhr.setRequestHeader('web', cookie.load('_web'));


        let session = JSON.parse(localStorage.getItem("session"));
        if (session)
            xhr.setRequestHeader('Authorization', 'token ' + session.token);


        xhr.onload = function() {
            if (this.status === 200) {
                resolve(this.response);
            } else {
                let error = new Error(this.responseText);
                error.code = this.status;
                reject(error);
            }
        };

        xhr.onerror = function() {
            reject(new Error("Network Error"));
        };

        xhr.send();
    });
};


export {login, refreshToken, logout, registration, resetPassword, forgotPassword, repairPassword, checkAuth};