import {refreshSession} from "../actions/authorization";
import {getConversations, getDialog} from "../actions/messages";
import {sendMessageToConversation, sendMessageToUser, uploadMessageFile} from "./MessageAPI";
import {getStore} from "./StoreAPI";


class StoreAPIManager {

    constructor(props, context) {
        this.props = props;
        this.context = context;
        this.state = {

        };
    }

    getStoreSafe = (storeId) => {
        return this.props.dispatch(refreshSession()).then(() => {
            return getStore(storeId);
        });
    };





}


export default StoreAPIManager;