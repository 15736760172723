import {mainDictionary} from "../api/DictionaryAPI";

export const loadDict = (dict) => ({
    type: 'LOAD_DICT',
    dict: dict
});

export const startLoadDict = () => {

    return (dispatch) => {
        return mainDictionary().then(
            response => {
                dispatch(loadDict(JSON.parse(response)));
            },
            error => {
                console.log(error);
            }
        );
    };


};