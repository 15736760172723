import React from 'react';
import {connect} from "react-redux";
import {createStore, editStore, uploadStorePhoto} from "../api/StoreAPI";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import FormControl from "@material-ui/core/FormControl/FormControl";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import {Link, Redirect} from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import {loadUser} from "../actions/user";
import {sendNotification} from "../actions/notifications";
import {defaultSquareIcon, filledSquareIcon} from "../assets/asstes";

class CreateStorePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            step: 0,
            progress: 0,
            delivType: '',
            displayProgress: false,
            store: {
                name: '',
                photoMin: '',
                photoMid: '',
                photoMax: '',
                payment: [],
                deliveries: [],
                description: '',
                country: '',
                city: '',
                address: '',
                id: 0
            },
            close: false,
            openSnack: false,
            snackText: '',
            validate: false,
            errorStyle: {
                borderBottom: '2px',
                borderBottomColor: '#E60936',
                borderBottomStyle: 'solid',
                paddingLeft: '20px',
                marginTop: '10px'
            },
            firstStep: false,
            secondStep: false,

        };
        this.initStore();
    }


    uploadPhoto = () => {

        let formData = new FormData();
        formData.append('file', this.refs.uploadPhoto.files[0]);
        uploadStorePhoto(this.state.store.id, formData, this.onProgress).then(
            response => {
                let store = {...this.state.store};
                let newStore = JSON.parse(response);
                store.photoMin = newStore.photoMin;
                store.photoMid = newStore.photoMid;
                store.photoMax = newStore.photoMax;
                this.setState(()=>({store: store}));
                this.refs.mainPhoto.style.display = 'block';
                this.setState(()=>({displayProgress: false}));
            },
            error => {
                console.log(error);
                this.refs.mainPhoto.style.display = 'block';
                this.setState(()=>({displayProgress: false}));
            }
        );

    };

    onProgress = (event) => {
        this.refs.mainPhoto.style.display = 'none';
        this.setState(()=>({displayProgress: true}));
        if (event.lengthComputable) {
            let complete = (event.loaded / event.total * 100 | 0);
            this.setState({progress: complete});
        }
    };

    initStore = () => {
        createStore().then(
            response => {
                let store = JSON.parse(response);
                this.setState(()=>({store, firstStep: (store.name && store.description), secondStep: store.photoMax}));

            },
            error => {
                console.log(error);
            }
        );
    };

    selectPhoto = () => {
        this.refs.uploadPhoto.click();
    };

    getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return (
                    <div>
                        <div className="row" style={{marginTop: '92px'}}>
                            <div className="col-12">
                                <div className="d-flex justify-content-center">
                                <span className='create-store-label'>Назовите свой магазин</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="d-flex justify-content-center" style={{textAlign: "center"}}>
                                    <span className='create-store-sublabel'>Выберите имя, отражающее стиль вашего творчества</span>
                                </div>
                            </div>
                        </div>
                        <div style={{marginTop: '60px'}} className='d-flex justify-content-center'>
                            <input type='text'
                                   className='input-text-element-100-pr'
                                   value={this.state.store.name}
                                   onChange={this.nameOnChange}
                                   placeholder='Название'
                                   style={
                                       (this.state.validate && (this.state.store.name.length < 3 || this.state.store.name.length > 250)) ?
                                       this.state.errorStyle :
                                       {
                                       paddingLeft: '20px',
                                       marginTop: '10px'
                                   }} />

                        </div>
                        <div className="d-flex justify-content-center">
                            <span className='error-message' style={{display: (this.state.validate && (this.state.store.name.length < 3 || this.state.store.name.length > 250)) ? 'block' : 'none'}}>Имя не может быть меньше 3 или больше 250 символов</span>
                        </div>
                        <div style={{marginTop: '30px'}} className='d-flex justify-content-center'>
                            <textarea
                                className='textarea-element-100-pr'
                                value={this.state.store.description}
                                onChange={(e)=>{
                                    let store = this.state.store;
                                    store.description = e.target.value;
                                    this.setState(()=>({store: {...store}}));
                                }}
                                placeholder='Опишите свое творчество'
                                style={(this.state.validate && (this.state.store.description.length < 10 || this.state.store.description.length > 3000)) ?
                                    this.state.errorStyle :
                                    {
                                        paddingLeft: '20px',
                                        marginTop: '10px'
                                    }} />
                        </div>
                        <div className="d-flex justify-content-center">
                            <span className='error-message' style={{display: (this.state.validate && (this.state.store.description.length < 10 || this.state.store.description.length > 3000)) ? 'block' : 'none'}}>Описание не может быть меньше 10 или больше 3000 символов</span>
                        </div>
                        <div style={{marginTop: '60px', marginBottom: '60px'}} className='d-flex justify-content-center'>
                            <button disabled={!this.state.store.name || !this.state.store.description}
                                    style={!this.state.store.name || !this.state.store.description ? {opacity: '0.5'} : {opacity: '1'}}
                                    className='create-btn-max' onClick={this.validateFirstStep}>Продолжить</button>
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div>
                        <div className="row" style={{marginTop: '92px'}}>
                            <div className="col-12">
                                <div className="d-flex justify-content-center">
                                <span className='create-store-label'>Загрузите фотографию</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="d-flex justify-content-center">
                                <span className='create-store-sublabel'>Загрузите фотографию магазина</span>
                                </div>
                            </div>
                        </div>
                        <div style={{marginTop: '30px'}} className='d-flex justify-content-center'>
                            <div style={{
                                width: '200px',
                                display: 'inline-block',
                                marginLeft: '0px',
                                marginTop: '27px'
                            }}>
                                <LinearProgress style={{display: this.state.displayProgress ? 'block' : 'none'}} ref='progress' variant="determinate" value={this.state.progress} />
                                <div ref='mainPhoto' className="user-cabinet-page-photo"
                                     style={{
                                         backgroundImage: 'url("' + this.state.store.photoMin + '")',
                                         backgroundSize: 'cover',
                                         backgroundRepeat: 'no-repeat',
                                         backgroundPositionX: 'center',
                                         backgroundPositionY: 'center',
                                         borderRadius: '5%',
                                         border: '1px solid #CFD6D8'
                                     }}
                                >


                                </div>



                                <div style={{
                                    marginTop: '10px',
                                    marginLeft: '70px'
                                }}>

                                    <input ref='uploadPhoto' type='file' name='file' style={{display: 'none'}} onChange={this.uploadPhoto}/>
                                </div>
                                <div>
                                    <button style={{width: 200}} type='file' className='btn btn-secondary' onClick={this.selectPhoto}>Загрузить</button>

                                </div>
                            </div>
                        </div>
                        <div style={{marginTop: '60px', marginBottom: '60px'}} className='d-flex justify-content-center'>
                            <button className='create-btn-max'
                                    style={!this.state.store.photoMin || !this.state.store.photoMid || !this.state.store.photoMax
                                        ? {opacity: '0.5'}
                                        : {opacity: '1'}}
                                    onClick={this.validateSecondStep}>Продолжить</button>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div>

                        <div className="row" style={{marginTop: '92px'}}>
                            <div className="col-12">
                                <div className="d-flex justify-content-center" style={{textAlign: "center"}}>
                                    <span className='create-store-label'>Настройте оплату и доставку </span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="d-flex justify-content-center" style={{textAlign: "center"}}>
                                <span className='create-store-sublabel'>Выберите удобный для вас тип оплаты и доставки</span>
                                </div>
                            </div>
                        </div>
                        <div style={{marginTop: '60px', marginLeft: '0px'}} >
                            <div className='store-setting-label'>
                                <span className='store-setting-step'>1. Оплата</span>
                            </div>
                            <div style={{width: '100%', lineHeight: '10px'}}>
                                <span className='description-create-goods-title'>Выберите хотя бы один способ оплаты</span>
                            </div>
                            <div style={{marginTop: 20}}>
                                {
                                    this.state.store.payment.map((pay, idx)=> (
                                        <div key={idx}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    icon={<div style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        backgroundImage: 'url(' + defaultSquareIcon +')',
                                                        backgroundSize: 'cover',
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundPositionX: 'center',
                                                        backgroundPositionY: 'center',
                                                    }}>

                                                    </div>}
                                                    checkedIcon={<div style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        backgroundImage: 'url('+ filledSquareIcon +')',
                                                        backgroundSize: 'cover',
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundPositionX: 'center',
                                                        backgroundPositionY: 'center',
                                                    }}>

                                                    </div>}
                                                    value={true}
                                                    checked={pay.isEnable}
                                                    onChange={(e, ch) => {
                                                        let store = {...this.state.store};
                                                        store.payment[idx].isEnable = ch;
                                                        this.setState({store: store});
                                                    }}
                                                />
                                            }
                                            label={<span className='create-store-payment'>{pay.name}</span>}
                                        />
                                        </div>
                                    ))
                                }

                            </div>
                        </div>
                        <div style={{marginTop: '60px', marginLeft: '0px'}} >
                            <div className='store-setting-label'>
                                <span className='store-setting-step'>2. Местонахождение</span>
                            </div>
                            <div style={{marginTop: 20}}>
                                    <input type='text'
                                           className='input-text-element-100-pr'
                                           value={this.state.store.country}
                                           onChange={(e)=>{
                                               let store = this.state.store;
                                               store.country = e.target.value;
                                               this.setState(()=>({store: {...store}}));
                                           }}
                                           placeholder='Страна*'
                                           style={(this.state.validate && !this.state.store.country)
                                               ? this.state.errorStyle
                                               : {
                                               paddingLeft: '20px',
                                               marginTop: '10px'
                                           }} />
                                <div className="d-flex justify-content-center">
                                    <span className='error-message' ref="nameError" style={{display: (this.state.validate && !this.state.store.country) ? 'block' : 'none'}}>Укажите страну</span>
                                </div>
                            </div>
                            <div style={{marginLeft: '0px'}}>
                                    <input type='text'
                                           className='input-text-element-100-pr'
                                           value={this.state.store.city}
                                           onChange={(e)=>{
                                               let store = this.state.store;
                                               store.city = e.target.value;
                                               this.setState(()=>({store: {...store}}));
                                           }}
                                           placeholder='Город*'
                                           style={ (this.state.validate && !this.state.store.city)
                                               ? this.state.errorStyle
                                               : {
                                               paddingLeft: '20px',
                                               marginTop: '10px'
                                           }} />
                                <div className="d-flex justify-content-center">
                                    <span className='error-message' ref="nameError" style={{display: (this.state.validate && !this.state.store.city) ? 'block' : 'none'}}>Укажите город</span>
                                </div>
                            </div>
                        </div>
                        <div style={{marginTop: '0px', marginLeft: '0px'}} >
                            <div className='store-setting-label'>
                                <span className='store-setting-step'></span>
                            </div>
                            <div >
                                    <input type='text'
                                           className='input-text-element-100-pr'
                                           value={this.state.store.address}
                                           onChange={(e)=>{
                                               let store = this.state.store;
                                               store.address = e.target.value;
                                               this.setState(()=>({store: {...store}}));
                                           }}
                                           placeholder='Адрес'
                                           style={{
                                               paddingLeft: '20px',
                                               marginTop: '10px'
                                           }} />
                            </div>

                        </div>
                        <div style={{marginTop: '60px', marginLeft: '0px'}} >
                            <div className='inline store-setting-label'>
                                <span className='store-setting-step'>3. Доставка</span>
                            </div>
                            <div>

                            </div>
                            <div>

                            </div>
                        </div>
                        <div style={{marginTop: '0px', marginLeft: '0px'}} >
                            <div style={{width: '100%', lineHeight: '10px'}}>
                                <span className='description-create-goods-title'>Заполните хотя бы один тип доставки</span>
                            </div>
                            <div style={{marginTop: '10px'}}>
                                <FormControl className='select-type'>
                                    <Select
                                        value={this.state.delivType}
                                        onChange={(e)=>{
                                            this.setState(()=>({delivType: e.target.value}));
                                        }}
                                        displayEmpty
                                        name="age"
                                        variant='outlined'
                                    >
                                        <MenuItem value="" disabled>
                                            Выберите тип доставки
                                        </MenuItem>
                                        {
                                            this.props.dict.delivery.map((deliv)=>(
                                                <MenuItem key={deliv.id} value={deliv.id}>{deliv.name}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                            <div style={{marginLeft: '0px', marginTop: 20}}>
                                <button className='btn-second-mid' disabled={!this.state.delivType} onClick={this.addDelivery}>Добавить</button>
                            </div>
                            <div>

                            </div>
                        </div>
                        {
                            this.state.store.deliveries ? this.state.store.deliveries.map((delivery, idx)=>(
                                <div key={idx} style={{marginTop: '37px', marginLeft: '0px'}} >
                                    <div className='inline store-setting-label'>
                                        <span className='delivery-label'>{this.props.dict.delivery.filter(d => d.id === delivery.deliveryTypeId)[0].name}</span>
                                    </div>
                                    <div >
                                        <input type='text'
                                               className='input-text-element-100-pr'
                                               value={delivery.city}
                                               onChange={(e)=> {
                                                   let store = this.state.store;
                                                   store.deliveries[idx].city = e.target.value;
                                                   this.setState(()=>({store: {...store}}));
                                               }}
                                               placeholder={delivery.deliveryTypeId === 7 ? 'Куда*' : 'Город*'}
                                               style={(this.state.validate && !this.state.store.deliveries[idx].city)
                                                   ? this.state.errorStyle : {
                                                   paddingLeft: '20px',
                                                   marginTop: '10px'
                                               }} />
                                        <div className="d-flex justify-content-center">
                                            <span className='error-message' ref="nameError" style={{display: (this.state.validate && !this.state.store.deliveries[idx].city) ? 'block' : 'none'}}>{delivery.deliveryTypeId === 7 ? 'Укажите куда' : 'Укажите город'}</span>
                                        </div>
                                    </div>
                                    <div  style={{marginLeft: 0, marginTop: 10}}>
                                        <FormControl className='select-type'  error={(this.state.validate && !this.state.store.deliveries[idx].period)}>
                                            <Select
                                                value={delivery.period}
                                                onChange={(e)=>{
                                                    let store = this.state.store;
                                                    store.deliveries[idx].period = e.target.value;
                                                    this.setState(()=>({store: {...store}}));
                                                }}
                                                displayEmpty
                                                name="age"
                                                variant='outlined'
                                            >
                                                <MenuItem value="" disabled>
                                                    Период доставки
                                                </MenuItem>
                                                {
                                                    this.props.dict.delivery_time.map((time)=>(
                                                        <MenuItem key={time.name} value={time.name}>{time.name}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                        <div className="d-flex justify-content-center">
                                            <span className='error-message' ref="nameError" style={{display: (this.state.validate && !this.state.store.deliveries[idx].period) ? 'block' : 'none', marginTop: 7}}>Выберите период доставки</span>
                                        </div>
                                    </div>
                                    <div  style={{marginLeft: '0px', marginTop: 10}}>
                                        <input type='text'
                                               className='input-text-element-100-pr'
                                               value={delivery.cost}
                                            onChange={(e)=>{
                                                let store = this.state.store;
                                                let cost = e.target.value.match(/(\d{1,10}[.]?\d{0,2})/g);
                                                store.deliveries[idx].cost = (cost === null ? '' : cost[0] );
                                                this.setState(()=>({store: {...store}}));
                                            }}
                                               placeholder='Стоимость, BYN'
                                               style={{
                                                   paddingLeft: '20px',
                                                   marginTop: '10px'
                                               }} />
                                        <div className='d-flex flex-row-reverse bd-highlight'
                                             style={{marginTop: '13px'}}
                                        >
                                            <span onClick={()=>{
                                                this.removeDelivery(idx);
                                            }} id='remove-delivery'>Удалить</span><div onClick={()=>{
                                            this.removeDelivery(idx);
                                        }} style={{marginRight: '5px'}} className='remove-icon'></div>
                                        </div>
                                    </div>

                                </div>
                            ))
                                : ''
                        }

                        <div style={{marginTop: '60px', marginBottom: '30px'}}
                             className='d-flex justify-content-center'>
                            <button className='create-btn-max'
                                    onClick={this.validateThirdStep}
                                    disabled={
                                        !this.state.store.payment.filter(p => p.isEnable).length > 0 ||
                                        !this.state.store.deliveries.length > 0 ||
                                        !this.state.store.country ||
                                        !this.state.store.city
                                    }
                                    style={!this.state.store.payment.filter(p => p.isEnable).length > 0 ||
                                    !this.state.store.deliveries.length > 0||
                                    !this.state.store.country ||
                                    !this.state.store.city  ? {opacity: '0.5'} : {opacity: '1'}}
                            >{this.props.user.storeIsFilled ? 'Сохранить' : 'Начать продавать'}</button>
                        </div>

                        <div style={{marginTop: 25, width: '100%', textAlign: "center"}}>
                            <Link to={this.props.user.storeIsFilled ? '/manage/owner' : '/'}>{this.props.user.storeIsFilled ? 'Вернуться в мой магазин' : 'Вернуться на главную'}</Link>
                        </div>

                    </div>
                );
            default:
                return '';
        }
    };

    validateThirdStep = () => {

        let isValid = true;

        this.state.store.deliveries.forEach((deliv)=> {
            if (deliv.city === '') {
                this.setState(()=>({validate: true}));
                isValid = false;
            }
            if (deliv.period === '') {
                this.setState(()=>({validate: true}));
                isValid = false;
            }
        });
        let store = this.state.store;
        store.country = store.country.trim();
        store.city = store.city.trim();
        this.setState({store: {...store}});
        if (!this.state.store.country || !this.state.store.city) {
            isValid = false;
            this.setState(()=>({validate: true}));
        }

        if (!isValid)
            return;

        editStore(this.state.store).then(
            response => {
                this.props.dispatch(loadUser()).then(() => {
                    this.setState(()=>({close: true}));
                    this.props.dispatch(sendNotification({
                        open: true,
                        messageInfo: {
                            message: 'Данные сохранены',
                            key: new Date().getTime(),
                            variant: 'success'
                        }
                    }));
                });
            },
            error => {
                let err = JSON.parse(error.message);
                if (err.code === 66) {
                    this.setState(()=>({snackText: 'Не заполнен город доставки', openSnack: true}))
                    this.props.dispatch(sendNotification({
                        open: true,
                        messageInfo: {
                            message: 'Не заполнен город доставки',
                            key: new Date().getTime(),
                            variant: 'error'
                        }
                    }));
                }else{
                    this.props.dispatch(sendNotification({
                        open: true,
                        messageInfo: {
                            message: 'Ошибка при сохранении данных',
                            key: new Date().getTime(),
                            variant: 'error'
                        }
                    }));
                }
            }
        );
    };

    addDelivery = () => {
        if (!this.state.delivType) {
            return;
        }
        let store = this.state.store;
        store.deliveries.push({
            deliveryTypeId: this.state.delivType,
            city: '',
            period: '',
            cost: ''
        });
        this.setState(()=>({store: {...store}, delivType: ''}), () => {
            if(window){
                window.scrollTo(0, (window.document.documentElement.scrollHeight - 900));
            }
        });
    };

    removeDelivery = (idx) => {
        let store = this.state.store;
        store.deliveries.splice(idx, 1);
        this.setState(()=>({store}));
    };

    setStep1 = () => {
      this.setState(()=>({step: 0}));
    };
    setStep2 = () => {
        if (!this.state.firstStep) {
            return;
        }
      this.setState(()=>({step: 1}));
    };
    setStep3 = () => {
        if (!this.state.secondStep || !this.state.firstStep) {
            return;
        }
      this.setState(()=>({step: 2}));
    };

    validateFirstStep = () => {
        let store = this.state.store;
        store.name = store.name.trim();
        store.description = store.description.trim();

        if (store.name.length < 3 || store.name.length > 250) {
            this.setState(()=>({validate: true}));
            return;
        }
        if (store.description.length < 10 || store.description.length > 3000) {
            this.setState(()=>({validate: true}));
            return;
        }

        this.setState(()=>({validate: false}));


        let step = store.name && store.description ? 1 : 0;
        this.setState(()=>({firstStep: true, step: step, store: {...store}}));
        if (window){
            window.scrollTo(0, 0);
        }
    };

    validateSecondStep = () => {

        let step = this.state.store.photoMin && this.state.store.photoMid && this.state.store.photoMax ? 2 : 1;

        this.setState(()=>({secondStep: true, step: step}));
        if (window){
            window.scrollTo(0, 0);
        }
    };

    componentDidMount() {
        if (window){
            window.scrollTo(0, 0);
        }
    }


    nameOnChange = (e) => {
        e.preventDefault();

        let name = e.target.value;

        this.setState(()=>({store: {...this.state.store, name: name}}));
    };

    handleNext = () => {
        this.setState(state => ({
            activeStep: state.activeStep + 1,
        }));
    };

    handleBack = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1,
        }));
    };

    handleReset = () => {
        this.setState({
            activeStep: 0,
        });
    };

    handleCloseSnack = () => {
        this.setState(()=>({openSnack: false}));
    };

    onClose = () => {
        this.props.history.goBack();
        // this.setState(()=>({close: true}));
    };

    render() {
        return (
            <div >
                {
                    this.state.close ? <Redirect to={this.props.user.storeIsFilled ? '/manage/owner' : ''} /> : ''
                }
                <div className="row" style={{marginTop: '15px'}}>
                    <div className="col-12">
                        <div className="d-flex justify-content-center">
                            <div >
                                <div onClick={this.setStep1} className={this.state.step > 0 ? 'circle-step-filled' : 'circle-step-active'}><span className='step-number' style={this.state.step > 0 ? {color: '#FFFFFF'} : {color: '#E60936'}}>1</span></div>
                                <div className='step-text-active'><span style={{marginLeft: '0px'}}>Назовите магазин</span></div>
                            </div>

                            <div id='step2' className={this.state.step > 0 ? 'line-step-active' : 'line-step'}></div>
                            <div >
                                <div onClick={this.setStep2}
                                     className={this.state.step === 1
                                         ? 'circle-step-active'
                                         : this.state.step > 1
                                             ? 'circle-step-filled'
                                             : 'circle-step'}
                                ><span className='step-number' style={this.state.step === 0
                                    ? {color: '#CFD6D8'}
                                    : this.state.step === 1
                                        ? {color: '#E60936'}
                                        : {color: '#FFFFFF'}}
                                >2</span></div>
                                <div className={this.state.step > 0 ? 'step-text-active' : 'step-text'}><span style={{marginLeft: '-38px'}}>Загрузите фотографию</span></div>
                            </div>
                            <div id='step3' className='line-step'></div>
                            <div >
                                <div onClick={this.setStep3}
                                     className={this.state.step === 2
                                    ? 'circle-step-active'
                                    : 'circle-step'}
                                ><span className='step-number'
                                       style={this.state.step < 2
                                           ? {color: '#CFD6D8'}
                                           : {color: '#E60936'}
                                       }
                                >3</span></div>
                                <div className={this.state.step === 2 ? 'step-text-active' : 'step-text'}>
                                    <span style={{marginLeft: '-40px'}}>Настройте оплату</span>
                                    <br/>
                                    <span style={{marginLeft: '-40px'}}>и доставку</span>
                                </div>
                            </div>

                        </div>
                        {this.getStepContent(this.state.step)}



                    </div>
                </div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={this.state.openSnack}
                    // autoHideDuration={6000}
                    onClose={this.handleCloseSnack}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.state.snackText}</span>}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            // className={classes.close}
                            onClick={this.handleCloseSnack}
                        >
                            <CloseIcon />
                        </IconButton>,
                    ]}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        dict: state.dictionary.dict,
        user: state.user,
        settings: state.settings,
        basket: state.basket,
        messages: state.messages
    };
};

export default connect(mapStateToProps)(CreateStorePage);