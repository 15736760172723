import url from "../settings/Settings";
import cookie from "react-cookies";


const saveClientId = (push = {clientId: null}) => {
    return new Promise(function(resolve, reject) {

        const xhr = new XMLHttpRequest();
        xhr.open('POST', url + '/v1.0/push/browser/user', true);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
        xhr.setRequestHeader('web', cookie.load('_web'));

        let session = JSON.parse(localStorage.getItem("session"));
        if (session)
            xhr.setRequestHeader('Authorization', 'token ' + session.token);
        xhr.onload = function() {
            if (this.status === 200) {
                resolve(this.response);
            } else {
                let error = new Error(this.responseText);
                error.code = this.status;
                reject(error);
            }
        };

        xhr.onerror = function() {
            reject(new Error("Network Error"));
        };

        xhr.send(JSON.stringify(push));
    });
};

const deleteClientId = (push = {clientId: null}) => {
    return new Promise(function(resolve, reject) {

        const xhr = new XMLHttpRequest();
        xhr.open('DELETE', url + '/v1.0/push/browser/user', true);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
        xhr.setRequestHeader('web', cookie.load('_web'));

        let session = JSON.parse(localStorage.getItem("session"));
        if (session)
            xhr.setRequestHeader('Authorization', 'token ' + session.token);
        xhr.onload = function() {
            if (this.status === 200) {
                resolve(this.response);
            } else {
                let error = new Error(this.responseText);
                error.code = this.status;
                reject(error);
            }
        };

        xhr.onerror = function() {
            reject(new Error("Network Error"));
        };

        xhr.send(JSON.stringify(push));
    });
};

const saveTopicSubscription = (push = {clientId: null, topic: null}) => {
    return new Promise(function(resolve, reject) {

        const xhr = new XMLHttpRequest();
        xhr.open('POST', url + '/v1.0/push/browser/topic', true);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
        xhr.setRequestHeader('web', cookie.load('_web'));

        let session = JSON.parse(localStorage.getItem("session"));
        if (session)
            xhr.setRequestHeader('Authorization', 'token ' + session.token);
        xhr.onload = function() {
            if (this.status === 200) {
                resolve(this.response);
            } else {
                let error = new Error(this.responseText);
                error.code = this.status;
                reject(error);
            }
        };

        xhr.onerror = function() {
            reject(new Error("Network Error"));
        };

        xhr.send(JSON.stringify(push));
    });
};


const adminGoodsSubscription = () => {
    return new Promise(function(resolve, reject) {

        const xhr = new XMLHttpRequest();
        xhr.open('POST', url + '/v1.0/push/browser/admin/goods', true);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
        xhr.setRequestHeader('web', cookie.load('_web'));

        let session = JSON.parse(localStorage.getItem("session"));
        if (session)
            xhr.setRequestHeader('Authorization', 'token ' + session.token);
        xhr.onload = function() {
            if (this.status === 200) {
                resolve(this.response);
            } else {
                let error = new Error(this.responseText);
                error.code = this.status;
                reject(error);
            }
        };

        xhr.onerror = function() {
            reject(new Error("Network Error"));
        };

        xhr.send();
    });
};



const adminOrderSubscription = () => {
    return new Promise(function(resolve, reject) {

        const xhr = new XMLHttpRequest();
        xhr.open('POST', url + '/v1.0/push/browser/admin/order', true);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
        xhr.setRequestHeader('web', cookie.load('_web'));

        let session = JSON.parse(localStorage.getItem("session"));
        if (session)
            xhr.setRequestHeader('Authorization', 'token ' + session.token);
        xhr.onload = function() {
            if (this.status === 200) {
                resolve(this.response);
            } else {
                let error = new Error(this.responseText);
                error.code = this.status;
                reject(error);
            }
        };

        xhr.onerror = function() {
            reject(new Error("Network Error"));
        };

        xhr.send();
    });
};


export {saveClientId, deleteClientId, saveTopicSubscription, adminGoodsSubscription, adminOrderSubscription};