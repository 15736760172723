// Expenses Reducer

const categoryReducerDefaultState = [];

const category = (state = categoryReducerDefaultState, action) => {
  switch (action.type) {
    case 'GET_CATEGORY':
      return [
        ...state,
        action.category
      ];
    default:
      return state;
  }
};
export default category;