import React from 'react';
import {Link} from "react-router-dom";
import {sentIcon, waitingIcon} from "../assets/asstes";



const OrderPhotoComponent = (props) => {


    return (
        <div className="order-goods-image col"
             style={ props.order.item ? {
                 backgroundImage: 'url("' + (props.order.item.photos.filter((ph) => {
                     return ph.orderPlace === 1
                 }).length > 0 ? props.order.item.photos.filter((ph) => {
                     return ph.orderPlace === 1
                 })[0].photoMin : '') + '")',
                 backgroundSize: 'cover',
                 backgroundRepeat: 'no-repeat',
                 backgroundPositionX: 'center',
                 backgroundPositionY: 'center',
             } : {
                 backgroundColor: '#F5F7F7'
             }}
        >
            {
                props.order.item ?
                    props.order.status === 1
                        ? <img alt=''
                               src={waitingIcon}
                               className="status-icon status-icon-margin"/>
                        : props.order.status === 4 ?
                        <img alt=''
                             src={sentIcon}
                             className="status-icon status-icon-margin"/>
                        : ''
                    : <div className='basket-remove-button user-cabinet-goods-removed'>товар удален</div>
            }
        </div>
    );
};


const OrderPhotoLinkComponent = (props) => {

    return (
        <Link to={'/product/' + (props.order.item ? props.order.item.id : 0)} disabled={!props.order.item}>
            <OrderPhotoComponent order={props.order}/>
        </Link>
    );

};


export {OrderPhotoComponent, OrderPhotoLinkComponent};


