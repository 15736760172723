const localStorageValuesState = {
    pushClient: null,
    goodsPush: false,
    orderPush: false
};

const localStorageValues = (state = localStorageValuesState, action) => {
    switch (action.type) {
        case 'LOAD_ALL':
            return {
                ...state,
                ...action.values
            };
        case 'DELETE_USER':
            return {
                ...state,
                ...localStorageValuesState
            };
        case 'SAVE_VALUE':
            return {
                ...state,
                ...action.values
            };
        case 'DELETE_PUSH':
            return {
                ...state,
                ...action.values
            };
        default:
            return state;
    }
};

export default localStorageValues;