import React from 'react';
import {connect} from "react-redux";
import {addDiscount, deleteDiscount, deleteStore, getStore} from "../api/StoreAPI";
import moment from 'moment';
import {deleteGoods, getMyFilteredGoods} from "../api/GoodsAPI";
import {Link, Redirect} from "react-router-dom";
import Slide from "@material-ui/core/Slide/Slide";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";
import {loadUser} from "../actions/user";
import Tabs from '@material-ui/core/Tabs';
import Tab from "@material-ui/core/Tab";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/Typography";
import {changeStatus, getStoreOrderv2} from "../api/OrderAPI";
import {sendNotification} from "../actions/notifications";
import {createNew, getDialog, promSetUserPage, setDialogId, setIsDialog, setOpenStatus} from "../actions/messages";
import Carousel, {Modal, ModalGateway} from "react-images";
import LoadingPage from "./LoadingPage";
import {getConversationExist} from "../api/MessageAPI";
import {OrderPhotoComponent, OrderPhotoLinkComponent} from "./OrderPhotoComponent";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {Helmet} from "react-helmet";
import * as VanillaSharing from 'vanilla-sharing';
import {logSocial} from "../api/LogAPI";
import {
    arrowDownIcon,
    arrowUpIcon,
    fbShareIcon,
    okShareIcon,
    pinterestShareIcon,
    telegramShareIcon, viberShareIcon,
    vkShareIcon,
    whatsappShareIcon
} from "../assets/asstes";
import {Dropdown} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import ru from 'date-fns/locale/ru';
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';


registerLocale('ru', ru);


function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3, paddingLeft: 0, paddingRight: 0 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    tabsRoot: {
        borderBottom: '1px solid #e8e8e8',
        width: '100%'
    },
    tabsIndicator: {
        backgroundColor: '#E60936',
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(4),
        fontSize: '15px',
        lineHeight: '30px',
        fontFamily: [
            'Tanos'
        ].join(','),
        '&:hover': {
            color: '#40a9ff',
            opacity: 1,
        },
        '&$tabSelected': {
            color: '#283149',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: '#283149',
            outline: 0
        },
    },
    tabSelected: {},
    typography: {
        padding: theme.spacing(3),
    },
});


const DateCustomInput = ({ value, onClick }) => (
    <div>
        {/*<input type='text'*/}
        {/*       disabled={true}*/}
        {/*       className='input-text-element-145'*/}
        {/*       value={value}*/}
        {/*       style={{*/}
        {/*           paddingLeft: '20px',*/}
        {/*           marginTop: '10px'*/}
        {/*       }}/>*/}

        <input type='text'
               disabled={true}
               value={value}

        />
        <button className="card-goods-action-btn" style={{paddingLeft: 20, color: '#007bff'}} onClick={onClick}>
            Выбрать дату
        </button>

    </div>

);


class OwnerStorePage extends React.Component {

    constructor(props) {
        super(props);
        moment.locale('ru');
        this.state = {
            storeId: this.props.user.storeId,
            store: {
                name: '',
                owner: {
                    name: '',
                    photoMid: ''
                },
                createdDate: moment(),
                city: '',
                deliveries: []
            },
            filter: {
                page: 0,
                size: 1000,
                storeId: this.props.user.storeId
            },
            filterOrder: {
                page: 0,
                size: 50,
                sort: 'createdDate,desc'
            },
            products: {},
            deleteInProgress: false,
            deleteOpen: false,
            goodsDeleteId: 0,
            deleteStoreInProgress: false,
            deleteStoreOpen: false,
            redirect: false,
            value: 0,
            orders: null,
            orderIdToChange: 0,
            orderChangeDialog: false,
            statusText: '',
            statusPlaceholder: '',
            sendingInProgress: false,
            statusAlias: '',
            commentSendText: '',
            anchorEl: null,
            orderStatusMenu: 0,
            orderId: 0,
            userId: 0,
            rejectIsOpen: false,
            messageText: '',
            photoIsOpen: false,
            deleteStoreDiscountOpen: false,
            deleteStoreDiscountInProgress: false,
            createStoreDiscountOpen: false,
            createStoreDiscountInProgress: false,
            discount: 0,
            startDiscount: null,
            endDiscount: null,
            dropdownIsOpen: false
        };
    }

    handleChange = (event, value) => {
        this.setState({ value });
    };

    dialogOnOpen = (goodsId) => {
        this.setState(() => ({deleteOpen: true, goodsDeleteId: goodsId}))
    };

    dialogOnClose = () => {
        this.setState(() => ({goodsDeleteId: 0, deleteOpen: false, rejectIsOpen: false, messageText: ''}));
    };

    dialogStoreOnOpen = () => {
        this.setState(() => ({deleteStoreOpen: true}))
    };

    dialogStoreOnClose = () => {
        this.setState(() => ({deleteStoreOpen: false}));
    };

    dialogStoreDiscountOnClose = () => {
        this.setState({deleteStoreDiscountOpen: false});
    };

    dialogStoreAddDiscountOnClose = () => {
        this.setState({createStoreDiscountOpen: false});
    };

    onDeleteGoods = () => {
        this.setState(() => ({deleteInProgress: true}));
        deleteGoods(this.state.goodsDeleteId).then(
            response => {
                let products = {...this.state.products};
                products.content = products.content.filter(p => p.id !== this.state.goodsDeleteId);
                this.setState(()=>({products: products, deleteInProgress: false}));
                this.dialogOnClose();
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Товар удален',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));

            },
            error => {
                this.setState(() => ({deleteInProgress: false}));
                this.dialogOnClose();
                console.log(error);
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Ошибка при удалении товара',
                        key: new Date().getTime(),
                        variant: 'error'
                    }
                }));
            }
        );
    };

    onDeleteStore = () => {
        this.setState(() => ({deleteStoreInProgress: true}));
        deleteStore(this.props.user.storeId).then(
            response => {
                this.dialogStoreOnClose();
                this.props.dispatch(loadUser()).then(() => {
                    this.setState({redirect: true})
                });
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Магазин удален',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));

            },
            error => {
                this.setState(() => ({deleteStoreInProgress: false}));
                this.dialogStoreOnClose();
                console.log(error);
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Ошибка при удалении магазина',
                        key: new Date().getTime(),
                        variant: 'error'
                    }
                }));
            }
        );
    };

    Transition = (props) => {
        return <Slide direction="up" {...props} />;
    };

    componentDidMount() {
        if (typeof window !== 'undefined'){
            window.scrollTo(0, 0);
        }
    }

    componentWillMount() {
        this.loadStore();
        this.loadProduct();
        this.loadStoreOrders();
    }

    getOrderStatus = (status) => {
        switch (status) {
            case 1:
                return 'Новый заказ';
            case 2:
                return 'В процессе';
            case 3:
                return 'Отменен';
            case 4:
                return 'Выполнен';
            case 5:
                return 'Доставлено';
        }
    };

    loadProduct() {
        getMyFilteredGoods(this.state.filter).then(
            response => {
                this.setState(() => ({products: JSON.parse(response)}));
            },
            error => {
                console.log(error);
            }
        );
    };

    loadStoreOrders = () => {
        getStoreOrderv2(this.state.filterOrder).then(
            response => {
                let orders = JSON.parse(response);
                this.setState({orders});
            },
            error => {
                this.setState({redirect: true});
                console.log(error.message);
            }
        );
    };

    loadStore = () => {
        getStore(this.props.user.storeId).then(
            response => {
                this.setState(() => ({
                    store: JSON.parse(response)
                }));
            },
            error => {
                console.log(error);
            }
        );
    };

    sendOnClose = () => {
        this.setState({orderIdToChange: 0, orderChangeDialog: false, sendingInProgress: false});
    };

    sendOnOpen = (orderId, status = 2) => {
        let statusText = '';
        let statusPlaceholder = '';
        switch (status) {
            case 2:
                statusText = 'Подтвердить';
                statusPlaceholder = 'Напишите ответ';
                break;
            case 3:
                statusText = 'Отказать';
                statusPlaceholder = 'Напишите причину отказа';
                break;
            case 4:
                statusText = 'Выполнено';
                statusPlaceholder = '';
                break;
        }
        this.setState({orderIdToChange: orderId, orderChangeDialog: true, statusText: statusText, statusPlaceholder: statusPlaceholder, statusAlias: status});
    };

    sendStatusToChange = () => {
        changeStatus({orderId: this.state.orderIdToChange, status: this.state.statusAlias, response: this.state.commentSendText}).then(
            response => {
                this.loadStoreOrders();
                this.setState({orderIdToChange: 0, orderChangeDialog: false, statusText: '', statusPlaceholder: '', statusAlias: '', sendingInProgress: false, commentSendText: ''});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Статус товара изменен',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));
            },
            error => {
                console.log(error.message);
                this.setState({orderIdToChange: 0, orderChangeDialog: false, statusText: '', statusPlaceholder: '', statusAlias: '', sendingInProgress: false});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Ошибка при изменении статуса заказа',
                        key: new Date().getTime(),
                        variant: 'error'
                    }
                }));
            }
        );
    };

    onChangeComment = (ev) => {
        this.setState({commentSendText: ev.target.value});
    };

    handleCloseMenu = () => {
        // this.setState({anchorEl: null, orderStatusMenu: 0, orderId: 0}, () => {
        //     document.removeEventListener('click', this.handleCloseMenu);
        // });
    };

    handleClickMenu = (event, orderId, orderStatus) => {
        this.setState({anchorEl: event.currentTarget, orderId: orderId, orderStatusMenu: orderStatus});

    };

    toggleDrawer = (open) => {
        if (!!this.props.messages.userPage) {
            getConversationExist(this.props.messages.userPage.id).then(
                response => {
                    let exist = JSON.parse(response);
                    if (exist.exist) {
                        this.props.dispatch(getDialog({id: exist.conversationId, size: 20})).then(() => {
                            this.props.dispatch(setDialogId(exist.conversationId));
                            this.props.dispatch(setIsDialog(true));
                            this.props.dispatch(setOpenStatus(open));
                            this.setState({
                                messengerOpen: open,
                            });
                        });

                    } else {
                        this.props.dispatch(createNew(true));
                        this.props.dispatch(setIsDialog(true));
                        this.props.dispatch(setOpenStatus(open));

                    }
                },
                error => {
                    console.log(error);
                }
            );
        } else {
            this.props.dispatch(setOpenStatus(open));

        }


    };

    toggleDrawerClick = () => {
        this.toggleDrawer(!this.props.messages.isOpen);

    };

    sendMessage = () => {
        let order = this.state.orders.content.filter(o => o.orderId === this.state.orderId)[0];

        this.props.dispatch(promSetUserPage(order.user)).then(() => {
                this.toggleDrawerClick();
            }
        );
        this.setState({orderId: 0});

    };

    // sendMessage = (orderId) => {
    //     let order = this.state.orders.content.filter(o => o.orderId === orderId)[0];
    //
    //     this.props.dispatch(promSetUserPage(order.user)).then(() => {
    //             document.getElementById('messengerBt').click()
    //         }
    //     );
    //     this.setState({orderId: 0});
    // };

    CustomView = (innerProps) => {
        return (
            <div className="product-image-lg"
                 onClick={() => {
                     this.toggleViewer(innerProps.currentIndex);
                 }}
                 style={{
                     backgroundImage: 'url("'
                         + innerProps.data + '")',
                     backgroundSize: 'contain',
                     backgroundRepeat: 'no-repeat',
                     backgroundPosition: 'center'
                 }}>
            </div>
        )
    };

    FooterCount = (props) => {
        const activeView = props.currentIndex + 1;
        const totalViews = props.views.length;
        return (
            <div>
                <span>{activeView} из {totalViews}</span>
            </div>
        );
    };

    toggleViewer = () => {
        this.setState(() => ({photoIsOpen: !this.state.photoIsOpen}));
    };

    logSocialShared = (type) => {
        const userId = this.props.user.name ? this.props.user.id : null;
        logSocial({page: 'https://crafty.by/store/' + this.state.store.id, userId: userId, type: type});
    };


    sharePinterest= () => {
        VanillaSharing.pinterest({
            url: ('https://crafty.by/store/' + this.props.user.storeId),
            description: (this.state.store.name + '. ' + this.state.store.description),
            media: this.state.store.photoMax,
        });
        this.logSocialShared('PINTEREST');
    };

    shareFacebook = () => {
        VanillaSharing.fbButton({
            url: ('https://crafty.by/store/' + this.state.store.id)
        });
        this.logSocialShared('FACEBOOK');
    };


    shareVk = () => {
        VanillaSharing.vk({
            url: ('https://crafty.by/store/' + this.state.store.id),
            title: this.state.store.name,
            description: this.state.store.description,
            image: this.state.store.photoMax,
            isVkParse: true,
        });
        this.logSocialShared('VK');
    };

    shareOk = () => {
        VanillaSharing.ok({
            url: ('https://crafty.by/store/' + this.state.store.id),
            title: this.state.store.name,
            image: this.state.store.photoMax,
        });
        this.logSocialShared('OK');
    };

    shareViber = () => {
        VanillaSharing.viber({
            url: ('https://crafty.by/store/' + this.state.store.id),
            title: this.state.store.name,
        });
        this.logSocialShared('VIBER');
    };


    shareTelegram = () => {
        VanillaSharing.telegram({
            url: ('https://crafty.by/store/' + this.state.store.id),
            title: this.state.store.name,
        });
        this.logSocialShared('TELEGRAM');
    };

    shareWhatsApp = () => {
        VanillaSharing.whatsapp({
            url: ('https://crafty.by/store/' + this.state.store.id),
            title: this.state.store.name,
        });
        this.logSocialShared('WHATSAPP');
    };

    onDeleteStoreDiscount = () => {
        this.setState({deleteStoreDiscountOpen: true});
    };

    onCreateStoreDiscount = () => {
        this.setState({createStoreDiscountOpen: true});
    };

    deleteStoreDiscount = () => {
        this.setState({deleteStoreDiscountInProgress: true});
        deleteDiscount().then(
            response => {
                this.setState({store: JSON.parse(response), deleteStoreDiscountInProgress: false, deleteStoreDiscountOpen: false});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Скидка удалена',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));
            },
            error => {
                this.setState({deleteStoreDiscountInProgress: false, deleteStoreDiscountOpen: false});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Ошибка при удалении скидки',
                        key: new Date().getTime(),
                        variant: 'error'
                    }
                }));
                console.log(error);
        }
        );
    };

    discountOnChange = (e) => {
        let discount = e.target.value.match(/(\d{1,2}$)/g);
        this.setState(() => ({discount: discount ? discount[0] : discount}));
    };

    createStoreDiscount = () => {
        this.setState({createStoreDiscountInProgress: true});
        addDiscount({
            discount: this.state.discount,
            startDiscount: moment(this.state.startDiscount).format("yyyy-MM-DD"),
            endDiscount: moment(this.state.endDiscount).format("yyyy-MM-DD")
        }).then(
            response => {
                this.setState({store: JSON.parse(response), createStoreDiscountOpen: false, createStoreDiscountInProgress: false});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Скидки на все товары добавлена',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));
            },
            error => {
                let err = JSON.parse(error.message);
                this.setState({createStoreDiscountInProgress: false, createStoreDiscountOpen: false});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: ((err && err.message) ? err.message : 'Ошибка при добавлении скидки'),
                        key: new Date().getTime(),
                        variant: 'error'
                    }
                }));
            }
        ).catch(
            error => {
                this.setState({createStoreDiscountInProgress: false, createStoreDiscountOpen: false});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: ('Ошибка при добавлении скидки'),
                        key: new Date().getTime(),
                        variant: 'error'
                    }
                }));
            }
        );
    }

    onStartDiscountChanged = (date) => {
        this.setState({startDiscount: date});
    };
    onEndDiscountChanged = (date) => {
        this.setState({endDiscount: date});
    };


    render() {
        const { classes } = this.props;
        const { value } = this.state;
        return (
            <div >
                {this.state.redirect ? <Redirect to={'/'}/> : ''}
                {
                    this.state.store.photoMid ?
                        <div>
                            <Helmet>
                                <title>{this.state.store.name}</title>
                            </Helmet>
                            <div className="store-box">
                                <div>
                                    {
                                        this.state.store.photoMid ?
                                            <div  className="product-image-lg">
                                                <Carousel styles={{width: '24rem'}} views={[this.state.store.photoMid,]} components={{ View: this.CustomView, FooterCount: this.FooterCount }}/>
                                            </div>
                                            : ''
                                    }


                                </div>
                                <div style={{wordBreak: 'break-word', marginTop: 15}}>
                                    <div className="store-page-name">
                                        <span>{this.state.store.name}</span>
                                    </div>
                                    <div style={{marginTop: '18px', position: "relative"}} className='user-cabinet-link'>
                                        <div style={{paddingTop: 2}}>
                                            <Link to={'/manage/store'}  >Редактировать</Link>
                                        </div>
                                        <div className='d-flex justify-content-center' style={{
                                            position: "absolute",
                                            top: 0,
                                            right: 0,
                                        }}>

                                            <button className='basket-remove-button' onClick={this.dialogStoreOnOpen}>Удалить</button>
                                        </div>
                                    </div>

                                    <div style={{marginTop: '18px'}} className="main-card-store-pin">
                                        <div className="main-card-store-pin-icon"></div>
                                        <span
                                            className="main-card-store-location">{this.state.store.country}, {this.state.store.city} {this.state.store.address ? (', ' + this.state.store.address) : ''} </span>
                                        <br/>
                                        <span className="main-card-store-location">{moment(this.state.store.createdDate).fromNow(true)} на Crafty</span>
                                    </div>

                                </div>
                                <div>
                                    <div style={{marginTop: 20}} className="store-header-product">
                                        <span>Ссылка на мой магазин</span>
                                    </div>
                                    <div style={{marginTop: 0}}>
                                        <a
                                            style={{
                                                fontSize: 12,
                                                fontFamily: "Open Sans"
                                            }}
                                            target='_blank' href={'https://crafty.by/store/'+this.state.store.id}>{'https://crafty.by/store/'+this.state.store.id}</a>

                                        <CopyToClipboard text={'https://crafty.by/store/'+this.state.store.id}
                                                         onCopy={() => {
                                                             if (this.state.copied){
                                                                 this.setState({copied: false}, () => {
                                                                     this.setState({copied: true});
                                                                     setTimeout(()=> {
                                                                         this.setState({copied: false});
                                                                     }, 3000);

                                                                 });

                                                             } else {
                                                                 this.setState({copied: true});
                                                                 setTimeout(()=> {
                                                                     this.setState({copied: false});
                                                                 }, 3000);
                                                             }
                                                         }}>
                                            <button
                                                style={{
                                                    color: '#404b69',
                                                    fontFamily: "Open Sans",
                                                    background: '#FFFFFF',
                                                    outline: 'none',
                                                    borderRadius: '5px',
                                                    border: 'solid 0px #404b69',
                                                    marginLeft: 10,
                                                    fontSize: 12,
                                                    height: 50,
                                                    paddingLeft: 20,
                                                    paddingRight: 20
                                                }}>Копировать</button>
                                        </CopyToClipboard>
                                        {
                                            this.state.copied ?
                                                <span style={{marginLeft: 10}} className='order-info-label'>Скопировано</span>
                                                : ''
                                        }

                                    </div>
                                    <div className='share-social d-flex justify-content-start'>
                                        <button className='button-social' style={{backgroundColor: '#FFFFFF', border: "none", outline: "none", paddingLeft: 0}} onClick={this.sharePinterest}>
                                            <img style={{width: 30, height: 30}} src={pinterestShareIcon} alt="Поделиться в Pinterest"/>
                                        </button>
                                        <button className='button-social'  style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareFacebook}>
                                            <img style={{width: 30, height: 30}} src={fbShareIcon} alt="Поделиться в Facebook"/>
                                        </button>
                                        <button className='button-social'  style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareVk}>
                                            <img style={{width: 30, height: 30}} src={vkShareIcon} alt="Поделиться во Вконтакте"/>
                                        </button>
                                        <button className='button-social'  style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareOk}>
                                            <img style={{width: 30, height: 30}} src={okShareIcon} alt="Поделиться в Одноклассниках"/>
                                        </button>
                                        <button  className='button-social' style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareTelegram}>
                                            <img style={{width: 30, height: 30}} src={telegramShareIcon} alt="Поделиться в Telegram"/>
                                        </button>
                                        <button className='button-social'  style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareWhatsApp}>
                                            <img style={{width: 30, height: 30}} src={whatsappShareIcon} alt="Поделиться в WhatsApp"/>
                                        </button>
                                        <button className='button-social'  style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareViber}>
                                            <img style={{width: 30, height: 30}} src={viberShareIcon} alt="Поделиться в Viber"/>
                                        </button>

                                    </div>

                                </div>
                                <div>
                                    <div style={{marginTop: 20}} className="store-header-product">
                                        <span>Способы доставки</span>
                                    </div>
                                    <div className='store-owner-delivery' style={{overflowX: "auto", whiteSpace: 'nowrap'}}>

                                        {
                                            this.state.store.deliveries.map((deliv, idx) => {
                                                return (
                                                    <div  key={idx} style={{marginTop: 5}}>
                                                        <span className='product-delivery-type-line'>{deliv.deliveryType.name} :</span><span className='product-delivery-line'> {deliv.city}.
                                                    Срок: {deliv.period}.
                                                    Стоимость: {deliv.cost ? (deliv.cost + ' ' + deliv.currency) : 'Бесплатно'} </span>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>

                                </div>
                                {/*<div style={{*/}
                                {/*    height: '1px',*/}
                                {/*    width: '100%',*/}
                                {/*    borderRadius: '1px',*/}
                                {/*    backgroundColor: '#CFD6D8',*/}
                                {/*    marginTop: '52px',*/}
                                {/*    marginLeft: '0px',*/}
                                {/*    display: 'block'*/}
                                {/*}}>*/}

                                {/*</div>*/}

                            </div>

                            <div style={{marginLeft: '0px', marginTop: '33px'}}>
                                <div className="store-header-product">
                                    <span>Описание</span>
                                </div>
                                <div style={{marginTop: '20px'}}>
                                    <span className="user-page-description-text">{this.state.store.description}</span>
                                </div>
                            </div>

                            <div style={{marginLeft: '0px', marginTop: '33px'}}>
                                <div className="store-header-product">
                                    <span>Скидка на все товары</span>
                                </div>
                                <div style={{marginTop: '20px'}}>
                                    {
                                        this.state.store.discount ?
                                            <div>
                                                <span className='user-page-description-text'>Скидка на все товары составляет </span><span className='user-page-description-text-b'>{this.state.store.storeDiscount.discount}%</span>
                                                <br/>
                                                <span className={'user-page-description-text'}>с </span>
                                                <span className='user-page-description-text-b'>{moment(this.state.store.storeDiscount.startDiscount).format("DD.MM.yyyy")}</span>
                                                <span> по </span>
                                                <span className='user-page-description-text-b'>{moment(this.state.store.storeDiscount.endDiscount).format("DD.MM.yyyy")}</span>
                                            </div>
                                            :
                                            <span className='empty-section-text'>Вы не назначили скидок</span>
                                    }
                                </div>
                                <div>
                                    {
                                        this.state.store.discount ?
                                            <div>
                                                <button onClick={this.onDeleteStoreDiscount} className="card-goods-action-btn" style={{paddingLeft: 0, color: 'rgb(230, 9, 54)'}}>Удалить скидку</button>
                                            </div>
                                            :
                                            <div>
                                                <button onClick={this.onCreateStoreDiscount} className="card-goods-action-btn" style={{paddingLeft: 0, color: '#007bff'}}>Сделать скидку</button>
                                            </div>
                                    }
                                </div>



                            </div>

                            <div style={{marginLeft: '0px', marginTop: '33px'}}>

                                <Tabs
                                    value={value}
                                    onChange={this.handleChange}
                                    classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
                                >
                                    <Tab
                                        disableRipple
                                        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                                        label="Мои товары"
                                    />
                                    <Tab
                                        disableRipple
                                        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                                        label="Мои заказы"
                                    />
                                </Tabs>

                                {value === 0 && <TabContainer>
                                    <div className="store-header-product">
                                        <span>Товары</span>
                                        <div className='inline' style={{marginLeft: '2px', float: 'right', marginRight: '0px'}}>
                                            <Link to={'/manage/product/create'} className='btn-link-danger-mid' >Добавить</Link>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-start' style={{marginTop: '20px', marginLeft: '0px', minHeight: '400px', flexWrap: "wrap"}}>
                                        {
                                            (!!this.state.products.content && !!this.state.products.content.length > 0)
                                                ? this.state.products.content.map((product) => {
                                                    return (
                                                        <div key={product.id} className="category-product-card">
                                                            {
                                                                product.rejected ?
                                                                    <div style={{height: 40}}>
                                                                        <a className='goods-status' onClick={()=>{
                                                                            this.setState({messageText: product.reject.comment, rejectIsOpen: true});
                                                                        }
                                                                        } style={{color: 'red', opacity: '1'}} href="javascript:void(0)"><div>Отклонен</div> <div>(посмотреть причину)</div></a>
                                                                    </div>
                                                                     :
                                                                    <div style={{height: 40, lineHeight: '50px'}}>
                                                                        <div>
                                                                            <span className='goods-status' style={product.approved ? {color: 'green'} : {color: 'darkgray'}}>{product.approved ? 'Одобрен' : 'На рассмотрении'}</span>
                                                                        </div>
                                                                    </div>
                                                            }
                                                            <div className="search-card-goods-image"
                                                                 style={{
                                                                     backgroundImage: 'url("' + (product.photos.filter((ph) => {
                                                                         return ph.orderPlace === 1
                                                                     }).length > 0 ? product.photos.filter((ph) => {
                                                                         return ph.orderPlace === 1
                                                                     })[0].photoMin : '') + '")',
                                                                     backgroundSize: 'cover',
                                                                     backgroundRepeat: 'no-repeat',
                                                                     backgroundPositionX: 'center',
                                                                     backgroundPositionY: 'center',
                                                                     position: 'relative'
                                                                 }}
                                                            >
                                                                {
                                                                    product.isAvailable
                                                                        ?
                                                                        <div style={
                                                                            {
                                                                                width: '83px',
                                                                                height: '25px',
                                                                                backgroundColor: '#79CA1F',
                                                                                display: 'block',
                                                                                position: 'absolute',
                                                                                bottom: '0',
                                                                                borderRadius: '0 5px 0 0'
                                                                            }
                                                                        }><span style={
                                                                            {
                                                                                color: '#FFFFFF',
                                                                                fontFamily: 'Open Sans',
                                                                                fontSize: '12px',
                                                                                lineHeight: '20px',
                                                                                marginLeft: '10px'
                                                                            }
                                                                        }>В наличии</span> </div>
                                                                        :
                                                                        ''
                                                                }
                                                            </div>
                                                            <div className="main-card-goods-desc">
                                                                <div className="main-card-goods-name">
                                                                    <Link className="main-card-goods-name-text"
                                                                          to={product.approved
                                                                              ? '/product/' + product.id
                                                                              : '/manage/preview/' + product.id}>{product.name ? product.name : 'Без имени'}</Link>
                                                                </div>
                                                                <div className="main-card-goods-action d-flex justify-content-around">
                                                                    <button
                                                                            onClick={() => {
                                                                                this.dialogOnOpen(product.id);
                                                                            }}
                                                                            style={{
                                                                                color: '#e60936',
                                                                                opacity: 0.7
                                                                            }}
                                                                            className='card-goods-action-btn'>Удалить</button>
                                                                    <Link
                                                                        style={{
                                                                            color: '#007bff'
                                                                        }}
                                                                        to={'/manage/product/edit/'+product.id} className={'card-goods-action-btn'}>Редактировать</Link>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    );
                                                })
                                                :
                                                <div className='d-flex justify-content-center' style={{marginTop: 20}}>
                                                    <span className='empty-section-text'>Тут будут отображаться товары, которые Вы создатите в своем магазине :)</span>
                                                </div>
                                        }
                                    </div>
                                </TabContainer>}
                                {value === 1 && <TabContainer style={{padding: '0px'}}>
                                    <div style={{marginTop: '0px', width: '100%', marginLeft: '0px', padding: 0}}>
                                        {
                                            (!!this.state.orders && !!this.state.orders.content && this.state.orders.content.length > 0) ? this.state.orders.content.map((order, idx) => {
                                                    return (
                                                        <div style={{marginTop: idx === 0 ? '32px' : '48px'}} key={order.orderId}>

                                                            <div className='order-header-div'  style={{position: "relative"}}>
                                                                <div style={{marginLeft: '12px', marginTop: '14px', paddingTop: '5px'}}>
                                                                    <Link to={'/user/' + order.user.id}
                                                                          className="order-header-store">{order.user.name}</Link>
                                                                </div>
                                                                <div style={{marginLeft: '12px', marginTop: '8px'}}>
                                                                    <span className='order-header-number'>Заказ № {order.orderId} от {moment(order.createdDate).format('DD MMMM, YYYY').toString()}</span>
                                                                </div>
                                                                <div style={{
                                                                    position: "absolute",
                                                                    top: 0,
                                                                    right: 0,
                                                                    marginRight: '16px',
                                                                    marginTop: '5px'
                                                                }}>
                                                                    <span className='user-cabinet-order-status'>{this.getOrderStatus(order.status)}</span>
                                                                </div>
                                                            </div>
                                                            <div className='row' style={{marginTop: '15px', marginLeft: '0px', marginRight: 0}}>
                                                                {
                                                                    order.item ?
                                                                        <OrderPhotoLinkComponent order={order} />
                                                                        : <OrderPhotoComponent order={order} />
                                                                }

                                                                <div className='col' style={{textAlign: 'right', paddingRight: '20px'}}>
                                                                    <span className='order-amount-cost'>{order.amount} X {order.cost} BYN</span>
                                                                </div>
                                                                <div className="col" style={{textAlign: 'right', paddingRight: '20px'}}>
                                                                    <div>
                                                                        <span className='order-cost-sum'>{order.cost * order.amount} BYN</span>
                                                                    </div>
                                                                    <div className='order-action-menu' style={{marginTop: '12px'}}>
                                                                        <Dropdown drop={'up'} onToggle={(isOpen, event, metadata) => {
                                                                            this.setState({dropdownIsOpen: isOpen});
                                                                        }}>
                                                                            <Dropdown.Toggle as={
                                                                                React.forwardRef(({ children, onClick }, ref) => (
                                                                                    <a
                                                                                        href=""
                                                                                        ref={ref}
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            onClick(e);
                                                                                            this.handleClickMenu(e, order.orderId, order.status);
                                                                                        }}
                                                                                    >
                                                                                        {children}
                                                                                        <img id={'img-' + order.orderId} style={{marginLeft: '5px'}}
                                                                                             src={this.state.dropdownIsOpen ? arrowUpIcon : arrowDownIcon}
                                                                                             className="arrow-icon" />
                                                                                    </a>
                                                                                ))
                                                                            }
                                                                                             id={'dropdown-custom-components'+order.id}
                                                                         >
                                                                                Выберите
                                                                            </Dropdown.Toggle>

                                                                            <Dropdown.Menu className={'dropdown-menu-align'}>
                                                                                {
                                                                                    this.state.orderStatusMenu === 1 ?
                                                                                        <Dropdown.Item className={'dropdown-item dropdown-item-menu dropdown-item-add'} onClick={(e) => {
                                                                                            this.sendOnOpen(this.state.orderId, 2);
                                                                                        }}
                                                                                                       >Принять заказ</Dropdown.Item>
                                                                                        :
                                                                                        ''
                                                                                }
                                                                                {
                                                                                    this.state.orderStatusMenu === 1 ?
                                                                                        <Dropdown.Item className={'dropdown-item dropdown-item-menu dropdown-item-add'} onClick={(e) => {
                                                                                            this.sendOnOpen(this.state.orderId, 3);
                                                                                        }}
                                                                                                       >Отклонить заказ</Dropdown.Item>
                                                                                        :
                                                                                        ''
                                                                                }
                                                                                {
                                                                                    this.state.orderStatusMenu === 2 ?
                                                                                        <Dropdown.Item className={'dropdown-item dropdown-item-menu dropdown-item-add'} onClick={(e) => {
                                                                                            this.sendOnOpen(this.state.orderId, 4);
                                                                                        }}
                                                                                                       >Готово</Dropdown.Item>
                                                                                        :
                                                                                        ''
                                                                                }
                                                                                <Dropdown.Item className={'dropdown-item dropdown-item-menu dropdown-item-add'} onClick={(e) => {
                                                                                    this.sendMessage(order.orderId);
                                                                                }}
                                                                                >Написать покупателю</Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                     </div>

                                                                </div>
                                                            </div>

                                                            <div style={{minWidth: '100px'}}>
                                                                <div style={{marginTop: '0px', marginBottom: '8px'}}>
                                                                    {
                                                                        order.item ?
                                                                            <Link className="order-goods-name"
                                                                                  to={'/product/' + order.item.id}>{order.item.name}</Link>
                                                                            :
                                                                            <div>
                                                                                <span className='order-goods-name'>{order.goodsName}</span>
                                                                            </div>
                                                                    }

                                                                </div>
                                                                {   order.address ?
                                                                    <div style={{marginBottom: '6px'}}>
                                                                        <span className='order-info-label'>Адрес: </span> <span className='order-info-text'>{order.address}</span>
                                                                    </div>
                                                                    : ''
                                                                }
                                                                {   order.phone ?
                                                                    <div style={{marginBottom: '6px'}}>
                                                                        <span className='order-info-label'>Телефон: </span> <span className='order-info-text'>{order.phone}</span>
                                                                    </div>
                                                                    : ''
                                                                }
                                                                {   order.delivery ?
                                                                    <div style={{marginBottom: '6px'}}>
                                                                        <span className='order-info-label'>Тип доставки: </span> <span className='order-info-text'>{order.delivery}</span>
                                                                    </div>
                                                                    : ''
                                                                }
                                                                {   order.comment ?
                                                                    <div style={{marginBottom: '6px'}}>
                                                                        <span className='order-info-label'>Комментарий: </span> <span className='order-info-text'>{order.comment}</span>
                                                                    </div>
                                                                    : ''
                                                                }
                                                                {   order.response ?
                                                                    <div style={{marginBottom: '6px'}}>
                                                                        <span className='order-info-label'>Ответ магазина: </span> <span className='order-info-text'>{order.response}</span>
                                                                    </div>
                                                                    : ''
                                                                }
                                                            </div>

                                                        </div>
                                                    );
                                                })
                                                :
                                                <div className='d-flex justify-content-center'>
                                                    <span className='empty-section-text'>На данный момент у Вас нет заказов - но не расстраивайтесь, они обязательно будут! :)</span>
                                                </div>
                                        }

                                    </div>
                                </TabContainer>}

                            </div>
                            <div>
                                <Dialog
                                    open={this.state.rejectIsOpen}
                                    // TransitionComponent={this.Transition}
                                    keepMounted
                                    onClose={this.dialogOnClose}
                                    aria-labelledby="alert-dialog-slide-title"
                                    aria-describedby="alert-dialog-slide-description"
                                >
                                    <DialogTitle id="alert-dialog-slide-title">
                                        {"Причина отказа"}
                                    </DialogTitle>
                                    <DialogContent>
                                        <div style={{maxWidth: '500px', wordWrap: 'break-word'}}>
                                            <span className='crafty-text-16'>{this.state.messageText}</span>
                                        </div>
                                        <br/>
                                        <div>
                                            <span className='product-delivery-line'>Устраните замечания, и после модерации товар будет одобрен</span>
                                        </div>
                                    </DialogContent>
                                    <DialogActions>
                                        <a onClick={this.dialogOnClose} href="javascript:void(0)" className='create-btn-40' style={{padding: '7px'}}>
                                            Закрыть
                                        </a>
                                    </DialogActions>
                                </Dialog>
                            </div>
                            <div>
                                <Dialog
                                    open={this.state.deleteOpen}
                                    TransitionComponent={this.Transition}
                                    keepMounted
                                    onClose={this.dialogOnClose}
                                    aria-labelledby="alert-dialog-slide-title"
                                    aria-describedby="alert-dialog-slide-description"
                                >
                                    <DialogTitle id="alert-dialog-slide-title">
                                        {"Вы точно хотите удалить этот товар?"}
                                    </DialogTitle>
                                    <DialogContent className='d-flex justify-content-center'>
                                        <CircularProgress
                                            style={{display: this.state.deleteInProgress ? 'block' : 'none'}}
                                            color="secondary"/>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={this.dialogOnClose} color="primary">
                                            Отмена
                                        </Button>
                                        <Button disabled={this.state.deleteInProgress} onClick={this.onDeleteGoods} color="secondary">
                                            Удалить
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                            <div>
                                <Dialog
                                    open={this.state.orderChangeDialog}
                                    TransitionComponent={this.Transition}
                                    keepMounted
                                    onClose={this.sendOnClose}
                                    aria-labelledby="alert-dialog-slide-title"
                                    aria-describedby="alert-dialog-slide-description"
                                >
                                    <DialogTitle id="alert-dialog-slide-title" className='d-flex justify-content-center'>
                                        {this.state.statusText}
                                    </DialogTitle>
                                    <DialogContent className='d-flex justify-content-center'>
                                        <div >
                                            {this.state.statusAlias !== 4 ?
                                                <textarea
                                                    className='textarea-element'
                                                    value={this.state.commentSendText}
                                                    onChange={this.onChangeComment}
                                                    placeholder={this.state.statusPlaceholder}
                                                    style={{
                                                        padding: '20px',
                                                        marginTop: '10px'
                                                    }} />
                                                : ''

                                            }

                                        </div>
                                        <CircularProgress
                                            style={{display: this.state.sendingInProgress ? 'block' : 'none'}}
                                            color="primary"/>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={this.sendOnClose} color="primary">
                                            Отмена
                                        </Button>
                                        <Button disabled={this.state.sendingInProgress} onClick={this.sendStatusToChange}
                                                // style={{color: '#43A047'}}
                                                color="secondary">
                                            Отправить
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                            <div>
                                <Dialog
                                    open={this.state.deleteStoreOpen}
                                    TransitionComponent={this.Transition}
                                    keepMounted
                                    onClose={this.dialogStoreOnClose}
                                    aria-labelledby="alert-dialog-slide-title"
                                    aria-describedby="alert-dialog-slide-description"
                                >
                                    <DialogTitle id="alert-dialog-slide-title">
                                        {"Вы точно хотите удалить магазин?"}
                                    </DialogTitle>
                                    <DialogContent className='d-flex justify-content-center'>
                                        <CircularProgress
                                            style={{display: this.state.deleteStoreInProgress ? 'block' : 'none'}}
                                            color="secondary"/>
                                        <span>Все ваши товары будут тоже удалены</span>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={this.dialogStoreOnClose} color="primary">
                                            Отмена
                                        </Button>
                                        <Button disabled={this.state.deleteStoreInProgress} onClick={this.onDeleteStore} color="secondary">
                                            Удалить
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                            <div>
                                <Dialog
                                    open={this.state.createStoreDiscountOpen}
                                    TransitionComponent={this.Transition}
                                    keepMounted
                                    onClose={this.dialogStoreAddDiscountOnClose}
                                    aria-labelledby="alert-dialog-slide-title"
                                    aria-describedby="alert-dialog-slide-description"
                                    fullScreen={true}
                                >
                                    <DialogTitle id="alert-dialog-slide-title" className='d-flex justify-content-center'>
                                            {"Добавление скидки на все товары"}
                                    </DialogTitle>
                                    <DialogContent className='d-flex justify-content-center'>
                                        <CircularProgress
                                            style={{display: this.state.createStoreDiscountInProgress ? 'block' : 'none'}}
                                            color="secondary"/>
                                        <div>
                                            <div style={{marginTop: '27px'}}>
                                                <div className='store-setting-label'>
                                                    <span className='goods-input-label'>Скидка в %</span><span className='goods-input-label' style={{color: '#E60936'}}>*</span>
                                                </div>
                                                <div >
                                                    <input type='text'
                                                           className='input-text-element-100-pr'
                                                           value={this.state.discount != null ? this.state.discount : ''}
                                                           onChange={this.discountOnChange}
                                                           placeholder='Введите % скидки'
                                                           style={{
                                                               paddingLeft: '20px',
                                                               marginTop: '10px'
                                                           }}/>
                                                </div>
                                                <br/>
                                                <div className='store-setting-label'>
                                                    <span className='goods-input-label'>Начало действия скидки</span><span className='goods-input-label' style={{color: '#E60936'}}>*</span>
                                                </div>
                                                <DatePicker
                                                    dateFormat="dd.MM.yyyy"
                                                    locale="ru"
                                                    selected={this.state.startDiscount}
                                                    onChange={date => this.onStartDiscountChanged(date)}
                                                    customInput={<DateCustomInput />}
                                                />
                                                <br/>
                                                <div className='store-setting-label' style={{marginTop: 25}}>
                                                    <span className='goods-input-label'>Конец действия скидки</span><span className='goods-input-label' style={{color: '#E60936'}}>*</span>
                                                </div>
                                                <DatePicker
                                                    dateFormat="dd.MM.yyyy"
                                                    locale="ru"
                                                    selected={this.state.endDiscount}
                                                    onChange={date => this.onEndDiscountChanged(date)}
                                                    customInput={<DateCustomInput />}
                                                />
                                            </div>
                                        </div>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={this.dialogStoreAddDiscountOnClose} color="primary">
                                            Отмена
                                        </Button>
                                        <Button disabled={this.state.createStoreDiscountInProgress} onClick={this.createStoreDiscount} color="secondary">
                                            Применить
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                            <div>
                                <Dialog
                                    open={this.state.deleteStoreDiscountOpen}
                                    TransitionComponent={this.Transition}
                                    keepMounted
                                    onClose={this.dialogStoreDiscountOnClose}
                                    aria-labelledby="alert-dialog-slide-title"
                                    aria-describedby="alert-dialog-slide-description"
                                >
                                    <DialogContent className='d-flex justify-content-center'>
                                        <CircularProgress
                                            style={{display: this.state.deleteStoreDiscountInProgress ? 'block' : 'none'}}
                                            color="secondary"/>
                                        Скидка будет удалена, и цены вернутся в прежнее состояние
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={this.dialogStoreDiscountOnClose} color="primary">
                                            Отмена
                                        </Button>
                                        <Button disabled={this.state.deleteStoreDiscountInProgress} onClick={this.deleteStoreDiscount} color="secondary">
                                            Удалить
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                            <ModalGateway>
                                {
                                    this.state.photoIsOpen ?
                                        <Modal onClose={this.toggleViewer}>
                                            <Carousel views={[{source: this.state.store.photoMax}]} />
                                        </Modal>
                                        : ''
                                }
                            </ModalGateway>
                        </div>
                        : <LoadingPage opacity={1}/>
                }

            </div>
       );
    }

}

const mapStateToProps = (state) => {
    return {
        dict: state.dictionary.dict,
        user: state.user,
        settings: state.settings,
        basket: state.basket,
        messages: state.messages
    };
};

OwnerStorePage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(connect(mapStateToProps)(OwnerStorePage));