import React from "react";
import {defaultCircleIcon, filledCircleIcon} from "../assets/asstes";


const RadioCrafty = () => {
    return (
        <div style={{width: '20px', height: '20px'}}>
            <img alt='' src={defaultCircleIcon}
                     className="crafty-radio" />
        </div>
    );
};

const RadioCraftyChecked = () => {
    return  (
        <div style={{width: '20px', height: '20px'}}>
            <img alt='' src={filledCircleIcon}
                      className="crafty-radio" />
        </div>
    );
};

export {RadioCrafty, RadioCraftyChecked};
