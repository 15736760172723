import React, {Component} from 'react';
import {AppBar, Toolbar, Typography, IconButton, withStyles, createMuiTheme, SwipeableDrawer} from "@material-ui/core";
import clsx from "clsx";
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Divider from "@material-ui/core/Divider";
import {connect} from "react-redux";
import {BrowserRouter, Link, Route} from "react-router-dom";
import LoginPage from "./components/LoginPage";
import MainPage from "./components/MainPage";
import {refreshSession, startLogoutAction} from "./actions/authorization";
import Footer from "./components/Footer";
import RegistrationPage from "./components/RegistrationPage";
import ForgotPassword from "./components/ForgotPassword";
import RepairPasswordPage from "./components/RepairPasswordPage";
import AgreementPage from "./components/AgreementPage";
import ProductPage from "./components/ProductPage";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from '@material-ui/icons/Close';
import SnackbarContentWrapper from "./components/SnackbarContentWrapper";
import {sendNotification} from "./actions/notifications";
import StorePage from "./components/StorePage";
import Conversation from "./components/Conversation";
import ConversationsList from "./components/ConversationsList";
import {getConversationExist} from "./api/MessageAPI";
import {createNew, getDialog, setDialogId, setIsDialog, setOpenStatus} from "./actions/messages";
import UserPage from "./components/UserPage";
import BasketPage from "./components/BasketPage";
import CatalogPage from "./components/CatalogPage";
import {isOpenLeftMenu} from "./actions/settings";
import SearchPage from "./components/SearchPage";
import UserCabinetPage from "./components/UserCabinetPage";
import UserCabinetEditPage from "./components/UserCabinetEditPage";
import AboutUsPage from "./components/AboutUsPage";
import StoreListPage from "./components/StoreListPage";
import BookmarkPage from "./components/BookmarkPage";
import CreateStorePage from "./components/CreateStorePage";
import OwnerStorePage from "./components/OwnerStorePage";
import CreateGoodsPage from "./components/CreateGoodsPage";
import ReactGA from 'react-ga';
import Fingerprint2 from 'fingerprintjs2';
import {postUser} from "./api/AnalyticAPI";
import cookie from 'react-cookies'
import {YMInitializer} from "react-yandex-metrika";
import {Helmet} from "react-helmet";
import ConfirmEmailComponent from "./components/ConfirmEmailComponent";
import GoodsListForApprove from "./components/admin/GoodsListForApprove";
import AdminUsersPage from "./components/admin/AdminUsersPage";
import StoreListForApprove from "./components/admin/StoreListForApprove";
import AdminStorePage from "./components/admin/AdminStorePage";
import AdminPreviewProductPage from "./components/admin/AdminPreviewProductPage";
import ReactPixel from 'react-facebook-pixel';
import AdminPhotoListComponent from "./components/admin/AdminPhotoListComponent";
import AdminMessageListComponent from "./components/admin/AdminMessageListComponent";
import AdminSocialListComponent from "./components/admin/AdminSocialListComponent";
import CollectionPage from "./components/CollectionPage";
import StoreMenuItem from "./components/menu/StoreMenuItem";
import UserMenuItem from "./components/menu/UserMenuItem";
import GiftsMenuItem from "./components/menu/GiftsMenuItem";
import GiftPage from "./components/GiftPage";
import {logoIcon, messageBubbleFilledIcon, messageBubbleIcon, cartFilledIcon, cartIcon, avatarIcon, userIcon, heartIcon} from "./assets/asstes";
import firebase from 'firebase/app';


firebase.initializeApp({
  apiKey: "AIzaSyDq90pXopytV2dq4m9XGNtbN1JgSKHBLwo",
  authDomain: "crafty-ff95e.firebaseapp.com",
  databaseURL: "https://crafty-ff95e.firebaseio.com",
  projectId: "crafty-ff95e",
  storageBucket: "crafty-ff95e.appspot.com",
  messagingSenderId: "382950124332",
  appId: "1:382950124332:web:04d6c759f1be83987a9a89",
  measurementId: "G-YT2K59QE5J"
});
// const messaging = firebase.messaging();
ReactGA.initialize('UA-134620892-1');
ReactGA.pageview('/');

ReactPixel.init('232415981309062');

if (window.requestIdleCallback) {
  requestIdleCallback(function () {
    Fingerprint2.get((components) => {
      const token = JSON.parse(localStorage.getItem("session"));
      let webUser = {
        token: token ? token.token : '',
        data: JSON.stringify(components),
        uniq: Fingerprint2.x64hash128(components.map(function (pair) {
          return pair.value
        }).join(), 31)
      };
      postUser(webUser);
      cookie.save('_web', webUser.uniq, {path: '/'});
      // console.log(components); // an array of components: {key: ..., value: ...}
      // console.log(Fingerprint2.x64hash128(components.map(function (pair) { return pair.value }).join(), 31)); // an array of components: {key: ..., value: ...}
    });
  })
} else {
  setTimeout(function () {
    Fingerprint2.get(function (components) {
      const token = JSON.parse(localStorage.getItem("session"));
      let webUser = {
        token: token ? token.token : '',
        data: JSON.stringify(components),
        uniq: Fingerprint2.x64hash128(components.map(function (pair) {
          return pair.value
        }).join(), 31)
      };
      // setCookie('_a__token', webUser.uniq, '/');
      cookie.save('_web', webUser.uniq, {path: '/'});

      postUser(webUser);
    })
  }, 500)
}


const styles = theme => ({
  root: {
    display: 'flex',
    // maxWidth: '360px'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#FFFFFF',
    color: '#000000',
    boxShadow: 'none'
  },
  appBarShift: {
    // width: `calc(100% - ${drawerWidth}px)`,
    width: `calc(100%)`,
    marginLeft: `calc(100%)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: `calc(100%)`,
    flexShrink: 0,
  },
  drawerPaper: {
    width: `calc(100%)`,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    // paddingLeft: theme.spacing(1),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  fullWidthCalc: {
    width: `calc(100%)`
  }
});



class App extends Component{


  constructor(props, context) {
    super(props, context);

    this.state = {
      isOpen: false
    };


  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.props.dispatch(sendNotification({open: false, messageInfo: {message: '', key: new Date().getTime()}}));
  };

  toggleDrawer = (open) => {
    if (!!this.props.messages.userPage) {
      getConversationExist(this.props.messages.userPage.id).then(
          response => {
            let exist = JSON.parse(response);
            if (exist.exist) {
              this.props.dispatch(getDialog({id: exist.conversationId, size: 20})).then(() => {
                this.props.dispatch(setDialogId(exist.conversationId));
                this.props.dispatch(setIsDialog(true));
                this.props.dispatch(setOpenStatus(open));
                this.setState({
                  messengerOpen: open,
                });
              });

            } else {
              this.props.dispatch(createNew(true));
              this.props.dispatch(setIsDialog(true));
              this.props.dispatch(setOpenStatus(open));

            }
          },
          error => {
            console.log(error);
          }
      );
    } else {
      this.props.dispatch(setOpenStatus(open));

    }


  };

  closeMenu = () => {
    this.props.dispatch(isOpenLeftMenu(false));
  };

  setIsOpen = () => {
    this.props.dispatch(isOpenLeftMenu(!this.props.settings.isOpen));
  };

  onLogout = () => {
    this.props.dispatch(startLogoutAction()).then(() => {
      this.setState(() => ({logout: true}));
      this.setIsOpen();
      this.props.dispatch(isOpenLeftMenu(false));

    });
  };

  toggleDrawerClick = () => {
    this.toggleDrawer(!this.props.messages.isOpen);

  };

  refreshToken = () => {
    this.props.dispatch(refreshSession()).then(()=>{
      setTimeout(() => {
        this.refreshToken();
      }, 10000);
    });
  };

  componentDidMount() {
    this.refreshToken();
  }

  render() {

    const { classes } = this.props;
    const {messageInfo} = this.props.notification;

    const theme = createMuiTheme({
      direction: 'ltr',
    });

    return (
        <BrowserRouter>
          <Helmet>
            <title>Crafty</title>
            <meta name="Description" content="Современный маркетплейс для людей, которые неравнодушны к товарам ручной работы." />
            <meta name="description" content="Современный маркетплейс для людей, которые неравнодушны к товарам ручной работы." />
            <meta name="og:description" content='Современный маркетплейс для людей, которые неравнодушны к товарам ручной работы.' />
            <meta name="og:title" content={'Crafty'} />
            <meta property="og:image" content={'https://crafty.by/images/logo.png'} />
            <link rel="canonical" href={'https://crafty.by'} />
            <meta name="keywords" content="crafty, ремесло, ручная работа, авторская работа, handmade" />
            <meta property="og:site_name" content="Crafty.by" />
          </Helmet>
          <YMInitializer accounts={[56086378]} options={{
            clickmap:true,
            trackLinks:true,
            accurateTrackBounce:true,
            webvisor: true
          }} />
        <div className={`${classes.root}`}>
          {/*<CssBaseline />*/}
          <AppBar
              position="fixed"
              className={clsx(classes.appBar, {
                [classes.appBarShift]: this.state.isOpen,
              })}
          >
            <Toolbar >
              <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={this.setIsOpen}
                  edge="start"
                  className={clsx(classes.menuButton, this.state.isOpen && classes.hide)}
              >
                <MenuIcon />
              </IconButton>
              <div className={`${classes.fullWidthCalc}`}>
                <div className='d-flex justify-content-center' style={{paddingRight: '52px', width: '100%'}}>
                  <Link to={'/'}>
                    <img src={logoIcon} alt="crafty.by" width='58px' height='32px' />
                  </Link>
                </div>
                <div style={{position: 'absolute', top: 10, right: 16}}>
                  <div className='message-div-header' style={{display: 'inline-block', marginRight: 10}} >
                    {
                      this.props.user.name
                          ?
                          <a onClick={() => {
                            this.toggleDrawerClick();
                          }}><img className='inline icon-20 ' alt={'message bubble'} src={this.props.messages.unread > 0 ? messageBubbleFilledIcon : messageBubbleIcon}/></a>
                          :
                          <Link to={'/login'}><img alt={'message bubble'} className='inline icon-20' src={messageBubbleIcon}/></Link>

                    }
                  </div>
                  <div style={{marginRight: 10, display: "inline-block"}}>
                    <Link to={this.props.user.name ? '/basket' : '/login?redirectURL=/basket'}>
                      {this.props.basket.count > 0 ? <img className='icon-20' alt={'cart filled icon'} src={cartFilledIcon}/> : <img alt={'cart icon'} className='icon-20' src={cartIcon}/>}
                    </Link>
                  </div>
                  <div style={
                    this.props.user.name ?
                        {display: 'inline-block', position: "relative", top: 8}
                    :
                        {display: 'inline-block', position: "relative", top: 0}

                  }>
                    {
                      this.props.user.name ?
                          <Link to="/cabinet" className="row d-flex justify-content-end inline" style={{marginLeft: 0, marginRight: 0}}>
                            <div style={{
                              height: '25px',
                              width: '25px',
                              borderRadius: '50%',
                              backgroundImage: 'url("' + (this.props.user.photoMin ? this.props.user.photoMin : avatarIcon) + '")',
                              backgroundSize: (this.props.user.photoMin ? 'cover' : 'contain'),
                              backgroundRepeat: 'no-repeat',
                              backgroundPositionX: 'center',
                              backgroundPositionY: 'center',

                            }}>

                            </div>
                          </Link>
                          :
                          <Link to={'/login?redirectURL=' + window.location.pathname} className="row d-flex justify-content-end inline" style={{marginLeft: 0, marginRight: 0}}>
                            <div style={{
                              height: '20px',
                              width: '20px',
                              backgroundSize: 'cover',
                              backgroundRepeat: 'no-repeat',
                              backgroundPositionX: 'center',
                              backgroundPositionY: 'center'
                            }}><img alt={'user icon'} src={userIcon}
                                    className="icon-20" /></div>

                          </Link>

                    }

                  </div>
                </div>
              </div>
            </Toolbar>
          </AppBar>
          <SwipeableDrawer
              className={classes.drawer}
              variant="temporary"
              anchor="left"
              open={this.props.settings.isOpen}
              classes={{
                paper: classes.drawerPaper,
              }}
              onClose={this.setIsOpen}
              onOpen={this.setIsOpen}
          >

            <div className='left-header-avatar'>
              <UserMenuItem user={this.props.user} closeMenu={this.closeMenu} />
            </div>
            <div className={classes.drawerHeader}>

              <IconButton onClick={this.setIsOpen}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            {
              this.props.user.name ?
                  <div style={{marginLeft: '21px', marginTop: '20px', marginBottom: '15px'}}>
                    <Link onClick={this.closeMenu} className='category-menu-label' to={'/bookmark'}><img alt={'bookmarks'} className='inline icon-20' src={heartIcon}/><span className='category-menu-label'>Закладки</span></Link>
                  </div>
                  : ''
            }
            <GiftsMenuItem user={this.props.user} closeMenu={this.closeMenu} />
            <StoreMenuItem user={this.props.user} closeMenu={this.closeMenu} />


            <Divider />
            <div style={{marginLeft: '12px', marginTop: '20px'}}>
              <div style={{marginBottom: '15px', paddingRight: '0px', paddingLeft: '0px'}}>
                <div>
                  <div className='category-menu-label'>
                    <Link className='category-menu-label' onClick={this.closeMenu} to={'/search'}> Все </Link>
                  </div>
                </div>
              </div>
              {this.props.dict ? this.props.dict.categories.map((category) => (
                  <div key={category.alias} style={{marginBottom: '15px', paddingRight: '0px', paddingLeft: '0px'}}>
                    <div>
                      <div className='category-menu-label'>
                        <Link className='category-menu-label' onClick={this.closeMenu} to={'/catalog/'+category.alias}> {category.name} </Link>
                      </div>
                    </div>
                  </div>

              ))
              : ''
              }
            </div>

            {
              (this.props.user.name && this.props.user.roles.filter(role => (role.name === 'ROLE_MANAGER'
                  || role.name === 'ROLE_ADMIN')).length > 0)
                  ?
                  <div style={{marginLeft: '12px', marginTop: '20px'}}>
                    <Divider />
                    <div style={{marginBottom: '15px', paddingRight: '0px', paddingLeft: '0px', marginTop: 20}}>
                      <div>
                        <div className='category-menu-label'>
                          <Link className='category-menu-label' onClick={this.closeMenu} to={'/admin/approve'}> Модерация товаров </Link>
                        </div>
                      </div>
                    </div>
                    <div style={{marginBottom: '15px', paddingRight: '0px', paddingLeft: '0px'}}>
                      <div>
                        <div className='category-menu-label'>
                          <Link className='category-menu-label' onClick={this.closeMenu} to={'/admin/store/approve'}> Модерация магазинов </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  : ''
            }
            {
              (this.props.user.name && this.props.user.roles.filter(role => (role.name === 'ROLE_ADMIN')).length > 0)
                  ? <div style={{marginLeft: '15px'}}>
                      <br/>
                      <div style={{marginBottom: '15px', paddingRight: '0px', paddingLeft: '0px'}}>
                        <div>
                          <div className='category-menu-label'>
                            <Link className='category-menu-label' onClick={this.closeMenu} to={'/admin/users'}> Пользователи </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  : ''
            }

            { this.props.user.name ?
              <div>
                <Divider />
                <div style={{marginTop: 15}}>
                  <Link to={'/'} className='exit-button-menu' onClick={this.onLogout}>Выйти</Link>
                </div>
              </div>
                : ''
            }


          </SwipeableDrawer>
          <main
              className={clsx(classes.content, {
                [classes.contentShift]: this.state.isOpen,
              })}
          >
            <div className={classes.drawerHeader} />
            <Route path="/" component={MainPage} exact={true}/>
            <Route path="/login" component={LoginPage} exact={true}/>
            <Route path="/registration" component={RegistrationPage}/>
            <Route path="/login/forgot" component={ForgotPassword} exact={true}/>
            <Route path="/forgot/:key" component={RepairPasswordPage} exact={true}/>
            <Route path="/agreement" component={AgreementPage} exact={true}/>
            <Route path="/product/:id" component={ProductPage}/>
            <Route path="/store/:storeId" component={StorePage}/>
            <Route path="/cabinet" component={UserCabinetPage} exact={true}/>
            <Route path="/cabinet/gift" component={GiftPage} exact={true} />
            <Route path="/cabinet/edit" component={UserCabinetEditPage} exact={true}/>
            <Route path="/user/:userId" component={UserPage} exact={true}/>
            <Route path="/basket" component={BasketPage}/>
            <Route path="/catalog/:alias" component={CatalogPage} exact={true}/>
            <Route path="/catalog/:alias/:child" component={CatalogPage}/>
            <Route path="/collection/:id" component={CollectionPage} exact={true}/>
            <Route path="/search" component={SearchPage}/>
            <Route path="/about" component={AboutUsPage} exact={true}/>
            <Route path="/stores" component={StoreListPage} exact={true} />
            <Route path="/bookmark" component={BookmarkPage} exact={true} />
            <Route path="/manage/store" component={CreateStorePage} exact={true} />
            <Route path="/manage/owner" component={OwnerStorePage} exact={true} />
            <Route path="/manage/product/create" component={CreateGoodsPage} exact={true} />
            <Route path="/manage/preview/:id" component={ProductPage} exact={true} />
            <Route path="/manage/product/edit/:goodsId" component={CreateGoodsPage} exact={true} />
            <Route path="/confirm/:hash" component={ConfirmEmailComponent} exact={true}/>
            <Route path="/admin/approve" component={GoodsListForApprove} exact={true}/>
            <Route path="/admin/users" component={AdminUsersPage} exact={true}/>
            <Route path="/admin/photos" component={AdminPhotoListComponent} exact={true}/>
            <Route path="/admin/messages" component={AdminMessageListComponent} exact={true}/>
            <Route path="/admin/social" component={AdminSocialListComponent} exact={true}/>
            <Route path="/admin/store/approve" component={StoreListForApprove} exact={true}/>
            <Route path="/admin/store/preview/:storeId" component={AdminStorePage} exact={true}/>
            <Route path="/admin/preview/:id" component={AdminPreviewProductPage}/>

            <Footer />
            {/*<CookieConsent*/}
            {/*    buttonText="Я понимаю"*/}
            {/*>*/}
            {/*  Для функционирования этого сайта мы используем cookie*/}
            {/*</CookieConsent>*/}
          </main>
        </div>


          <Snackbar
              key={messageInfo.key}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              open={this.props.notification.open}
              autoHideDuration={6000}
              onClose={this.handleClose}
              onExited={this.handleExited}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
              // message={<span id="message-id">{messageInfo.message}</span>}
              action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={this.handleClose}
                >
                  <CloseIcon/>
                </IconButton>,
              ]}
          >
            <SnackbarContentWrapper
                onClose={this.handleClose}
                variant={messageInfo.variant}
                message={messageInfo.message}
            />

          </Snackbar>
          {
            !this.props.user.name ? '' :
                <SwipeableDrawer
                    className={classes.drawer}
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    anchor="right"
                    style={{
                      boxShadow: '-8px 16px 32px 0 rgba(64, 75, 105, 0.2)'
                    }}
                    variant='temporary'
                    open={this.props.messages.isOpen}
                    onClose={() => {
                      this.toggleDrawer(false);
                    }}
                    onOpen={() => {
                      this.toggleDrawer(true);
                    }}
                >
                  {
                    this.props.messages.isDialog ?
                        <Conversation/>
                        :
                        <ConversationsList/>
                  }
                </SwipeableDrawer>
          }

        </BrowserRouter>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    dict: state.dictionary.dict,
    user: state.user,
    settings: state.settings,
    basket: state.basket,
    messages: state.messages,
    notification: state.notification,

  };
};

export default withStyles(styles)(connect(mapStateToProps)(App));
