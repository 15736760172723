import React from "react";


export const ErrorFieldTextComponent = (props) => {
    return (
        <div className="d-flex justify-content-center">
            <span className='error-message'
                style={{display: props.isError ? 'block' : 'none'}}
            >{props.message}</span>
        </div>
    );
};


