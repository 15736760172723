import {Link} from "react-router-dom";
import avatarIcon from "../../assets/avatar.png";
import userIcon from "../../assets/user.svg";
import React from "react";


const userMenu = (props) => {
    return props.user.name ?
        <Link onClick={props.closeMenu} to="/cabinet" className="row d-flex justify-content-end inline" style={{marginLeft: 0, marginRight: 0}}>
            <div style={{
                height: '30px',
                width: '30px',
                borderRadius: '50%',
                backgroundImage: 'url("' + (props.user.photoMin ? props.user.photoMin : avatarIcon) + '")',
                backgroundSize: (props.user.photoMin ? 'cover' : 'contain'),
                backgroundRepeat: 'no-repeat',
                backgroundPositionX: 'center',
                backgroundPositionY: 'center'
            }}> </div>
            <span style={{maxWidth: '120px'}} id="login-header" className='hide-text'>{props.user.name}</span>
        </Link>
        :
        <Link onClick={props.closeMenu} to={'/login?redirectURL=' + window.location.pathname} className="row d-flex justify-content-end inline" style={{marginLeft: 0, marginRight: 0}}>
            <div style={{
                height: '20px',
                width: '20px',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPositionX: 'center',
                backgroundPositionY: 'center'
            }}><img alt={'user'} src={userIcon}
                    className="icon-20" /></div>
            <span id="login-header">Войти</span>
        </Link>

}

export default userMenu;