export const loadUserAgentParams = () => {

    let browser = '';
    let isOpera = false, isFirefox = false, isSafari = false, isIE = false, isEdge = false, isChrome = false, isEdgeChromium = false;
    try {
        // Opera 8.0+
        // eslint-disable-next-line no-undef
        isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
        if (isOpera)
            browser = 'Opera';
    }catch (e) {
        isOpera = false;
    }

    try {
        // Firefox 1.0+
        isFirefox = typeof InstallTrigger !== 'undefined';
        if (isFirefox)
            browser = 'Firefox';
    }catch (e) {
        isFirefox = false;
    }

    try {
        // Safari 3.0+ "[object HTMLElementConstructor]"
        isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
        if (isSafari)
            browser = 'Safari';
    } catch (e) {
        isSafari = false;
    }

    try {
        // Internet Explorer 6-11
        isIE = /*@cc_on!@*/false || !!document.documentMode;
        if (isIE)
            browser = 'IE';
    }catch (e) {
        isIE = false;
    }

    try {
        // Edge 20+
        isEdge = !isIE && !!window.StyleMedia;
        if (isEdge)
            browser = 'Edge';
    }catch (e) {
        isEdge = false;
    }
    try {
        // Chrome 1 - 79
        isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
        if (isChrome)
            browser = 'Chrome';
    }catch (e) {
        isChrome = false;
    }

    try {
        // Edge (based on chromium) detection
        isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);
        if (isEdgeChromium)
            browser = 'EdgeChromium';
    }catch (e) {
        isEdgeChromium = false;
    }


    let userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'Mac'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null,
        isMacOS = false,
        isIOS = false,
        isWindows = false,
        isAndroid = false,
        isLinux = false;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
        isMacOS = true;
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
        isIOS = true;
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
        isWindows = true;
    } else if (/Android/.test(userAgent)) {
        os = 'Android';
        isAndroid = true;
    } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
        isLinux = true;
    }



    let data = {
        isIOS: isIOS,
        isWindows: isWindows,
        isLinux: isLinux,
        isAndroid: isAndroid,
        isMacOS: isMacOS,
        os: os,
        browser: browser,
        isChrome: isChrome,
        isFirefox: isFirefox,
        isOpera: isOpera,
        isSafari: isSafari,
        isEdge: (isEdge || isEdgeChromium),
    };


    return {
        type: 'LOAD_ALL',
        values: {
            ...data
        }
    }
};