import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import {connect} from "react-redux";
import {getAllUsers} from "../../api/AdminUserAPI";
import {Link} from "react-router-dom";
import {avatarIcon} from "../../assets/asstes";

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    tabsRoot: {
        borderBottom: '1px solid #e8e8e8',
        width: '960px'
    },
    tabsIndicator: {
        backgroundColor: '#E60936',
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing.unit * 4,
        fontSize: '20px',
        lineHeight: '30px',
        fontFamily: [
            'Tanos'
        ].join(','),
        '&:hover': {
            color: '#40a9ff',
            opacity: 1,
        },
        '&$tabSelected': {
            color: '#283149',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: '#283149',
            outline: 0
        },
    },
    tabSelected: {},
    typography: {
        padding: theme.spacing.unit * 3,
    },
});

class AdminUsersPage extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            filter: {
                page: 0,
                size: 100,
                sort: 'createdDate,desc'
            },
            users: null
        };
    }

    componentDidMount() {
        if (window){
            window.scrollTo(0, 0);
        }
        getAllUsers(this.state.filter).then(
            response => {
                let users = JSON.parse(response);
                this.setState({users});
            },
            error => {
                console.log(error);
            }
        );

    }

    render() {
        return (
            <div>
                <div className='d-flex justify-content-center'>
                    <h1>Список пользователей</h1>
                </div>
                <div >
                    {
                        (this.state.users && this.state.users.content.length > 0)
                        ?
                            this.state.users.content.map(user => {
                                return (
                                    <div className='inline-block' key={user.id} style={{marginTop: '20px', width: '900px'}}>
                                        <div className='inline-block'>
                                            <div ref='mainPhoto' className="user-cabinet-page-photo"
                                                 style={{
                                                     backgroundImage: 'url("' + (user.photoMin ? user.photoMin : avatarIcon) + '")',
                                                     backgroundSize: (user.photoMid ? 'cover' : 'contain'),
                                                     backgroundRepeat: 'no-repeat',
                                                     backgroundPositionX: 'center',
                                                     backgroundPositionY: 'center',
                                                     borderRadius: '5%'
                                                 }}
                                            ></div>
                                        </div>
                                        <div className='inline-block' style={{marginLeft: '20px'}}>
                                            <div style={{display: 'block'}}>
                                                <Link to={'/user/'+user.id}>Name: {user.name}</Link>
                                            </div>
                                            <div style={{display: 'block'}}>
                                                <p>City: {user.city}</p>
                                            </div>
                                            <div style={{display: 'block'}}>
                                                <p>Country: {user.country}</p>
                                            </div>
                                            <div style={{display: 'block'}}>
                                                <p>Email: {user.email}</p>
                                            </div>
                                            <div style={{display: 'block'}}>
                                                <p>Phone: {user.phone}</p>
                                            </div>

                                        </div>
                                        <div className='inline-block' style={{marginLeft: '20px'}}>
                                            <div>
                                            <span>Roles: </span>
                                            {
                                                user.roles.map(role => {
                                                    return (
                                                        <div>
                                                            <span>{role.name}</span>
                                                        </div>
                                                    );
                                                })
                                            }
                                            </div>
                                            <div>
                                                <span>Created Date: {user.createdDate}</span>

                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        :
                            ''
                    }
                </div>
            </div>
        );
    }


}

const mapStateToProps = (state) => {
    return {
        dict: state.dictionary.dict,
        user: state.user,
        settings: state.settings,
        basket: state.basket,
        notification: state.notification,
        messages: state.messages
    };
};

AdminUsersPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(connect(mapStateToProps)(AdminUsersPage));