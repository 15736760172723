import React from 'react';
import {getGoodsById, getFilteredGoods, getMyGoodsById} from '../../api/GoodsAPI';

import {Link, Redirect} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {refreshSession} from "../../actions/authorization";
import {getAdminGoodsById} from "../../api/AdminGoodsAPI";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Carousel, {Modal, ModalGateway} from "react-images";
import {selectByAliasChild} from "../../selectors/category";
import LoadingPage from "../LoadingPage";
import {Helmet} from "react-helmet";
import * as moment from "moment";
import ProductListComponent from "../ProductListComponent";



const customStyles = {
    view: () => ({
        // none of react-images styles are passed to <View />
        height: '24rem',
        // width: 600,
    })
};

class AdminPreviewProductPage extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            user: {},
            product: {},
            photos: [],
            current: 0,
            otherProduct: [],
            redirect: false,
            redirectLogin: false,
            message: '',
            reportOpen: false,
            sendingInProgress: false,
            photoIsOpen: false,
            photoArray: [],
            photoArrayMid: [],
            commentFilter: {
                page: 0,
                size: 4,
                goodsId: this.props.match.params.id
            },
            comments: null,
            messageIsOpen: false,
            comment: '',
            commentInProgress: false,
            redirectPhone: false
        };    }

    componentDidMount() {
        if (window){
            window.scrollTo(0, 0);
        }
        this.updateProduct();

    }


    getViewsLabel = (number) => {
        if (number > 4 && number < 20) {
            return 'просмотров';
        }
        let numStr = number.toString();
        let lastNumber  = 0;
        if (numStr.length > 1) {
            lastNumber = Number.parseInt(numStr.substr(numStr.length - 1));
        }else{
            lastNumber = Number.parseInt(numStr);
        }
        if (lastNumber === 0 || lastNumber > 4) {
            return 'просмотров';
        }
        if (lastNumber === 1) {
            return 'просмотр';
        }
        if (lastNumber > 1 && lastNumber < 5) {
            return 'просмотра';
        }
        return 'просмотров';
    };

    updateProduct = () => {
        let product;
        getAdminGoodsById(this.props.match.params.id).then(
            response => {
                product = JSON.parse(response);
                this.setState(()=>{
                    return {product: product};
                });
                this.loadOther(product.store.id);
                let photos = product.photos;
                photos.sort((a,b)=>{
                    if (a.orderPlace < b.orderPlace){
                        return -1;
                    }
                    if (a.orderPlace > b.orderPlace){
                        return 1;
                    }
                    return 0;
                });
                let photoArray = [];
                photos.forEach(photo => {
                    photoArray.push({source: photo.photoMax});
                });
                let photoArrayMid = [];
                photos.forEach(photo => {
                    photoArray.push({source: photo.photoMax});
                    photoArrayMid.push({source: photo.photoMid});
                });
                this.setState(() => ({photos: photos, photoArray: photoArray, photoArrayMid: photoArrayMid}));
            },
            error => {
                console.log(JSON.stringify(error.message));
                let resp = JSON.parse(error.message);
                if (error.code === 401 || error.code === 403){
                    this.props.dispatch(refreshSession()).then(() => {
                        this.updateProduct();
                    });
                }
                if (error.code === 404) {
                    this.props.history.goBack();
                }
            }
        );
    };

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id){
            this.updateProduct();
        }
    }


    toggleViewer = () => {
        this.setState(() => ({photoIsOpen: !this.state.photoIsOpen}));
    };

    loadOther = (id) => {
        let otherProduct;
        const filter = {
            page: 0,
            size: 4,
            storeId: id
        };
        getFilteredGoods(filter).then(
            response => {
                otherProduct = JSON.parse(response);
                this.setState(()=>({otherProduct: otherProduct.content}));
            },
            error => {
                console.log(error);
            }
        );
    };

    hexToRGB = (hex) => {
        const RGB_HEX = /^#?(?:([\da-f]{3})[\da-f]?|([\da-f]{6})(?:[\da-f]{2})?)$/i;

            const [ , short, long ] = String(hex).match(RGB_HEX) || [];

            if (long) {
                const value = Number.parseInt(long, 16);
                return [ value >> 16, value >> 8 & 0xFF, value & 0xFF ];
            } else if (short) {
                return Array.from(short, s => Number.parseInt(s, 16)).map(n => (n << 4) | n);
            }

    };


    CustomView = (innerProps) => {

        // console.log(innerProps);
        return (
            <div className="product-image-lg"
                 onClick={() => {
                     this.toggleViewer(innerProps.currentIndex);
                 }}
                 style={{
                     backgroundImage: 'url("'
                         + innerProps.data.source + '")',
                     backgroundSize: 'contain',
                     backgroundRepeat: 'no-repeat',
                     backgroundPosition: 'center'
                 }}>

            </div>
        )
    };

    FooterCount = (props) => {

        // console.log(props);
        const activeView = props.currentIndex + 1;
        const totalViews = props.views.length;
        return (
            <div>
                <span>{activeView} из {totalViews}</span>
            </div>
        );
    };


    render() {
        const { classes } = this.props;

        const category = this.state.product.category ? selectByAliasChild(this.props.dict.categories, this.state.product.category.alias) : null;
        return (
            <div>
                {this.state.redirect ? <Redirect to={'/'}/> : ''}
                {this.state.redirectLogin ? <Redirect to={'/login?redirectURL=/product/'+this.state.product.id}/> : ''}
                {this.state.redirectPhone ? <Redirect to={'/cabinet/edit'}/> : ''}
                {
                    this.state.product.name && this.state.photoArrayMid.length > 0 ?
                        <div>
                            {
                                this.state.loadingAction ? <LoadingPage opacity={0.5}/> : ''
                            }
                            <Helmet>
                                <title>{this.state.product.name ? this.state.product.name.replace(/[^0-9a-zA-ZА-Яа-яЁё .,?!:;_]/gi, ' ') : ''}</title>
                                <meta name="description" content={this.state.product.description ? (this.state.product.description.substring(0, 150).replace(/[^0-9a-zA-ZА-Яа-яЁё .,?!:;_]/gi, ' ') + '...') : ''} />
                                <meta name="og:description" content={this.state.product.description ? (this.state.product.description.substring(0, 150).replace(/[^0-9a-zA-ZА-Яа-яЁё .,?!:;_]/gi, ' ') + '...') : ''} />
                                <meta name="og:title" content={(this.state.product.name ? this.state.product.name.replace(/[^0-9a-zA-ZА-Яа-яЁё .,?!:;_]/gi, ' ') : '')} />
                                <meta property="og:image" content={(this.state.product.photos.length > 0
                                    ? this.state.product.photos.filter((ph) => {
                                        return ph.orderPlace === 1
                                    })[0].photoMid
                                    : '')} />
                                <link rel="canonical" href={'https://crafty.by/product/'+this.state.product.id} />
                                <meta name='keywords' content={this.state.product.name.replace(/[^0-9a-zA-ZА-Яа-яЁё .,?!:;_]/gi, ' ') + ', купить, заказать, цена, в интернет магазине, ' + this.state.product.store.city} />
                                <meta property="og:url" content={'https://crafty.by/product/'+this.state.product.id}/>
                                <meta property="og:type" content="product" />
                                <meta property="og:availability" content={this.state.product.isAvailable ? 'instock' : 'preorder'} />
                                <meta property="product:price:amount" content={this.state.product.cost.cost} />
                                <meta property="product:price:currency" content="BYN" />
                                <meta property="og:brand" content={this.state.product.store.name}/>

                            </Helmet>
                            <div  className="product-image-lg">
                                <Carousel styles={customStyles} views={this.state.photoArrayMid} components={{ View: this.CustomView, FooterCount: this.FooterCount }}/>
                            </div>
                            {
                                category ?
                                    <div className='path-category' style={{marginTop: 5}}>
                                        <Link className='path-category' to={'/catalog/' + category.parent.alias}>{category ? category.parent.name : ''}</Link>
                                        <span className='path-category'> / </span>
                                        <Link className='path-category' to={'/catalog/' + category.parent.alias + '/' + category.alias}>{category ? category.name : ''}</Link>
                                    </div>
                                    : ''
                            }

                            <div className='product-page-name'>
                                <span>{this.state.product.name}</span>
                            </div>

                            <div className='product-cost-block' style={{position: "relative"}}>
                                <div className='product-cost'>
                                    <span>{this.state.product.cost.cost} BYN</span>
                                </div>
                                <div style={{position: "absolute", right: 0, top: 0}}>
                                    <button onClick={this.addOrderToBasket} className='basket-button'>
                                        {
                                            this.props.user.name
                                                ? (this.props.basket.items.filter(value => {
                                                    return value.item.id === this.state.product.id;
                                                }).length > 0) ? 'Добавлено в корзину' : 'Добавить в корзину'
                                                : 'Добавить в корзину'
                                        }
                                    </button>
                                    <button onClick={this.state.product.booked ? this.removeBookmark : this.bookmark} className='product-bookmark-button'>
                                        {
                                            this.state.product.booked
                                                ?
                                                <img className='heart' src="/images/heart-filled.svg" />
                                                :
                                                <img className='heart' src="/images/heart.svg" />
                                        }
                                    </button>
                                </div>
                            </div>
                            <Link to={'/store/'+this.state.product.store.id} className='store-product-link'>
                                <div className='product-store-block'>
                                    <div className='product-store-image'
                                         style={{
                                             backgroundImage: 'url("' + this.state.product.store.photoMin + '")',
                                             backgroundSize: 'cover',
                                             backgroundRepeat: 'no-repeat',
                                             backgroundPosition: 'center',
                                             display: 'inline-block'
                                         }}>

                                    </div>
                                    <div className='product-store-block-text' style={{
                                        display: 'inline-block',
                                        position: 'absolute',
                                        top: 0,

                                    }}>
                                        <div className='product-store-label'>
                                            <span>Магазин</span>
                                        </div>
                                        <div className='product-store-name'>
                                            <span>{this.state.product.store.name}</span>
                                        </div>
                                    </div>
                                </div>
                            </Link>

                            <div className='product-label'>
                                Описание
                            </div>
                            <div className="product-page-description" style={{wordBreak: 'break-word'}}>
                                <span style={{whiteSpace: 'pre-wrap'}}>
                                    {this.state.product.description}
                                </span>
                            </div>

                            {
                                this.state.product.materials ?
                                    <div>
                                        <div className='product-label'>
                                            Материалы
                                        </div>
                                        <div className='product-material-line'>
                                            <span>{this.state.product.materials}</span>
                                        </div>
                                    </div>
                                    : ''
                            }

                            <div className='product-label'>
                                Наличие
                            </div>
                            <div className="product-page-description" style={{wordBreak: 'break-word'}}>
                                <span style={{whiteSpace: 'pre-wrap'}}>
                                    {this.state.product.isAvailable ? 'В наличии' : 'Под заказ'}
                                </span>
                            </div>

                            <div className='product-label'>
                                Доставка
                            </div>

                            <div className='product-delivery' style={{overflowX: "auto", whiteSpace: 'nowrap'}}>

                                {
                                    this.state.product.store.deliveries.map((deliv, idx) => {
                                        return (
                                            <div  key={idx} style={{marginTop: 5}}>
                                                <span className='product-delivery-type-line'>{deliv.deliveryType.name} :</span><span className='product-delivery-line'> {deliv.city}.
                                                    Срок: {deliv.period}.
                                                    Стоимость: {deliv.cost ? (deliv.cost + ' ' + deliv.currency) : 'Бесплатно'} </span>
                                            </div>
                                        );
                                    })
                                }
                            </div>

                            <div className='product-label'>
                                Отзывы
                            </div>

                            <div>
                                <div className='store-header-title'>
                                    <span >{this.state.product.views} {this.getViewsLabel(this.state.product.views)}</span>
                                </div>
                            </div>

                            <div className='product-label' style={{marginTop: 25}}>
                                Другие товары этого продавца <Link
                                to={'/store/' + this.state.product.store.id}>{this.state.product.store.name}</Link>
                            </div>

                            <div>
                                <ProductListComponent productList={this.state.otherProduct}/>
                            </div>
                        </div>

                        :
                        <LoadingPage opacity={1}/>
                }

                <div>
                    <Dialog
                        open={this.state.messageIsOpen}
                        // TransitionComponent={this.Transition}
                        keepMounted
                        onClose={this.sendOnClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title" className='d-flex justify-content-center'>
                            Добавить отзыв
                        </DialogTitle>
                        <DialogContent className='d-flex justify-content-center'>
                            <div>

                            </div>
                            <div >
                                {
                                    this.state.uploadingInProgress ?
                                        <div >
                                            <LinearProgress variant="determinate" value={this.state.progress}/>
                                        </div>
                                        : ''
                                }
                                <textarea
                                    // autoFocus={true}
                                    className='textarea-element'
                                    value={this.state.comment}
                                    onChange={this.commentOnChange}
                                    placeholder={'Введите комментарий'}
                                    style={{
                                        padding: '20px',
                                        marginTop: '10px'
                                    }} />

                            </div>
                            <CircularProgress
                                style={{display: this.state.commentInProgress ? 'block' : 'none'}}
                                color="secondary"/>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.sendOnClose} color="secondary">
                                Отмена
                            </Button>
                            <button className='save-btn-40' disabled={this.state.commentInProgress} onClick={this.sendComment}>
                                Отправить
                            </button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.reportOpen}
                        TransitionComponent={this.Transition}
                        keepMounted
                        onClose={this.sendOnClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title" className='d-flex justify-content-center'>
                            Пожаловаться на товар
                        </DialogTitle>
                        <DialogContent className='d-flex justify-content-center'>
                            <div>

                            </div>
                            <div >
                                <div  className='d-flex justify-content-center'>
                                    <CircularProgress
                                        style={{display: this.state.sendingInProgress ? 'block' : 'none'}}
                                        color="secondary"/>
                                </div>
                                <textarea
                                    className='textarea-element'
                                    value={this.state.message}
                                    onChange={this.onChangeReport}
                                    placeholder={'Введите сообщение'}
                                    style={{
                                        padding: '20px',
                                        marginTop: '10px',
                                        height: '150px'
                                    }} />

                            </div>
                        </DialogContent>
                        <DialogActions>
                            <input ref={this.fileInput} style={{display: 'none'}} type="file" onChange={this.fileOnChange}/>
                            <Button onClick={this.sendOnClose} color="secondary">
                                Отмена
                            </Button>
                            <button className='save-btn-40' disabled={this.state.sendingInProgress} onClick={this.sendReport}>
                                Отправить
                            </button>
                        </DialogActions>
                    </Dialog>
                </div>
                <ModalGateway>
                    {
                        this.state.photoIsOpen ?
                            <Modal

                                allowFullscreen={true}
                                onClose={this.toggleViewer}>
                                <Carousel
                                    interactionIsIdle={false}
                                    isFullscreen={true}
                                    views={this.state.photoArray}
                                    currentIndex={this.state.currentIndex}
                                    components={{ FooterCount: this.FooterCount }}/>
                            </Modal>
                            : ''
                    }
                </ModalGateway>
            </div>

        );
    }


}

const mapStateToProps = (state) => {
    return {
        dict: state.dictionary.dict,
        user: state.user,
        basket: state.basket,
        messages: state.messages
    };
};

export default connect(mapStateToProps)(AdminPreviewProductPage);