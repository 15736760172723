const userAgentState = {
    isIOS: false,
    isWindows: false,
    isLinux: false,
    isAndroid: false,
    isMacOS: false,
    os: '',
    browser: '',
    isChrome: false,
    isFirefox: false,
    isOpera: false,
    isSafari: false,
    isEdge: false
};


const userAgentUtil = (state = userAgentState, action) => {
    switch (action.type) {
        case 'LOAD_ALL':
            return {
                ...state,
                ...action.values
            };
        default:
            return state;
    }
};


export default userAgentUtil;