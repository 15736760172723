import React, {Component} from "react";
import * as moment from "moment";
import {reportStore} from "../api/ReportAPI";
import {sendNotification} from "../actions/notifications";
import Slide from "@material-ui/core/Slide";
import {getStore} from "../api/StoreAPI";
import {getAdminStoreById} from "../api/AdminStoreAPI";
import {setUserPage} from "../actions/messages";
import {getFilteredGoods} from "../api/GoodsAPI";
import {Link, Redirect} from "react-router-dom";
import {withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import LoadingPage from "./LoadingPage";
import Carousel, {Modal, ModalGateway} from "react-images";
import 'moment/locale/ru'
import ProductListComponent from "./ProductListComponent";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {deleteMessageFile, sendMessageToUser, uploadMessageFile} from "../api/MessageAPI";
import {Helmet} from "react-helmet";
import * as VanillaSharing from 'vanilla-sharing';
import {logSocial} from "../api/LogAPI";
import {
    avatarIcon,
    fbShareIcon,
    messageIcon,
    okShareIcon,
    pinterestShareIcon,
    telegramShareIcon, viberShareIcon,
    vkShareIcon, whatsappShareIcon
} from "../assets/asstes";


const styles = theme => ({

});



class StorePage extends Component{

    constructor(props, context) {
        super(props, context);
        this.state = {
            storeId: this.props.props ? this.props.props.match.params.storeId : this.props.match.params.storeId,
            store: {
                name: '',
                owner: {
                    name: '',
                    photoMid: ''
                },
                createdDate: moment(),
                city: '',
                approved: false
            },
            filter: {
                page: 0,
                size: 1000,
                storeId: this.props.props ? this.props.props.match.params.storeId : this.props.match.params.storeId
            },
            products: {},
            redirect: false,
            message: '',
            reportOpen: false,
            sendingInProgress: false,
            photoIsOpen: false,
            currentIndex: 0,
            photoArrMid: [],
            messageIsOpen: false,
            files: [],
            uploadingInProgress: false,
            progress: 0,
        };
        moment.locale('RU');
        this.fileInput = new React.createRef();


    }

    toggleViewer = () => {
        this.setState(() => ({photoIsOpen: !this.state.photoIsOpen}));
    };

    componentDidMount() {
        moment.locale('RU');

        if (window)
            window.scrollTo(0, 0);
    }

    componentWillMount() {
        this.loadStore();
    }

    componentWillUnmount() {
        if (!this.props.messages.isOpen)
            this.props.dispatch(setUserPage(null));
    }

    loadProduct() {
        getFilteredGoods(this.state.filter).then(
            response => {
                this.setState(() => ({products: JSON.parse(response)}));
            },
            error => {
                console.log(error);
            }
        );
    };


    loadStore() {
        const loader = this.props.viewer === 'user' ? getStore : this.props.viewer === 'admin' ? getAdminStoreById : getStore;
        loader(this.props.props ? this.props.props.match.params.storeId : this.props.match.params.storeId).then(
            response => {
                this.setState(() => ({
                    store: JSON.parse(response)
                }), () => {
                    this.props.dispatch(setUserPage(this.state.store.owner));
                });
                this.loadProduct();
            },
            error => {
                this.setState(() => ({redirect: true}));
                console.log(error);
                let err = JSON.parse(error.message);
                if (err.code === 61) {
                    this.setState(() => ({redirect: true}));
                }
            }
        );
    }
    Transition = (props) => {
        return <Slide direction="up" {...props} />;
    };

    sendOnClose = () => {
        this.setState({reportOpen: false, messageIsOpen: false});
    };

    reportOnOpen = () => {
        this.setState({reportOpen: true});
    };

    sendReport = () => {
        this.setState({sendingInProgress: true});
        reportStore(this.props.match.params.storeId, this.state.message).then(
            response => {
                this.setState({message: '', reportOpen: false, sendingInProgress: false});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Жалоба отправлена',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));
            },
            error => {
                this.setState({message: '', reportOpen: false, sendingInProgress: false});
            }
        );
    };

    onChangeReport = (ev) => {

        let message = ev.target.value;
        this.setState({message});
    };


    toggleViewer = (currentIndex = 0) => {
        this.setState(() => ({photoIsOpen: !this.state.photoIsOpen, currentIndex: currentIndex}));
    };

    CustomView = (innerProps) => {
        return (
            <div className="product-image-lg"
                 onClick={() => {
                     this.toggleViewer(innerProps.currentIndex);
                 }}
                 style={{
                     backgroundImage: 'url("'
                         + innerProps.data + '")',
                     backgroundSize: 'contain',
                     backgroundRepeat: 'no-repeat',
                     backgroundPosition: 'center'
                 }}>
            </div>
        )
    };

    FooterCount = (props) => {
        const activeView = props.currentIndex + 1;
        const totalViews = props.views.length;
        return (
            <div>
                <span>{activeView} из {totalViews}</span>
            </div>
        );
    };


    openMessage = () => {
        if (!this.props.user.name) {
            this.setState({redirectLogin: true});
            return;
        }
        this.setState({messageIsOpen: true});
    };

    removeMessageFile = (fileId) => {
        deleteMessageFile(fileId).then(
            response => {
                let state = {...this.state};
                state.files = state.files.filter(file => file.fileId !== fileId);
                this.setState({...state});
            },
            error => {
                console.log(error);
            }
        );
    };

    fileOnChange = (ev) => {
        if (ev.target.files[0] == null){
            return;
        }
        ev.persist();
        if ((ev.target.files[0].size / 1000 / 1000) > 100){

            this.props.dispatch(sendNotification({
                open: true,
                messageInfo: {
                    message: 'Файл слишком большой',
                    key: new Date().getTime(),
                    variant: 'error'
                }
            }));
            ev.target.value = "";
            return;
        }
        let formData = new FormData();
        formData.append('file', ev.target.files[0]);
        this.setState({uploadingInProgress: true});
        uploadMessageFile(formData, this.onProgress).then(
            response => {
                let file = JSON.parse(response);
                let state = {...this.state};
                state.files.push(file);
                this.setState({...state});
                ev.target.value = "";
                this.setState({uploadingInProgress: false, progress: 0});
            },
            error => {
                console.log(error);
            }
        );
    };


    onProgress = (event) => {

        if (event.lengthComputable) {
            let complete = (event.loaded / event.total * 100 | 0);
            this.setState({progress: complete});
        }
    };

    sendMessage = () => {
        if (this.state.message.trim().length === 0 && this.state.files.length === 0){
            this.props.dispatch(sendNotification({
                open: true,
                messageInfo: {
                    message: 'Сообщение пустое',
                    key: new Date().getTime(),
                    variant: 'error'
                }
            }));
            return;
        }
        sendMessageToUser({message: this.state.message, userId: this.props.userPage.id, fileIds: this.state.files.map(file => file.fileId)}).then(
            response => {
                this.setState({messageIsOpen: false, message: '', files: []});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Сообщение отправлено',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));
            },
            error => {
                this.setState({messageIsOpen: false});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Сообщение не было доставлено',
                        key: new Date().getTime(),
                        variant: 'error'
                    }
                }));
            }
        );
    };

    onChangeMessage = (ev) => {
        this.setState({message: ev.target.value});
    };

    logSocialShared = (type) => {
        const userId = this.props.user.name ? this.props.user.id : null;
        logSocial({page: 'https://crafty.by/store/' + this.state.store.id, userId: userId, type: type});
    };

    sharePinterest= () => {
        VanillaSharing.pinterest({
            url: ('https://crafty.by/store/' + this.state.store.id),
            description: (this.state.store.name + '. ' + this.state.store.description),
            media: this.state.store.photoMax,
        });
        this.logSocialShared('PINTEREST');
    };

    shareFacebook = () => {
        VanillaSharing.fbButton({
            url: ('https://crafty.by/store/' + this.state.store.id)
        });
        this.logSocialShared('FACEBOOK');
    };


    shareVk = () => {
        VanillaSharing.vk({
            url: ('https://crafty.by/store/' + this.state.store.id),
            title: this.state.store.name,
            description: this.state.store.description,
            image: this.state.store.photoMax,
            isVkParse: true,
        });
        this.logSocialShared('VK');
    };

    shareOk = () => {
        VanillaSharing.ok({
            url: ('https://crafty.by/store/' + this.state.store.id),
            title: this.state.store.name,
            image: this.state.store.photoMax,
        });
        this.logSocialShared('OK');
    };

    shareViber = () => {
        VanillaSharing.viber({
            url: ('https://crafty.by/store/' + this.state.store.id),
            title: this.state.store.name,
        });
        this.logSocialShared('VIBER');
    };


    shareTelegram = () => {
        VanillaSharing.telegram({
            url: ('https://crafty.by/store/' + this.state.store.id),
            title: this.state.store.name,
        });
        this.logSocialShared('TELEGRAM');
    };

    shareWhatsApp = () => {
        VanillaSharing.whatsapp({
            url: ('https://crafty.by/store/' + this.state.store.id),
            title: this.state.store.name,
        });
        this.logSocialShared('WHATSAPP');
    };





    render() {
        return (
            <div>
                {this.state.redirectLogin ? <Redirect to={'/login?redirectURL=/store/'+this.state.store.id}/> : ''}

                <Helmet>
                    <title>{this.state.store.name ? ('Магазин мастера ' + this.state.store.name.replace(/[^0-9a-zA-ZА-Яа-яЁё .,?!:;_]/gi, ' ') + ' (' + this.state.store.owner.name + ') | ' + this.state.store.city ) : ''}</title>
                    <meta name="description" content={this.state.store.description ? (this.state.store.description.substring(0, 150).replace(/[^0-9a-zA-ZА-Яа-яЁё .,?!:;_]/gi, ' ') + '...') : ''} />
                    <meta name="og:description" content={this.state.store.description ? (this.state.store.description.substring(0, 150).replace(/[^0-9a-zA-ZА-Яа-яЁё .,?!:;_]/gi, ' ') + '...') : ''} />
                    <meta name="og:title" content={(this.state.store.name ? ('Магазин мастера ' + this.state.store.name.replace(/[^0-9a-zA-ZА-Яа-яЁё .,?!:;_]/gi, ' ') + ' (' + this.state.store.owner.name + ') | ' + this.state.store.city ) : '')} />
                    <meta property="og:image" content={(this.state.store.photoMid)} />
                    <meta name='keywords' content={this.state.store.name.replace(/[^0-9a-zA-ZА-Яа-яЁё .,?!:;_]/gi, ' ') + ', купить, заказать, цена, в интернет магазине, ' + this.state.store.city} />
                    <meta property="og:url" content={'https://crafty.by/store/' + this.state.storeId}/>
                    <link rel="canonical" href={'https://crafty.by/store/' + this.state.storeId} />

                </Helmet>
                {this.state.redirect ? <Redirect to={{pathname: '/', state: {status: 404}}}/> : ''}
                {
                    this.state.store.name ?
                        <div>
                            <div className="product-image-lg">
                                <Carousel styles={{width: '24rem'}} views={[this.state.store.photoMid,]} components={{ View: this.CustomView, FooterCount: this.FooterCount }}/>
                            </div>

                            {
                                this.state.store.approved ?
                                    <div className='share-social d-flex justify-content-start'>
                                        <button className='button-social' style={{backgroundColor: '#FFFFFF', border: "none", outline: "none", paddingLeft: 0}} onClick={this.sharePinterest}>
                                            <img style={{width: 30, height: 30}} src={pinterestShareIcon} alt="Поделиться в Pinterest"/>
                                        </button>
                                        <button className='button-social'  style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareFacebook}>
                                            <img style={{width: 30, height: 30}} src={fbShareIcon} alt="Поделиться в Facebook"/>
                                        </button>
                                        <button className='button-social'  style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareVk}>
                                            <img style={{width: 30, height: 30}} src={vkShareIcon} alt="Поделиться во Вконтакте"/>
                                        </button>
                                        <button className='button-social'  style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareOk}>
                                            <img style={{width: 30, height: 30}} src={okShareIcon} alt="Поделиться в Одноклассниках"/>
                                        </button>
                                        <button  className='button-social' style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareTelegram}>
                                            <img style={{width: 30, height: 30}} src={telegramShareIcon} alt="Поделиться в Telegram"/>
                                        </button>
                                        <button className='button-social'  style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareWhatsApp}>
                                            <img style={{width: 30, height: 30}} src={whatsappShareIcon} alt="Поделиться в WhatsApp"/>
                                        </button>
                                        <button className='button-social'  style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareViber}>
                                            <img style={{width: 30, height: 30}} src={viberShareIcon} alt="Поделиться в Viber"/>
                                        </button>

                                    </div>
                                    : ''
                            }


                            <div className='product-page-name'>
                                <h1 className='product-page-name-h3'>{this.state.store.name}</h1>
                            </div>
                            <div className='product-cost-block' style={{position: "relative"}}>
                                <div style={{paddingTop: '8px'}}>
                                <span
                                    className="main-card-store-location">{moment(this.state.store.createdDate).fromNow(true)} на Crafty</span>
                                </div>
                                <div style={{position: "absolute", right: 0, top: 0}}>

                                    <button onClick={this.openMessage} className='page-message-button'>
                                        <img className='heart' src={messageIcon} /> <span>Написать</span>
                                    </button>
                                </div>
                            </div>
                            <Link to={'/user/'+this.state.store.owner.id} className='store-product-link'>
                                <div className='product-store-block'>
                                    <div className='product-store-image'
                                         style={{
                                             backgroundImage: 'url("' + (this.state.store.owner.photoMin ? this.state.store.owner.photoMin : avatarIcon) + '")',
                                             backgroundSize: (!!this.state.store.owner.photoMin ? 'cover' : 'contain'),
                                             backgroundRepeat: 'no-repeat',
                                             backgroundPosition: 'center',
                                             display: 'inline-block'
                                         }}>

                                    </div>
                                    <div className='product-store-block-text' style={{
                                        display: 'inline-block',
                                        position: 'absolute',
                                        top: 0,

                                    }}>
                                        <div className='product-store-label'>
                                            <span>Владелец</span>
                                        </div>
                                        <div className='product-store-name'>
                                            <span>{this.state.store.owner.name}</span>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            <div style={{marginTop: '10px'}} className="main-card-store-pin">
                                <div className="main-card-store-pin-icon"></div>
                                <span
                                    className="main-card-store-location">{this.state.store.country}, {this.state.store.city}. {this.state.store.address}</span>
                            </div>
                            <div className="product-page-description" style={{wordBreak: 'break-word'}}>
                                <span style={{whiteSpace: 'pre-wrap'}}>
                                    {this.state.store.description}
                                </span>
                            </div>
                            <div className='product-label'>
                                Товары
                            </div>

                            <div>
                                <ProductListComponent productList={this.state.products.content}/>
                            </div>

                        </div>
                        :
                        <LoadingPage opacity={1}/>
                }

                <ModalGateway>
                    {
                        this.state.photoIsOpen ?
                            <Modal onClose={this.toggleViewer}>
                                <Carousel views={[{source: this.state.store.photoMax}]} components={{ FooterCount: this.FooterCount }} />
                            </Modal>
                            : ''
                    }
                </ModalGateway>
                <div>
                    <Dialog
                        open={this.state.messageIsOpen}
                        TransitionComponent={this.Transition}
                        keepMounted
                        onClose={this.sendOnClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title" className='d-flex justify-content-center'>
                            Сообщение
                        </DialogTitle>
                        <DialogContent className='d-flex justify-content-center'>
                            <div>

                            </div>
                            <div >
                                {
                                    this.state.files.length > 0 ?
                                        this.state.files.map(file => {
                                            return (
                                                <div key={file.fileId}>
                                                    <div style={{display: 'inline-flex', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '284px', wordBreak: 'break-all'}}>
                                                        <a href={file.link} target='_blank'><span style={{
                                                            maxWidth: '250px',
                                                            overflow: 'hide'
                                                        }}>{file.name}</span></a>
                                                    </div><a style={{marginLeft: '10px', color: 'black'}} href="javascript:void(0)" onClick={() => {
                                                    this.removeMessageFile(file.fileId);
                                                }}>X</a>
                                                </div>
                                            );

                                        })
                                        : ''
                                }
                                {
                                    this.state.uploadingInProgress ?
                                        <div >
                                            <LinearProgress variant="determinate" value={this.state.progress}/>
                                        </div>
                                        : ''
                                }
                                <textarea
                                    className='textarea-element'
                                    value={this.state.message}
                                    onChange={this.onChangeMessage}
                                    placeholder={'Введите сообщение'}
                                    style={{
                                        padding: '20px',
                                        marginTop: '10px'
                                    }} />

                            </div>
                            <CircularProgress
                                style={{display: this.state.sendingInProgress ? 'block' : 'none'}}
                                color="secondary"/>
                        </DialogContent>
                        <DialogActions>
                            <input ref={this.fileInput} style={{display: 'none'}} type="file" onChange={this.fileOnChange}/>
                            <Button onClick={this.sendOnClose} color="secondary">
                                Отмена
                            </Button>
                            <button className='save-btn-40' style={{float: 'right', width: '40px', marginRight: '5px'}} onClick={() => {
                                this.fileInput.current.click();
                            }}>+</button>
                            <button className='save-btn-40' disabled={this.state.sendingInProgress} onClick={this.sendMessage}>
                                Отправить
                            </button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        dict: state.dictionary.dict,
        user: state.user,
        settings: state.settings,
        basket: state.basket,
        messages: state.messages,
        notification: state.notification,
        userPage: state.messages.userPage
    };
};

export default withStyles(styles)(connect(mapStateToProps)(StorePage));