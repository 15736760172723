import React, {Component} from "react";
import {withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import MainListProduct from "./MainListProduct";
import {getFilteredGoods, goodsCollections, goodsCollectionsMain} from "../api/GoodsAPI";
import {refreshSession} from "../actions/authorization";
import AboutLine from "./AboutLine";
import MainPopularStore from "./MainPopularStore";
import SearchBlock from "./SearchBlock";
import {Helmet} from "react-helmet";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Link} from "react-router-dom";
import {nyBanner} from "../assets/asstes";
import LoadingPage from "./LoadingPage";
import CircularProgress from "@material-ui/core/CircularProgress";
// import 'react-smartbanner/dist/main.css';



const styles = theme => ({
    root: {

    }
});

class MainPage extends Component{

    constructor(props, context) {
        super(props, context);
        this.state = {
            productListNew: {
                content: []
            },
            productNY: {
                goodsList: {
                    content: []
                }
            },
            productRec: {
                goodsList: {
                    content: []
                }
            },
            collections: [],
            collectionsLoading: true,
            productListNewLoading: true
        };
    }

    updateGoods = () => {
        let productList, productNY, productRec, collection;
        const filterNew = {
            page: 0,
            size: 12
        };

        getFilteredGoods(filterNew).then(
            response => {
                productList = JSON.parse(response);
                this.setState((state)=> {
                    return {productListNew: productList, productListNewLoading: false};
                });
            },
            error => {
                console.log(error);
                this.setState({productListNewLoading: false});
                let resp = JSON.parse(error.message);
                if (resp.status === 401 || resp.status === 403){
                    this.props.dispatch(refreshSession(true)).then(() => {
                        this.updateGoods();
                    });
                }
            }
        ).catch(
            error => this.setState({productListNewLoading: false})
        );

        goodsCollectionsMain(filterNew).then(
            response => {
                collection = JSON.parse(response);
                this.setState({collections: collection, collectionsLoading: false});
            }, error => {
                this.setState({collectionsLoading: false});
                console.log(error);
            }
        ).catch(
            error => this.setState({collectionsLoading: false})
    );

    };


    componentDidMount() {
        if (window){
            window.scrollTo(0, 0);
        }
        this.updateGoods();

    }

    render() {

        let settings = {
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            // centerMode: true,
            autoplaySpeed: 5000,
            // autoplay: true
        };

        return (
            <div className="row" style={{marginLeft: '0', marginRight: 0}}>
                <Helmet>
                    <title>Crafty - товары ручной работы, handmade(хендмейд), подарки</title>
                    <meta name="Description" content="Современный маркетплейс для людей, которые неравнодушны к товарам ручной работы. Handmade и подарки" />
                    <meta name="description" content="Современный маркетплейс для людей, которые неравнодушны к товарам ручной работы. Handmade и подарки" />
                    <meta name="og:description" content='Современный маркетплейс для людей, которые неравнодушны к товарам ручной работы. Handmade и подарки' />
                    <meta name="og:title" content={'Crafty - товары ручной работы, handmade(хендмейд), подарки'} />
                    <meta property="og:image" content={'https://crafty.by/images/logo.png'} />
                    <link rel="canonical" href={'https://crafty.by'} />
                    <meta name="keywords" content="crafty, ремесло, ручная работа, авторская работа, handmade, хендмейд" />
                    <meta property="og:site_name" content="Crafty.by" />
                </Helmet>
                <SearchBlock />
                <div style={{width: '100%'}}>
                    <Link to={'/collection/1'}>
                        <img src={nyBanner} alt="Подарки к новому году" style={{width: '100%'}}/>
                    </Link>
                    {/*<Slider {...settings} className='col-12'>*/}
                    {/*    <divs>*/}
                    {/*        <img src="/images/ny_big_80.jpg" alt="New Year" style={{width: '100px'}}/>*/}
                    {/*        /!*<div style={{*!/*/}
                    {/*        /!*    display: 'block',*!/*/}
                    {/*        /!*    width: '100%',*!/*/}
                    {/*        /!*    height: '100px',*!/*/}
                    {/*        /!*    backgroundImage: 'url("/images/ny_big_80.jpg")',*!/*/}
                    {/*        /!*    backgroundSize: 'contain',*!/*/}
                    {/*        /!*    backgroundRepeat: 'no-repeat',*!/*/}
                    {/*        /!*    backgroundPositionX: 'center',*!/*/}
                    {/*        /!*    backgroundPositionY: 'center'*!/*/}
                    {/*        /!*}}> </div>*!/*/}
                    {/*    </divs>*/}
                    {/*    <div>*/}
                    {/*        <div style={{display: 'block', width: '100px', height: '100px', backgroundImage: '/images/left_side2_sm.jpg'}}> slider 2 </div>*/}
                    {/*    </div>*/}
                    {/*</Slider>*/}
                </div>
                {
                    this.state.collectionsLoading ?
                        <div className='d-flex justify-content-center'>
                            <CircularProgress color="secondary" />
                        </div>
                        :
                        this.state.collections ?
                            this.state.collections.map( collection => {
                                return (<MainListProduct key={collection.collectionId} url={'/collection/'+collection.collectionId} name={collection.title} productList={collection.goodsList ? collection.goodsList.content : []}/>);
                            })
                            : ''
                }
                {
                    this.state.productListNewLoading ?
                        <div className='d-flex justify-content-center'>
                            <CircularProgress color="secondary" />
                        </div>
                        :
                        <MainListProduct url='/search' name='Новое' productList={this.state.productListNew ? this.state.productListNew.content : []}/>
                }
                <AboutLine/>
                {
                    this.props.dict ?
                        <MainPopularStore stores={this.props.dict.stores}/>
                        :''
                }
            </div>
        );
    }

}


const mapStateToProps = (state) => {
    return {
        dict: state.dictionary.dict,
        user: state.user,
        settings: state.settings,
        basket: state.basket,
        messages: state.messages
    };
};

export default withStyles(styles)(connect(mapStateToProps)(MainPage));

