import React from "react";
import {logEvent} from "../api/LogAPI";
import {logoIcon} from "../assets/asstes";


class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        try {
            errorInfo.error = error.message;
            logEvent({type: 'ERROR', errorStack: JSON.stringify(errorInfo), page: window.location.href, comment: error.message});
        }catch (e) {
            console.log('');
        }

    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div style={{marginTop: 40}}>
                    <div className="d-flex justify-content-center">
                            <img src={logoIcon} alt="" width='174px' height='96px' />

                    </div>
                    <div className="d-flex justify-content-center" style={{marginTop: 30}}>
                        <div>
                            <h1>Попробуйте обновить страницу позже. </h1>
                            <br/>
                            <h1>Возможно идет обновление. </h1>
                            <br/>
                            <a href="mailto:support@crafty.by">В случае проблем обращайтесь на support@crafty.by</a>
                        </div>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;