import {refreshToken, logout} from "../api/AuthorizationApi";
import {deleteUser, saveUser} from "./user";
import {saveBasket} from "./basket";
import {setConversations} from "./messages";


export const saveSession = (session) => ({
    type: 'SAVE_SESSION',
    session: session
});

// let lastTime = null;

export const refreshSession = (isError = false) => {
    // console.log('was');
    // console.log(lastTime);
    // if (!lastTime){
    //     lastTime = new Date();
    // }
    // lastTime = new Date();
    // console.log('now');
    // console.log(lastTime);
    return (dispatch) => {
        return refreshToken(isError).then(
            response => {
                localStorage.setItem('session', response);
                dispatch(saveSession(JSON.parse(response)));
            },
            error => {
                localStorage.removeItem('session');
                dispatch(startLogoutAction());
                console.log(error);
            }
        );
    };
};



export const logoutAction = () => ({
    type: 'LOGOUT',
    authorization: {},
});

export const setTokenManager = (tokenManager) => ({
    type: 'REFRESH_TOKEN_MANAGER',
    tokenManager: tokenManager

});

export const startLogoutAction = () => {
    return (dispatch) => {
        return logout().then(
            response => {
                localStorage.removeItem('session');
                dispatch(logoutAction());
                dispatch(deleteUser());
                dispatch(saveBasket({count: 0, items: []}));
                dispatch(setConversations({}))
            },
            error => {
                localStorage.removeItem('session');
                dispatch(logoutAction());
                dispatch(deleteUser());
                dispatch(saveBasket({count: 0, items: []}));
                dispatch(setConversations({}))

            }
        );
    };
};