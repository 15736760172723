import {getCurrentUser} from "../api/UserAPI";

export const saveUser = (user) => ({
    type: 'SAVE_USER',
    user: user
});

export const deleteStore = (user) => ({
    type: 'DELETE_STORE',
    user: user
});

export const changePhoto = (user) => ({
    type: 'CHANGE_PHOTO',
    user: {
        photoMin: user.photoMin,
        photoMid: user.photoMid,
        photoMax: user.photoMax
    }
});

export const deleteUser = () => ({
    type: 'DELETE_USER'
});

export const isAuthorized = (isAuthorized = false) => ({
    type: 'IS_AUTHORIZED',
    user: {isAuthorized: isAuthorized}
});


export const loadUser = () => {

    return (dispatch) => {
        return getCurrentUser().then(
            response => {
                dispatch(saveUser(JSON.parse(response)));
            },
            error => {
                //TODO: load user error show
            }
        );
    };


};