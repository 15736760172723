import {Link} from "react-router-dom";
import shopIcon from "../../assets/shop.svg";
import React from "react";


const storeMenu = (props) => {
    return props.user.storeIsFilled
        ? <div style={{marginLeft: '21px',  marginBottom: '15px'}}>

            <div style={{display: 'inline-grid', verticalAlign: 'text-top', marginLeft: 0}}>
                <Link className='category-menu-label' onClick={props.closeMenu} to={'/manage/owner'}><img alt={'shop'} src={shopIcon}
                                                                                                         className="shop-icon" /><span className='category-menu-label'>Мой магазин</span></Link>
            </div>
        </div>
        :
        props.user.name ?
            <div style={{marginLeft: '30px',  marginBottom: '15px'}}>
                <div  style={{display: 'inline-grid', verticalAlign: 'text-top'}}>
                    <Link onClick={props.closeMenu} id="sell-label" to={'/manage/store'}>Продавай с нами</Link>
                </div>


            </div>

            :
            <div style={{verticalAlign: 'text-top', marginLeft: '30px', marginBottom: '15px', marginTop: 15}}>
                <Link onClick={props.closeMenu} id="sell-label" to={'/login'}>Продавай с нами</Link>
            </div>

}

export default storeMenu;