import React from 'react';
import {connect} from "react-redux";
import {selectByAlias} from "../selectors/category";
import {getFilteredGoods} from "../api/GoodsAPI";
import {Link, Redirect} from "react-router-dom";
import {refreshSession} from "../actions/authorization";
import {isOpenLeftMenu, setChildCategory, setMainCategory} from "../actions/settings";
import InputRange from "react-input-range";
import CategorySearchBlock from "./CategorySearchBlock";
import TextField from "@material-ui/core/TextField/TextField";
import {withStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Pagination from "material-ui-flat-pagination";
// import * as ReactGA from "react-ga";
import {getFilteredCategories} from "../api/DictionaryAPI";
import * as queryString from "query-string";
import MainListProduct from "./MainListProduct";
import LoadingPage from "./LoadingPage";
import Select from "react-select";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import {Helmet} from "react-helmet";
import * as ReactGA from "react-ga";

const styles = theme => ({

    root: {
        flexGrow: 1,
        width: '100%',
        height: 50,
        display: 'inline-grid'
    },

    select: {
        color: 'red',
        width: '100%'
    },
    content: {
        margin: 0
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(0),
        color: '#5B6273',
        fontFamily: "Open Sans",
        fontSize: '12px',
        lineHeight: '17px'
    },
    textSecondary: {
        opacity: '0.5',
        color: '#283149',
        fontFamily: 'Open Sans',
        fontSize: '14px',
        lineHeight: '19px'
    },
    textPrimary: {
        color: '#4A90E2', fontFamily: 'Open Sans', fontSize: '14px', lineHeight: '19px'
    }

});

const ordering = [
    {
        value: 'createdDate,desc',
        label: 'Сначала новые',
    },
    {
        value: 'createdDate,asc',
        label: 'Сначала старые',
    },
    {
        value: 'cost,desc',
        label: 'Сначала дорогие',
    },
    {
        value: 'cost,asc',
        label: 'Сначала дешевые',
    }
];

class CatalogPage extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            currentCategory: {},
            availableCategories: [],
            filter: {
                page: 0,
                size: 48,
                category: props.match.params.alias,
                costFrom: props.dict.limits.min,
                costTo: props.dict.limits.max,
                color: '',
                name: '',
                sort: 'createdDate,desc'

            },
            product: {
                content: []
            },
            value: {
                min: props.dict.limits.min,
                max: props.dict.limits.max
            },
            loading: true,
            offset: 0,
            limit: 1,
            total: 1,
            childSelect: {},
            redirectCategory: false,
            suggestion: []
        }
    }

    componentDidMount() {
        if (window){
            window.scrollTo(0, 0);
            window.addEventListener('popstate', this.onBackButtonEvent);

        }
        getFilteredCategories(this.props.match.params.alias).then(
            response => {
                let availableCategories = JSON.parse(response);
                let suggestion = availableCategories.map(cat => ({label: cat.name, value: cat.alias, cost: null}))
                this.setState({availableCategories: availableCategories, suggestion: suggestion});
            },
            error => {
                console.log(error);
            }
        );
        const category = selectByAlias(this.props.dict.categories, this.props.match.params.alias);
        const childCategory = this.props.match.params.child ? selectByAlias(category.child, this.props.match.params.child) : null;
        let filter = this.state.filter;
        this.setState(() => ({
            currentCategory: category,
            filter: {...filter, category: category.alias},
            childSelect: (childCategory ? {label: childCategory.name, value: childCategory.alias} : {label: ''})
        }));
        this.searchProduct();
        this.props.dispatch(setMainCategory(this.props.match.params.alias));
        this.props.dispatch(setChildCategory(this.props.match.params.child));

        ReactGA.event({
            category: 'Categories',
            action: 'Open category'
        });
    }

    componentWillMount() {
        const parsed = queryString.parse(window.location.search);
        let filter = {...this.state.filter};
        filter.name = parsed.q;
        filter.size = parsed.size ? parsed.size : 48;
        filter.page = parsed.page ? parsed.page : 0;
        filter.sort = parsed.sort ? parsed.sort : filter.sort;

        window.history.pushState(filter, 'Поиск', '?' + queryString.stringify(filter));
        this.setState({filter: filter, offset: (filter.page * filter.size)});
        this.props.dispatch(isOpenLeftMenu(false));
    }

    componentWillUnmount() {
        this.props.dispatch(setMainCategory(''));
        this.props.dispatch(setChildCategory(''));
        if (window)
            window.removeEventListener('popstate', this.onBackButtonEvent);

    }

    onBackButtonEvent = ( ev ) => {
        if(ev.state && typeof ev.state.page !== 'undefined' ) {

            let filter = {...this.state.filter};
            filter.page = ev.state.page;
            filter.size = ev.state.size;
            filter.category = ev.state.category;
            filter.costFrom = ev.state.costFrom;
            filter.costTo = ev.state.costTo;
            filter.color = ev.state.color;
            filter.name = ev.state.name;
            filter.sort = ev.state.sort;

            this.setState({filter: filter, offset: (filter.page * filter.size)}, () => {
                this.searchProduct();
            });

        }
    };



    componentDidUpdate(prevProps) {

        if (prevProps.match.params.alias !== this.props.match.params.alias || prevProps.match.params.child !== this.props.match.params.child) {
            this.props.dispatch(isOpenLeftMenu(false));

            const category = selectByAlias(this.props.dict.categories, this.props.match.params.alias);
            getFilteredCategories(this.props.match.params.alias).then(
                response => {
                    let availableCategories = JSON.parse(response);
                    this.setState({availableCategories});
                },
                error => {
                    console.log(error);
                }
            );
            let filter = this.state.filter;
            filter.page = 0;
            this.setState(() => ({
                currentCategory: category,
                filter: {...filter, category: this.props.match.params.alias},
                offset: 0,
                limit: 1,
                total: 1
            }));
            this.props.dispatch(setMainCategory(this.props.match.params.alias));
            this.props.dispatch(setChildCategory(this.props.match.params.child));
            this.searchProduct();

            ReactGA.event({
                category: 'Categories',
                action: 'Change category'
            });
            ReactGA.pageview(this.props.history.location.pathname);
        }

    }

    searchProduct = () => {
        this.setState(()=>({loading: true}));
        let cat = typeof this.props.match.params.child === 'string' ? this.props.match.params.child : this.props.match.params.alias;
        getFilteredGoods({...this.state.filter, category: cat}).then(
            response => {
                let resp  = JSON.parse(response);
                    this.setState(() => ({
                        product: resp,
                        limit: resp.size,
                        total: resp.totalElements

                }));
                this.setState(()=>({loading: false}));
            },
            error => {
                this.setState(()=>({loading: false}));
                let resp = JSON.parse(error.message);
                if (resp.status === 401 || resp.status === 403) {
                    this.props.dispatch(refreshSession()).then(() => {
                        this.searchProduct();
                    });
                }
            }
        );
    };


    onSearchChange = (ev) => {
        let filter = this.state.filter;
        filter.name = ev.target.value;
        this.setState({filter: filter}, () => {
            const params = queryString.parse(window.location.search);
            params.q = filter.name;
            params.page = filter.page;
            params.size = filter.size;
            params.sort = filter.sort;
            window.history.pushState(params, 'Поиск', '?' + queryString.stringify(params));

            this.searchProduct();
        })
    };

    onOrderChange = (ev) => {
        let filter = this.state.filter;
        filter.sort = ev.target.value;
        this.setState({filter: filter}, () => {
            const params = queryString.parse(window.location.search);
            params.q = filter.name;
            params.page = filter.page;
            params.size = filter.size;
            params.sort = filter.sort;
            window.history.pushState(params, 'Поиск', '?' + queryString.stringify(params));

            this.searchProduct();
        });
    };

    changePage = (e, offset, page) => {
        let filter = this.state.filter;
        filter.page = page - 1;
        this.setState(() => ({filter: filter, offset: offset}), () => {
            const params = queryString.parse(window.location.search);
            params.q = filter.name;
            params.page = filter.page;
            params.size = filter.size;
            params.sort = filter.sort;
            window.history.pushState(params, 'Поиск', '?' + queryString.stringify(params));

            this.searchProduct();

        });
        if (typeof window !== 'undefined'){
            window.scrollTo(0, 0);
        }
    };

    onChangeCategory = (e) => {
            this.setState({childSelect: e}, () => {
                this.setState({redirectCategory: true});
            });

    };

    render() {

        const { classes, theme } = this.props;

        let childCategory = (this.state.availableCategories.filter(value => value.alias === this.props.match.params.child));

        const selectStyles = {
            input: base => ({
                ...base,
                width: '100%',
                color: theme.palette.text.primary,
                '& input': {
                    font: 'inherit',
                },
            }),
            control: (provided) => {
                return {
                    ...provided,
                    width: '100%',
                    borderRadius: 0,
                    height: 50,
                    ':active': {
                        ...provided[':active'],
                        outlineColor: '#000000',
                        borderColor: '#000000',
                        outlineFocused: '#000000'
                    },
                    ':focus': {
                        ...provided[':focus'],
                        outlineColor: '#000000',
                        borderColor: '#000000'
                    },
                    ':target': {
                        ...provided[':target'],
                        outlineColor: '#000000',
                        borderColor: '#000000'
                    },
                    fontFamily: 'Open Sans',
                    fontSize: 14,
                    fontWeight: 'normal',
                    fontStyle: 'normal',
                    fontStretch: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    color: '#283149'
                };
            },
            indicatorSeparator: () => {

            },
            option: (provided) => {
                return {
                    ...provided,
                    borderRadius: 0,
                    backgroundColor: '#FFFFFF',
                    color: '#000000',
                    cursor: 'default',
                    ':active': {
                        ...provided[':active'],
                        backgroundColor: 'rgba(207, 214, 216, 0.2)',
                    },
                    ':hover': {
                        ...provided[':hover'],
                        backgroundColor: 'rgba(207, 214, 216, 0.2)',
                    },
                    width: '100%'
                };
            },
            container: (provided) => {
                return {
                    ...provided,
                    width: '100%',
                    borderRadius: 0
                };
            },
            valueContainer: (provided) => {
                return {
                    ...provided,
                    borderRadius: 0
                };
            },
            menu: (provided) => {
                return {
                    ...provided,
                    borderRadius: 0
                };
            },
        };

        let child = this.state.currentCategory.child ? this.state.currentCategory.child.map(cat => cat.name + ', ') : null;

        let tagCloud = this.state.currentCategory.tagCloud;

        return (
            <div>
                <Helmet>
                    <title>{typeof this.props.match.params.child === 'string' ?
                        this.state.currentCategory.name +
                        (childCategory.length > 0 ?
                            (' - ' + childCategory[0].name) +
                            (!!tagCloud
                                ? ' (' + tagCloud.toString().replaceAll(';', ', ') + ')'
                                : '')
                            : '')
                        : this.state.currentCategory.name +
                        (!!tagCloud
                        ? ' (' + tagCloud.toString().replaceAll(';', ', ') + ')'
                        : '')
                    }</title>
                    <meta name="description" content={child ? new String(child).substring(0, 150) : ''} />
                    <meta name="og:description" content={child ? new String(child).substring(0, 150) : ''} />
                    <meta name="og:title" content={tagCloud ? this.state.currentCategory.name + ' - ' + tagCloud.toString().replaceAll(';', ', ') : this.state.currentCategory.name} />
                    <meta property="og:image" content={this.state.currentCategory.photo} />
                    <meta name='keywords' content={this.state.currentCategory.name + ', купить, заказать, цена, в интернет магазине, ' + (child ? new String(child).substring(0, 150) : '')} />
                    <link rel="canonical" href={'https://crafty.by/catalog/'+this.props.match.params.alias
                    + (this.props.match.params.child ? '/'+this.props.match.params.child : '')} />
                    <meta property="og:url" content={'https://crafty.by/catalog/'+this.props.match.params.alias
                    + (this.props.match.params.child ? '/'+this.props.match.params.child : '')}/>
                </Helmet>
                {
                    this.state.redirectCategory ? <Redirect to={'/catalog/' + this.state.currentCategory.alias + '/' + this.state.childSelect.value}/> : ''
                }
                {
                    typeof this.props.match.params.child === 'string' ?
                        <div className="path-category">
                            <Link className="path-category" to={'/'}>Главная</Link>
                            <span>  /  </span>
                            <Link className="path-category" to={'/catalog/' + this.state.currentCategory.alias}>{this.state.currentCategory.name}</Link>
                            <span> / </span>
                            <Link className="path-category" to={'/catalog/' + this.state.currentCategory.alias + '/' + this.props.match.params.child}>{ childCategory.length > 0 ? childCategory[0].name : '' }</Link>
                        </div>
                        :
                    <div id="path-category">
                    <Link className="path-category" to={'/'}>Главная</Link><span>  /  </span> <Link className="path-category" to={'/catalog/' + this.state.currentCategory.alias}>{this.state.currentCategory.name}</Link> {}
                    </div>


                }

                <div className="catalog-box">
                    <div>
                        {/*<div>*/}
                        {/*    <h1 className="category-parent-name">{this.state.currentCategory.name}</h1>*/}
                        {/*</div>*/}
                        <div>


                            <ExpansionPanel>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    style={{margin: 0}}
                                    classes={{content: classes.content}}
                                >
                                    {/*<Typography className={classes.heading}>Фильтр</Typography>*/}
                                    <div>
                                        <h1 className="category-parent-name">{this.state.currentCategory.name}</h1>
                                    </div>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails style={{display: "block"}}>
                                    <div className="search-head">
                                        <span>Подкатегория</span>
                                    </div>
                                    <div style={{marginTop: '10px', position: "relative"}}>
                                        <Select
                                            isSearchable={ false }
                                            inputProps={{readOnly:true}}
                                            classes={classes}
                                            styles={selectStyles}
                                            options={this.state.suggestion}
                                            style={{width: '100%'}}
                                            // components={components}
                                            value={this.state.childSelect}
                                            onChange={(e) => {
                                                this.onChangeCategory(e);
                                            }}
                                            theme={theme => ({
                                                ...theme,
                                                width: '100%'
                                            })}
                                            placeholder="Подкатегория"
                                        />


                                    </div>


                                    {
                                        this.state.availableCategories.length > 0
                                            ?
                                            <div>
                                                <div className="search-head">
                                                    <span>Стоимость</span>
                                                </div>
                                                <div style={{marginTop: '21px'}}>
                                                    <InputRange
                                                        maxValue={this.props.dict.limits.max}
                                                        minValue={this.props.dict.limits.min}
                                                        step={1}
                                                        allowSameValues={true}
                                                        value={this.state.value}
                                                        onChange={value => this.setState({value})}
                                                        onChangeComplete={(value) => {
                                                            this.setState(() => ({
                                                                ...this.state,
                                                                filter: {...this.state.filter, costFrom: value.min, costTo: value.max}
                                                            }));
                                                            this.searchProduct();
                                                        }}
                                                    />
                                                    <span className='cost-range' style={{float: 'left'}}>{this.state.value.min} BYN</span> <span
                                                    className='cost-range' style={{float: 'right'}}>{this.state.value.max} BYN</span>
                                                </div>
                                            </div>
                                            : ''
                                    }

                                    <div style={{marginTop: '40px'}}>
                                        <CategorySearchBlock onChange={this.onSearchChange}/>
                                    </div>
                                    <div>

                                        <div style={{marginTop: '10px'}} className="category-sort d-flex justify-content-end">
                                            <TextField
                                                id="filled-select-currency"
                                                select
                                                className={classes.textField}
                                                value={this.state.filter.sort}
                                                onChange={this.onOrderChange}
                                                SelectProps={{
                                                    MenuProps: {
                                                        className: classes.menu,
                                                    },
                                                    IconComponent: ExpandMoreIcon
                                                }}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    className: classes.textField
                                                }}
                                                margin="normal"
                                            >
                                                {ordering.map(option => (
                                                    <MenuItem style={{color: '#5B6273',
                                                        fontFamily: "Open Sans",
                                                        fontSize: '12px',
                                                        lineHeight: '17px'}} key={option.value} value={option.value}>
                                                        <div>{option.label}</div>

                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                    </div>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>

                        </div>
                    </div>
                    <div >
                        <div>

                        </div>
                        <div className="category-product-box">


                            {   this.state.loading ? <LoadingPage opacity={1} /> :
                                this.state.product.content.length > 0 ?
                                    <MainListProduct productList={this.state.product.content}/>
                                :
                                    <div className='d-flex justify-content-center'>
                                        <span>Ничего не найдено</span>
                                    </div>
                            }


                        </div>
                        <div className={'d-flex justify-content-center'}>
                                <Pagination
                                    limit={this.state.product.size}
                                    offset={this.state.offset}
                                    total={this.state.product.totalElements}
                                    currentPageColor={'primary'}
                                    otherPageColor={'secondary'}
                                    classes={{textSecondary: classes.textSecondary, textPrimary: classes.textPrimary}}
                                    onClick={(e, offset, page) => this.changePage(e, offset, page)}
                                />
                        </div>
                    </div>
                </div>
            </div>
        );
    }


}

const mapStateToProps = (state) => {
    return {
        dict: state.dictionary.dict,
        user: state.user,
        settings: state.settings,
        basket: state.basket,
        messages: state.messages
    };
};

CatalogPage.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps)(CatalogPage));