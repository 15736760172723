import url from '../settings/Settings';

const getAllMessages = (filter) => {
    return new Promise(function(resolve, reject) {
        // console.log(JSON.stringify(filter));
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url + '/v1.0/admin/message/list?page='
            + (filter.page || 0) + '&size='+filter.size
            + (filter.sort ? '&sort='+filter.sort : '')
            , true);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
        let session = JSON.parse(localStorage.getItem("session"));
        if (session)
            xhr.setRequestHeader('Authorization', 'token ' + session.token);
        xhr.onload = function() {
            if (this.status === 200) {
                resolve(this.response);
            } else {
                let error = new Error(this.responseText);
                error.code = this.status;
                reject(error);
            }
        };

        xhr.onerror = function() {
            reject(new Error("Network Error"));
        };

        xhr.send();
    });
};



export {getAllMessages};