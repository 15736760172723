import {refreshSession} from "../actions/authorization";
import {getConversations, getDialog} from "../actions/messages";
import {sendMessageToConversation, sendMessageToUser, uploadMessageFile} from "./MessageAPI";


class MessageAPIManager {

    constructor(props, context) {
        this.props = props;
        this.context = context;
        this.state = {
        };
    }

    getAllConversations = () => {
        localStorage.setItem('lastReq', Date.now());
        this.props.dispatch(refreshSession()).then(() => {
            this.props.dispatch(getConversations());
        });
    };

    uploadFileToMessage = (formData, onProgress) => {
        localStorage.setItem('lastReq', Date.now());

        return this.props.dispatch(refreshSession()).then(() => {
            return uploadMessageFile(formData, onProgress);
        });
    };

    sendMessageToUserSafe = (message) => {
        localStorage.setItem('lastReq', Date.now());

        return this.props.dispatch(refreshSession()).then(() => {
            return sendMessageToUser(message);
        });
    };

    sendMessageToConversationSafe = (message, filter) => {
        localStorage.setItem('lastReq', Date.now());

        return this.props.dispatch(refreshSession()).then(() => {
            return sendMessageToConversation(message, filter);
        });
    };

    getDialogSafe = (filter) => {
        localStorage.setItem('lastReq', Date.now());

        this.props.dispatch(refreshSession()).then(() => {
            this.props.dispatch(getDialog(filter));
        });
    };



}


export default MessageAPIManager;