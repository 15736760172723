import React from 'react';
import ReactDOM from 'react-dom';
import './main.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from "./store/configureStore";
import ErrorBoundary from "./components/ErrorBoundary";
import {Provider} from "react-redux";
import {startLoadDict} from "./actions/dictionary";
import moment from "moment";
import {refreshSession} from "./actions/authorization";
import {loadUser} from "./actions/user";
import {loadBasket} from "./actions/basket";
import {getConversations, getUnreadAction} from "./actions/messages";
import {loadUserAgentParams} from "./actions/userAgentUtilActions";

const store = configureStore();

const jsx = (
    <div>
        <ErrorBoundary>
            <Provider store={store}>
                <App/>
            </Provider>
        </ErrorBoundary>
    </div>

);

store.dispatch(startLoadDict()).then(() => {
    store.dispatch(loadUserAgentParams());
    let session = JSON.parse(localStorage.getItem("session"));
    if (!!session) {
        if (moment(session.refreshExpDate).diff(moment(), "day") > 0) {
            store.dispatch(refreshSession()).then(() => {
                store.dispatch(loadUser()).then(() => {
                    store.dispatch(loadBasket()).then(() => {
                        store.dispatch(getConversations()).then(() => {
                            store.dispatch(getUnreadAction());
                            ReactDOM.render(jsx, document.getElementById('root'));
                        });
                    });
                });
            });
        } else {
            localStorage.removeItem('session');
            ReactDOM.render(jsx, document.getElementById('root'));
        }
    } else {
        ReactDOM.render(jsx, document.getElementById('root'));
    }

});

// ReactDOM.render(jsx, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
