const basketReducerDefaultState = {
    count: 0,
    items: []
};

const basket = (state = basketReducerDefaultState, action) => {
    switch (action.type) {
        case 'SAVE_BASKET':
            return {
                ...state,
                ...action.basket
    };
        case 'REMOVE_GOODS':
            return {
                ...state,
                ...action.basket
            };
        case 'SEND_ORDER':
            return {
                ...state,
                ...action.basket
            };
        default:
            return state;
    }
};
export default basket;