import React from 'react';
import {Redirect} from "react-router-dom";
import {confirmEmail} from "../api/UserAPI";

class ConfirmEmailComponent extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            sent: false
        };
    }

    componentDidMount() {
        confirmEmail(this.props.match.params.hash).then(
            response => {
                this.setState({sent: true});
            },
            error => {
                this.setState({sent: true});
            }
        );
    }

    render() {
        return (
            <div>
                {
                    this.state.sent ? <Redirect to={'/'}/> : ''
                }
            </div>
        );
    }

}

export default ConfirmEmailComponent;



