import React from 'react';
import {Link} from "react-router-dom";
import {appleButtonIcon, facebookIcon, instagramIcon, pinterestIcon, rightIcon, vkIcon} from "../assets/asstes";

const Footer = (props) => (

    <div id="footer-main">
        <div className="row" style={{marginLeft: 0, marginRight: 0, padding: 0}}>
            <div className="logo"> </div>
            <div className="col align-self-end" style={{textAlign: 'right', padding: 0}}>
                <a target='_blank' style={{marginLeft: '20px'}} href="https://apps.apple.com/us/app/crafty/id1446305618">
                    <img style={{height: '35px'}} src={appleButtonIcon} alt={'iOS приложение'} />
                </a>
            </div>

        </div>
        <div className="footer-row row"
             style={{
                 marginTop: '5px',
                 borderBottom: '1px solid #CFD6D8',
                 paddingBottom: '11px',
                 marginLeft: 0,
                 marginRight: 0
             }}>
            <div className="col" style={{paddingLeft: '0px'}}>
                <span className="footer-location">Беларусь, Минск</span>
                {/*<span className="footer-delimiter">|</span>*/}
                {/*<br/>*/}
                {/*<a href="tel:+375 (44) 763-51-42"> <span className="footer-phone">+375 (44) 763-51-42</span></a>*/}
                {/*<span className="footer-delimiter">|</span>*/}
                <br/>
                <a href="mailto:support@crafty.by"><span className="footer-email">support@crafty.by</span></a>
            </div>
            <div className="col align-self-end" style={{textAlign: 'right', padding: 0}}>
                <Link className="footer-links" to={'/about'}>О нас</Link>
                {/*<br/>*/}
                {/*<a disabled className="footer-links" href="#">Часто задаваемые вопросы</a>*/}
                <br/>
                <Link className="footer-links" to={'/agreement'}>Ответственности сторон</Link>
            </div>
        </div>
        <div className="row" style={{marginTop: '22px', marginLeft: 0, marginRight: 0}}>
            <div className="col-8" style={{paddingLeft: '0px'}}>
                <div>
                    <a target='_blank' href="https://www.instagram.com/crafty.by/">
                        <img alt={'instagram'} src={instagramIcon}
                             className='icon-16' />
                    </a>
                    <a target='_blank' style={{marginLeft: '7px'}} href="https://www.facebook.com/crafty.by/">
                        <img  alt={'facebook'} src={facebookIcon}
                             className='icon-16' />
                    </a>
                    <a target='_blank' style={{marginLeft: '7px'}} href="https://www.pinterest.com/craftyby/">
                        <img  alt={'pinterest'} src={pinterestIcon}
                             className='icon-16' />
                    </a>
                    <a target='_blank' style={{marginLeft: '7px'}} href="https://vk.com/craftyby">
                        <img  alt={'VK'} src={vkIcon}
                             className='icon-16' />
                    </a>
                </div>
                <div>
                    <span className="copyright">© 2020 Крафти, все права защищены</span>
                </div>
            </div>
            <div className="col-4 align-self-end" style={{textAlign: 'right'}}>
                <a target='_blank' href='https://right.by/'><img src={rightIcon}
                                                 className="right-icon" alt={'Right'} /></a>
                <div>
                    <a target='_blank' className='slogan-right' href='https://right.by/'>Дизайн</a>
                </div>
            </div>
        </div>
    </div>
);

export default Footer;